import {
  pepper,
  turmeric,
  cardamom,
  coriander,
  ginger,
  chillie,
  spices,
  herbs,
  dry,
  veg,
  handicraft,
  orange,
  apple,
  dates,
  coirfenders,
  dolls,
  dryfruits,
  freshfruits,
  naturalhoney,
  aloevere,
  amla,
  lemongrass,
  Honey,
  HerbalHoney,
  HoneyGeneral,
  raisins,
  ForestHoney,
  Banana,
  Lemon,
  Mango,
  SweetLime,
  Guava,
  Papaya,
  Pomegranates,
  woodapple,
  limerind,
  apricots,
  figs,
  basil,
  cumin,
  fssai,
  iec,
  udyam,
  fieo,
  apeada,
  spice,
  gst,
  rye,
  corn,
  oats,
  bajara,
  rice,
  wheat,
  barley,
  triticale,
  fonio,
  sorghum,
  teff,
  chickpeas,
  urad,
  wholeMasoo,
  fieldBean,
  beatenRice,
  chanaDal,
  blackGram,
  moong,
  redLentil,
  puffedRice,
  horseGram,
  blackeyedBeans,
  tur,
  soyabean,
  kidneyBeans,
  peas,
  mothBean,
  grainBanner,
  pulseBanner,
  dehydratedFruits,
  dehydratedVegies,
  onion,
  mushroom,
  broccoli,
  potato,
  spinach,
  cabbage,
  brinjal,
  cauliflower,
  drumstick,
  ladyFinger,
  greenChille,
  bottleGourd,
  bitterGourd,
  cucumber,
  bellPaper,
  ridgeGourd,
  pumpkin,
  lemon,
  sweetPotato,
  jerusalem,
  radish,
  beetroot,
  carrot,
  taro,
  curryLeaves,
  springOnion,
  mint,
  papdi,
  methi,
  cashew,
  walnut,
  almonds,
  hazelnut,
  pista,
  yam,
  purslane,
  vegetableBanner,
  dehydratedBanner,
  foxNut,
  ryeBanner,
  cornBanner,
  oatsBanner,
  milletBanner,
  riceBanner,
  wheatBanner,
  barleyBanner,
  triticaleBanner,
  fonioBanner,
  sorghumBanner,
  teffBanner,
  chickpeasBanner,
  peasBanner,
  kidneybeansBanner,
  soyabeanBanner,
  turBanner,
  greengramBanner,
  blackgramBanner,
  chanadalBanner,
  beatenriceBanner,
  fieldbeansBanner,
  uraddalBanner,
  turkishgramBanner,
  wholemasoorBanner,
  uraddalgotaBanner,
  horsegramBanner,
  puffedriceBanner,
  masoordalBanner,
  cashewBanner,
  walnutBanner,
  almondsBanner,
  hazelnutBanner,
  pistaBanner,
  foxnutBanner,
  figsBanner,
  datesBanner,
  raisinsBanner,
  apricotsBanner,
  woodappleBanner,
  limerindBanner,
  dehydratedVegiesBanner,
  dehydratedFruitsBanner,
  cuminBanner,
  corianderBanner,
  turmericBanner,
  gingerBanner,
  pepperBanner,
  chillieBanner,
  cardamonBanner,
  pomegranateBanner,
  papayaBanner,
  guavaBanner,
  appleBanner,
  orangeBanner,
  mangoBanner,
  bananaBanner,
  amlaBanner,
  basilBanner,
  aloeveraBanner,
  suranBanner,
  methiBanner,
  papdiBanner,
  mintBanner,
  springOnionBanner,
  greencorianderBanner,
  taroBanner,
  carrotBanner,
  beetrootBanner,
  radishBanner,
  jerusalemBanner,
  sweetPotatoBanner,
  lemonBanner,
  pumpkinBanner,
  ridgeGourdBanner,
  bellPaperBanner,
  cucumberBanner,
  bitterGourdBanner,
  laukiBanner,
  greenChilleBanner,
  ladyfingerBanner,
  drumstickBanner,
  cauliflowerBanner,
  brinjalBanner,
  cabbageBanner,
  spinachBanner,
  potatoBanner,
  broccoliBanner,
  mushroomBanner,
  onionBanner,
} from "../assets";

export const subNav = [
  {
    item: "Grains and Cereal",
    link: "grains-and-cereal",
  },
  {
    item: "Pulses",
    link: "pulses",
  },
  {
    item: "Indian Spices",
    link: "indian-spices",
  },
  {
    item: "Indian  Herbs",
    link: "indian-herbs",
  },
  {
    item: "Vegetables",
    link: "vegetables",
  },
  {
    item: "Dry Fruits",
    link: "dry-fruits",
  },
  {
    item: "Fresh Fruits",
    link: "fresh-fruits",
  },
  {
    item: "Natural Honey",
    link: "natural-honey",
  },
  {
    item: "Dehydrated Products",
    link: "dehydrated-products",
  },
];

const spiceProucts = [
  {
    heading: "Cardamom",
    picture: cardamom,
  },
  {
    heading: "Chillie",
    picture: chillie,
  },
  {
    heading: "Pepper",
    picture: pepper,
  },
  {
    heading: "Ginger",
    picture: ginger,
  },
  {
    heading: "Turmeric",
    picture: turmeric,
  },
  {
    heading: "Coriander",
    picture: coriander,
  },
  {
    heading: "Cumin",
    picture: cumin,
  },
];

const herbsProducts = [
  {
    heading: "Aloe vera",
    picture: aloevere,
  },
  {
    heading: "Amla",
    picture: amla,
  },
  {
    heading: "Holy basil",
    picture: basil,
  },
  {
    heading: "Lemongrass",
    picture: lemongrass,
  },
];

const dryFruitsProducts = [
  {
    heading: "Cashew",
    picture: cashew,
  },
  {
    heading: "Walnut",
    picture: walnut,
  },
  {
    heading: "almonds",
    picture: almonds,
  },
  {
    heading: "hazelnut",
    picture: hazelnut,
  },
  {
    heading: "Pistachio",
    picture: pista,
  },
  {
    heading: "Fox Nut",
    picture: foxNut,
  },
  {
    heading: "Figs",
    picture: figs,
  },
  {
    heading: "Dates",
    picture: dates,
  },
  {
    heading: "Raisins",
    picture: raisins,
  },
  {
    heading: "Apricots",
    picture: apricots,
  },
  {
    heading: "Dry woodapple",
    picture: woodapple,
  },
  {
    heading: "Dry lime rind",
    picture: limerind,
  },
];

const freshFruitProducts = [
  {
    heading: "Bananas",
    picture: Banana,
  },
  {
    heading: "Lemon",
    picture: Lemon,
  },
  {
    heading: "Mangoes",
    picture: Mango,
  },
  {
    heading: "Sweet lime",
    picture: SweetLime,
  },
  {
    heading: "Oranges",
    picture: orange,
  },
  {
    heading: "Apples",
    picture: apple,
  },
  {
    heading: "Guavas",
    picture: Guava,
  },
  {
    heading: "Papayas",
    picture: Papaya,
  },
  {
    heading: "Pomegranates",
    picture: Pomegranates,
  },
];

const naturalHoneyProducts = [
  {
    heading: "Organic wild honey",
    picture: ForestHoney,
    paragraph:"It can be used as a substitute for sugar in many food and drinks. It contains about 69% glucose and fructose, enabling it to be used as a sweetener that is better for your overall health than normal white sugar.Though it has more calories than sugar when honey is consumed with warm water, it helps in digesting the fat stored in your body."
  },
  {
    heading: "NaturalKashmir honey",
    picture: Honey,
    paragraph:"Pure Honey is very good for health in many ways. First, it is an unprocessed food. Second, it is a food which digests easily and naturally, this is because Pure Honey is composed primarily of simple and natural sugars without additives, preservatives or chemicals. Pure Honey has a long shelf life."
  },
  {
    heading: "Herbal honey",
    picture: HerbalHoney,
    paragraph:"Studies have shown that herbal honey possesses natural antioxidant and therapeutic properties that were able to prevent cognitive decline and dementia and enhance the brain's cholinergic system and circulation. One of the better known health benefits of herbal honey is that it is able to help treat sore throats."
  },
  {
    heading: "Multi floral honey",
    picture: HoneyGeneral,
    paragraph:"Honey is a natural remedy for many ailments and also helps in the regulation of fats. Honey is truly a remarkable substance, made even more extraordinary by the process with which it is made. This blend of sugar, trace enzymes, minerals, vitamins, and amino acids is quite unlike any other sweetener on the planet."
  },
];

const grainProducts = [
  {
    heading: "Rye",
    picture: rye,
  },
  {
    heading: "Corn / Maize",
    picture: corn,
  },
  {
    heading: "Oats",
    picture: oats,
  },
  {
    heading: "Millet / Bajra",
    picture: bajara,
  },
  {
    heading: "Rice",
    picture: rice,
  },
  {
    heading: "Wheat",
    picture: wheat,
  },
  {
    heading: "Barley",
    picture: barley,
  },
  {
    heading: "Triticale",
    picture: triticale,
  },
  {
    heading: "Fonio",
    picture: fonio,
  },
  {
    heading: "Sorghum / Jawari",
    picture: sorghum,
  },
  {
    heading: "Teff",
    picture: teff,
  },
];

const pulseProducts = [
  {
    heading: "Chickpeas / Garbanzo Beans",
    picture: chickpeas,
  },
  {
    heading: "Peas",
    picture: peas,
  },
  {
    heading: "Kidney beans",
    picture: kidneyBeans,
  },
  {
    heading: "Soya bean",
    picture: soyabean,
  },
  {
    heading: "Pigeon pea/Arhar/Tur",
    picture: tur,
  },
  {
    heading: "Black-eyed beans / Cowpea",
    picture: blackeyedBeans,
  },
  {
    heading: "Horse gram",
    picture: horseGram,
  },
  {
    heading: "Puffed rice",
    picture: puffedRice,
  },
  {
    heading: "Masoor Dal / Red Lentil",
    picture: redLentil,
  },
  {
    heading: "Green gram / Mung bean",
    picture: moong,
  },
  {
    heading: "Black gram",
    picture: blackGram,
  },
  {
    heading: "Chana Dal",
    picture: chanaDal,
  },
  {
    heading: "Beaten rice",
    picture: beatenRice,
  },
  {
    heading: "Field beans",
    picture: fieldBean,
  },
  {
    heading: "Urad Dal Split",
    picture: urad,
  },
  {
    heading: "Turkish gram / Moth bean",
    picture: mothBean,
  },
  {
    heading: "Whole Masoor",
    picture: wholeMasoo,
  },
  {
    heading: "Urad Dal Gota",
    picture: urad,
  },
];

const vegetableProducts = [
  {
    heading: "Onion",
    picture: onion,
  },
  {
    heading: "Mushroom",
    picture: mushroom,
  },
  {
    heading: "Broccolie",
    picture: broccoli,
  },
  {
    heading: "Potato",
    picture: potato,
  },
  {
    heading: "Spinach / Palak",
    picture: spinach,
  },
  {
    heading: "Cabbage",
    picture: cabbage,
  },
  {
    heading: "Brinjal",
    picture: brinjal,
  },
  {
    heading: "Cauliflower",
    picture: cauliflower,
  },
  {
    heading: "Drum Stick",
    picture: drumstick,
  },
  {
    heading: "Lady Finger",
    picture: ladyFinger,
  },
  {
    heading: "Green Chilli",
    picture: greenChille,
  },
  {
    heading: "Doodhi / Bottle Gourd / Lauki",
    picture: bottleGourd,
  },
  {
    heading: "Bitter Gourd",
    picture: bitterGourd,
  },
  {
    heading: "Cucumber",
    picture: cucumber,
  },
  {
    heading: "Bell Pepper",
    picture: bellPaper,
  },
  {
    heading: "Ridge Gourd / Ghosal",
    picture: ridgeGourd,
  },
  {
    heading: "Pumpkin",
    picture: pumpkin,
  },
  {
    heading: "Lemon",
    picture: lemon,
  },
  {
    heading: "Sweet Potato",
    picture: sweetPotato,
  },
  {
    heading: "Jerusalem",
    picture: jerusalem,
  },
  {
    heading: "Radish",
    picture: radish,
  },

  {
    heading: "Beetroot",
    picture: beetroot,
  },
  {
    heading: "Carrots",
    picture: carrot,
  },
  {
    heading: "Taro",
    picture: taro,
  },
  {
    heading: "Curry Leaves",
    picture: curryLeaves,
  },
  {
    heading: "Coriander",
    picture: coriander,
  },
  {
    heading: "Spring Onion",
    picture: springOnion,
  },
  {
    heading: "Mint",
    picture: mint,
  },
  {
    heading: "Papdi / Hyacinth Bean",
    picture: papdi,
  },
  {
    heading: "Methi / FenuGreek",
    picture: methi,
  },
  {
    heading: "Common Purslane / Parippu Keerai",
    picture: purslane,
  },
  {
    heading: "Elephane Yam / Suran",
    picture: yam,
  },
];

const dehydratedProducts = [
  {
    heading: "dehydrated fruits",
    picture: dehydratedFruits,
  },
  {
    heading: "dehydrated vegetables",
    picture: dehydratedVegies,
  },
];

export const porductLayout = [
  {
    heading: "Grains and Cereal",
    picture: grainBanner,
    contentArray: grainProducts,
    link: "grains-and-cereal",
  },
  {
    heading: "Pulses",
    picture: pulseBanner,
    contentArray: pulseProducts,
    link: "pulses",
  },
  {
    heading: "Indian Spices",
    picture: spices,
    contentArray: spiceProucts,
    link: "indian-spices",
  },
  {
    heading: "Indian Herbs",
    picture: herbs,
    contentArray: herbsProducts,
    link: "indian-herbs",
  },
  {
    heading: "Vegetables",
    picture: vegetableBanner,
    contentArray: vegetableProducts,
    link: "vegetables",
  },
  {
    heading: "Dry Fruits",
    picture: dryfruits,
    contentArray: dryFruitsProducts,
    link: "dry-fruits",
  },
  {
    heading: "Fresh Fruits",
    picture: freshfruits,
    contentArray: freshFruitProducts,
    link: "fresh-fruits",
  },
  {
    heading: "Natural Honey",
    picture: naturalhoney,
    contentArray: naturalHoneyProducts,
    link: "natural-honey",
  },
  {
    heading: "Dehydrated Products",
    picture: dehydratedBanner,
    contentArray: dehydratedProducts,
    link: "dehydrated-products",
  },
];

export const herbLayout = {
  heading: "Indian Herbs",
  picture: herbs,
  options: ["CARDAMOM", "CHILLIE", "PEPPER", "GINGER", "TURMERIC", "CORIANDER"],
  paragraph:
    "Kankas Exports are providing type of Herbs and spices exporters in India. Classifications of herbs are alovera, amla, holi basil, lemon grass.",
};

export const productArr = [
  {
    image: dry,
    heading: "Dry fruits-Figs",
    paragraph:
      "Figs are the delightful fruits harvested from the ficus tree, belonging to the mulberry family. They boast a distinctive sweet flavor and offer a delightful soft and chewy texture.",
    link: "dry-fruits",
  },
  {
    image: handicraft,
    heading: "Handicrafts",
    paragraph:
      "We craft high-quality products from Bamboo, Palm, and Coconut trees that serve both functional and decorative purposes.",
    link: "indian-herbs",
  },
  {
    image: veg,
    heading: "Fresh fruits-Bananas",
    paragraph:
      "Assimilating a banana into your daily breakfast routine provides essential nutrients that support the healthy functioning of your heart.",
    link: "fresh-fruits",
  },
];

export const certificates = [
  {
    image: fssai,
    name: "FSSAI",
  },
  {
    image: iec,
    name: "IEC",
  },
  {
    image: udyam,
    name: "UDYAM",
  },
  {
    image: fieo,
    name: "FIEO",
  },
  {
    image: apeada,
    name: "APEDA",
  },
  {
    image: spice,
    name: "SPICE BOARD",
  },
  {
    image: gst,
    name: "GSTIN",
  },
];

export const gallarySlider = [orange, apple, dates, dolls, coirfenders];

export const singleProductLayout = [
  {
    heading: "Indian Rye Exporter",
    picture: ryeBanner,
    link: "Rye",
    firstLayout: true,
    description:
      "Rye, one of the oldest cultivated grains, holds a significant place among the world's top five most-consumed cereals. With its rich nutritional profile, rye is highly sought after globally. Indian rye exporters play a crucial role in meeting this demand by supplying premium quality rye to various countries. The rising awareness of its health benefits has led to an increase in rye exports from India, making it a key player in the food export industry. As a versatile grain used in bread, whiskey, and animal feed, Indian rye continues to gain importance on the global stage. The consistent quality and growing demand ensure that India remains a prominent rye exporter, contributing significantly to the global cereal market.",
    marketDemand:
      "The import of rye from India has surged in recent years, driven by its numerous health and medicinal benefits. This nutrient-rich grain is a staple in both Indian and Indonesian cuisines, fueling its growing popularity worldwide. As global demand for rye continues to rise, Indian exporters are seizing the opportunity to expand their presence in international markets. The increasing recognition of rye's versatility and health benefits positions India as a key player in the global rye trade, offering significant growth potential for exporters looking to tap into this thriving market.",
    specifications: [
      {
        title: "Product Name",
        value: "Rye",
      },
      {
        title: "Origin",
        value: "Southwestern Asia",
      },
      {
        title: "Family",
        value: "Poaceae",
      },

      {
        title: "Binomial name",
        value: "Secale Cereale",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "259 Calories",
          "Total Carbohydrate - 48 g",
          "Dietary fibre - 6 g",
        ],
      },
      {
        title: "Minerals",
        value: [
          "Copper",
          "Magnesium",
          "Phosphorous",
          "Thiamin",
          "Riboflavin",
          "Niacin",
          "Folate",
          "Pantothenic Acid",
        ],
      },
      {
        title: "Vitamin",
        value: ["Vitamin B6"],
      },
      {
        title: "Protein",
        value: ["9 g"],
      },
      {
        title: "Fats",
        value: ["3.3g"],
      },
    ],
    benifits: [
      "Rye offers a host of health benefits, making it a valuable addition to any diet. Its high fiber content helps with weight loss by promoting a feeling of fullness, leading to reduced food intake. Rye also aids digestion, easing the passage of stool through the digestive tract. Rich in nutrients, it boosts the immune system and may help prevent asthma. Additionally, rye is heart-healthy, often recommended for heart patients. Beyond its nutritional value, rye serves as a key ingredient in whiskey production and is also used as livestock feed. This versatile grain supports overall health while contributing to various industries.",
    ],
    outro:
      "If you're seeking a reliable rye importer from India, JNTB EXPORT IMPORT stands out as a top choice. With a strong global presence, JNTB EXPORT IMPORT offers comprehensive support, including finding buyers for your products and conducting thorough market analysis. As the export of rye continues to grow rapidly, Indian exporters should seize this opportunity to expand their reach. By partnering with JNTB EXPORT IMPORT, you can ensure the distribution of premium quality rye worldwide, tapping into the increasing global demand and boosting your business success.",
    varieties: [],
  },
  {
    heading: "Indian Corn Exporter",
    picture: cornBanner,
    link: "Corn",
    firstLayout: true,
    description:
      "Corn, a staple in the global diet, is one of the world’s most popular cereal grains and a key player in the agricultural export market. As the seed of a grass family plant, corn comes in various forms, including popcorn and sweet corn. Indian corn exporters are pivotal in this sector, shipping tonnes of corn annually to markets around the world. The versatility of corn allows it to be used in diverse dishes like salads, curries, and vegetables, fueling its demand. The rapid growth in corn exports from India has significantly boosted revenues for exporters. With a range of forms and varieties available, Indian corn exports continue to expand, highlighting the country's crucial role in the global corn industry.",
    marketDemand:
      "Corn import from India has been on the rise, driven by its numerous health and medicinal benefits. Known for reducing the risk of anemia and supporting a healthy pregnancy, corn's value is recognized globally. Indian exporters supply premium-quality corn to various nations, meeting this growing demand. The expanding international market presents a lucrative business opportunity for Indian exporters to tap into. By exporting high-quality corn, Indian businesses can enhance their global presence and capitalize on the increasing global appetite for this versatile grain.",
    specifications: [
      {
        title: "Product Name",
        value: "Corn/Maize",
      },
      {
        title: "Origin",
        value: "Mexico",
      },
      {
        title: "Family",
        value: "Poaceae",
      },
      {
        title: "Binomial name",
        value: "Zea mays",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "177 Calories",
          "Carbohydrates - 41 grams",
          "Fiber - 4.6 grams",
        ],
      },
      {
        title: "Minerals",
        value: [
          "Magnesium",
          "Phosphorous",
          "Zinc",
          "Copper",
          "Iron",
          "Thiamin",
          "Riboflavin",
          "Niacin",
          "Folate",
          "Pantothenic Acid",
        ],
      },
      {
        title: "Vitamin",
        value: ["Vitamin B6, Vitamin E"],
      },
      {
        title: "Protein",
        value: ["5.4 g"],
      },
      {
        title: "Fats",
        value: ["2.1gm"],
      },
    ],
    benifits: [
      "Corn is an excellent energy booster, making it a great post-exercise snack choice.",
      "Corn aids in weight gain and is a healthier choice compared to junk food, offering nutritious benefits.",
      "Corn is ideal for diabetes and cholesterol patients as sweet corn and corn oil boost blood flow, lower cholesterol absorption, and help regulate insulin levels, supporting overall health.",
      "Corn is also widely used as nutritious livestock feed.",
    ],
    outro:
      "Looking for a top corn importer from India? JNTB EXPORT IMPORT is your best choice! We export premium-quality corn to numerous countries worldwide. Leveraging advanced technology and thorough market analysis, JNTB EXPORT IMPORT makes maize export from India seamless and efficient. With our established global presence, we are well-equipped to support your corn export needs. For reliable, high-quality corn exports, JNTB EXPORT IMPORT is the perfect partner.",
    varieties: [],
  },
  {
    heading: "Indian Oats Exporters",
    picture: oatsBanner,
    link: "Oats",
    firstLayout: false,
    description:
      "Oats, a crop with Mediterranean origins, has seen a surge in popularity due to its versatile applications across various fields. Indian oats exporters capitalize on this demand, shipping tonnes of oats to countries worldwide each year. The export of oats from India includes a range of varieties, each rich in nutrients and highly valued in global markets. This growing demand presents a lucrative business opportunity for Indian oats exporters, enabling them to tap into expanding international markets. Beyond its profitability, oats export offers numerous advantages, such as boosting the agricultural sector, enhancing trade relationships, and contributing to the global supply of healthy, nutritious grains.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 389 calories",
          "Carbs - 66.3 grams",
          "Fibre - 10.6 grams",
        ],
      },
      {
        title: "Protein",
        value: ["16.9g"],
      },
      {
        title: "Vitamin",
        value: ["Vitamin B"],
      },
      {
        title: "Minerals",
        value: [
          "	Manganese",
          "Phosphorous",
          "Copper",
          "Iron",
          "Selenium",
          "Magnesium",
          "Zinc",
        ],
      },
    ],
    benifits: [],
    outro:
      "If you're looking to export oats from India, JNTB EXPORT IMPORT is your top choice! As one of the leading oats importers, JNTB EXPORT IMPORT brings extensive experience in the food industry to the table. Our team of trained professionals stays ahead of market trends, ensuring you find the best international buyers for your products. With a deep understanding of the global market, JNTB EXPORT IMPORT is well-equipped to help you navigate the export process seamlessly. For those eager to tap into the thriving oats export business, JNTB EXPORT IMPORT is the ideal partner to ensure your success.",
    varieties: [
      {
        heading: "HFO - 114 (Haryana Javi - 114)",
        description:
          "Released in 1974, HFO-114 is an early-sown variety of oats known for its excellent tillering and synchronous flowering. This tall, disease-resistant variety is also resistant to lodging, making it a reliable choice for growers. HFO-114 produces bold seeds and offers high yields, delivering 50–55 t/ha of green fodder in two cuts, along with 13 t/ha of dry matter and 20 q/ha of seeds. Its robust performance makes it a preferred variety for high-quality fodder production.",
      },
      {
        heading: "Brunker-10",
        description:
          "Brunker-10 is a quick-growing oats variety characterized by fine, smooth, and narrow leaves. The plants are prostrate with profuse tillering but exhibit slow early growth. Brunker-10 delivers a green fodder yield of 40–45 t/ha in approximately 140 days, making it a valuable crop for farmers. This variety is predominantly cultivated in Punjab, Haryana, Delhi, and Uttar Pradesh, where its adaptability and reliable yield make it a popular choice for fodder production.",
      },
      {
        heading: "Weston 11",
        description:
          "Weston 11, an exotic oats variety released in 1978, is semi-erect with a height of around 155 cm. It features broad leaves and smooth panicles. This variety requires 110 days to flower and 160 days to reach maturity, making it a well-timed choice for farmers seeking a reliable and high-yielding oats crop.",
      },
      {
        heading: "Palampur-1",
        description:
          "Released in 1980, the Palanpur-1 oats variety boasts medium maturity, profuse tillering, and lodging resistance. Notified for cultivation in Himachal Pradesh, Palanpur-1 stands out for its impressive yield, producing approximately 50 t/ha of green fodder. Its robust characteristics make it a reliable choice for both local cultivation and export markets.",
      },
      {
        heading: "Bundel Jai-822",
        description:
          "Developed from a cross between IGO-4268 and Indio-6-5-1 using intervarietal hybridization and pedigree selection, Bundel Jai-822 was released in 1989. This variety features an erect growth habit and glabrous nodes. It flowers in 95–100 days and matures in approximately 125–130 days, making it a robust and efficient choice for growers seeking a high-performing oats variety.",
      },
      {
        heading: "Sabzaar (SKO-7)",
        description:
          "Released in 1997 by SKUA&T in Srinagar, Sabzaar is tailored for temperate Kashmir and high-altitude Jammu regions. Notified for these areas, this variety is known for its profuse tillering and leafy growth, making it ideal for dual purposes. The SKO-7 variety of Sabzaar yields approximately 35–40 t/ha of green fodder, combining robust performance with high productivity.",
      },
      {
        heading: "Harita (RO -19)",
        description:
          "Released in 2007 and notified for Maharashtra, Harita is a versatile multicut oats variety developed from Kent by MPKV, Rahuri. Ideal for winter seasons under irrigated conditions, Harita excels in productivity. It offers an average yield of around 50 t/ha of green forage and approximately 9.5 t/ha of dry matter, making it a highly efficient choice for both forage and feed production.",
      },
      {
        heading: "UPO–212",
        description:
          "Released in 1990, the UPO-212 oats variety is designed for cultivation in northern and central India under a multi-cut system. This variety features a light green stem with 8–10 tillers and thin, variable awns. UPO-212 stands out for its impressive productivity, delivering an average green fodder yield of approximately 60 t/ha. Its robust characteristics make it an excellent choice for high-yield forage production.",
      },
      {
        heading: "OL-125",
        description:
          "Developed through intervarietal hybridization of Appler and IPC-163, and released in 1995, the OL-125 oats variety is tailored for the north-west and central zones of India. Ideal for both single and multi-cut systems, OL-125 offers robust performance with an impressive green fodder yield of 58 t/ha. Its high productivity and adaptability make it a top choice for efficient forage cultivation.",
      },
    ],
    varietyHeading: "Oats Varieties We Export",
    verietyDesc:
      "Oats imports from India feature a wide range of varieties, including HFO-114, Brunker-10, and Weston-11, among others. Indian oats exporters are known for delivering premium quality oats and other grains to markets worldwide. This growing demand for Indian oats presents a valuable opportunity for local vendors to expand into the international market. By exporting these high-quality grains, local businesses can tap into global markets, enhancing their reach and profitability. With the right support and quality products, Indian oats exporters are well-positioned to succeed on the global stage.",
  },
  {
    heading: "Indian Pearl Millet Exporters",
    picture: milletBanner,
    link: "Millet",
    firstLayout: false,
    description:
      "Pearl millet, a towering annual grass reaching up to 3 meters high with a robust root system, is gaining global popularity. Its increasing demand has led Indian exporters to ship tonnes of this versatile grain each year. Beyond its agricultural uses, pearl millet offers notable health and medicinal benefits, contributing to its rising export potential. With its growing global appeal, the export of pearl millet from India is expected to see rapid growth in the coming years. Indian exporters are reaping substantial revenues by supplying this valuable grain to various countries. As a result, pearl millet export presents an excellent business opportunity for food exporters looking to capitalize on this expanding market.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 378 calories",
          "Carbohydrates - 73g",
          "Dietary Fiber - 17g/200 grams",
        ],
      },
      {
        title: "Protein",
        value: ["11g"],
      },
      {
        title: "Vitamin",
        value: [
          "Vitamin E",
          "Vitamin K",
          "Thiamin",
          "Riboflavin",
          "Niacin",
          "Vitamin B6",
          "Folate",
        ],
      },
      {
        title: "Minerals",
        value: [
          "Calcium",
          "Iron",
          "Magnesium",
          "Phosphorus",
          "Potassium",
          "Sodium",
        ],
      },
      {
        title: "Fat",
        value: ["8.4 gm"],
      },
    ],
    benifits: [],
    outro:
      "If you're seeking a reliable pearl millet importer from India, JNTB EXPORT IMPORT is your top choice! As a leading exporter of food products, including pulses, grains, vegetables, and fruits, JNTB EXPORT IMPORT ensures high-quality exports with a strong reputation for trustworthiness. The growing demand for pearl millet presents a lucrative opportunity for Indian vendors and exporters to tap into international markets. Partner with JNTB EXPORT IMPORT to leverage this potential and export premium Indian food products globally with confidence and ease.",
    varieties: [
      {
        heading: "Giant Bajra",
        description:
          "This pearl millet variety is ideal for Bajra-growing regions, featuring leafy plants with profuse tillering and 9-10% protein at the boot stage. Known for its moderate resistance to downy mildew and ergot diseases, it offers robust performance and reliability in various conditions.",
      },
      {
        heading: "Raj Bajra Chari-2",
        description:
          "Notified for cultivation across all Bajra-growing areas, the Raj Bajra Chari-2 variety excels with a green fodder yield of 30–45 t/ha. It boasts resistance to foliar diseases and insect pests, making it a reliable choice for farmers. At ear emergence, the internodes are fully enclosed in the leaf sheath, and the plant features shiny, broad leaves. This variety combines high yield potential with robust disease and pest resistance.",
      },
      {
        heading: "APFB-2",
        description:
          "The APFB-2 variety of pearl millet is ideal for cultivation in Andhra Pradesh. This early-maturing, non-lodging variety is highly responsive to fertilizers and excels in summer and early Kharif sowings. With a plant height of 160–180 cm, APFB-2 delivers a green fodder yield of around 25 t/ha. Its robust growth and high yield make it a valuable choice for efficient fodder production in the region.",
      },
      {
        heading: "PCB-164",
        description:
          "Developed by PAU, Ludhiana from five late-maturing lines, the PCB-164 variety of pearl millet is tailored for the north-western regions of India. Released and notified for these areas, PCB-164 combines robust growth with adaptability, making it a strong choice for farmers seeking reliable millet production in this region.",
      },
      {
        heading: "Avika Bajra Chari (AVKB-19)",
        description:
          "The Avika Bajra Chari variety, also known as AVKB-19, is recommended for cultivation in western Uttar Pradesh, Rajasthan, Haryana, Punjab, and the Tarai region of Uttarakhand. This versatile variety excels in dual purposes, offering a green fodder yield potential of 36.7 t/ha, dry fodder yield of 8.8 t/ha, and a seed yield of 10.2 q/ha. Its robust performance makes it a top choice for farmers seeking high yields in diverse conditions.",
      },
      {
        heading: "Narendra Chara Bajra-2 (NDFB- 2)",
        description:
          "Developed by NDUA&T, Faizabad, the Narendra Chara Bajra variety is tailored for pearl millet cultivation in the north-east zone, specifically in salt-affected soils. This variety offers robust performance in challenging conditions, making it an ideal choice for regions with saline soil, and ensuring reliable yields for local farmers.",
      },
      {
        heading: "FBC-16",
        description:
          "The FBC-16 variety of pearl millet, notified for cultivation across north-west India, is a top choice for farmers. Known for its multi-cut capability and resistance to major diseases, FBC-16 stands out with its low oxalate concentration and high voluntary dry matter intake by animals. This variety delivers an impressive green fodder yield potential of 70–80 t/ha, making it highly productive and valuable for livestock feed in the region.",
      },
      {
        heading: "TNSC-1",
        description:
          "Bred by TNAU, Coimbatore, the TNSC-1 pearl millet variety was recommended in 1995 for all Bajra-growing areas in the country. This high-performing variety yields around 27–40 t/ha of green fodder and is known for its resistance to foliar diseases and insect pests. TNSC-1 combines robust productivity with disease resilience, making it an excellent choice for reliable fodder production.",
      },
      {
        heading: "OL-125",
        description:
          "Developed through intervarietal hybridization of Appler and IPC-163 and released in 1995, the OL-125 oats variety is designed for cultivation in the north-west and central zones of India. This versatile variety supports both single and multi-cut systems, delivering an impressive green fodder yield of 58 t/ha. Its robust performance and adaptability make OL-125 a top choice for efficient and high-yielding oats production.",
      },
    ],
    varietyHeading: "Pearl Millet Varieties We Export",
    verietyDesc:
      "Pearl millet imports from India feature a range of varieties, including Giant Bajra, Raj Bajra, and Chari-2. Indian exporters are renowned for supplying premium-quality pearl millet to numerous countries. With these key varieties at the forefront, the export of pearl millet from India continues to thrive, meeting global demand with top-notch products.",
  },
  {
    heading: "",
    picture: riceBanner,
    link: "",
    firstLayout: true,
    description: "",
    marketDemand: "",
    specifications: [],
    ingredients: [],
    benifits: [],
    outro: "",
    varieties: [],
  },
  {
    heading: "Indian Wheat Exporters",
    picture: wheatBanner,
    link: "Wheat",
    firstLayout: false,
    description:
      "Wheat, a staple cereal grain widely consumed as bread across the globe, is a major export product for India. Indian wheat exporters ship tonnes of this vital grain annually, capitalizing on its higher protein content compared to other major cereals like maize or rice. As global demand for wheat continues to rise, Indian exporters are expanding their shipments of wheat and wheat products. This increasing demand presents a lucrative opportunity for Indian vendors and farmers, enabling them to achieve substantial revenues. With its essential role in diets worldwide and growing international appetite, wheat export from India is poised for significant growth and profitability.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 364 calories",
          "Dietary Fiber - 2.7 g",
          "Total Carbohydrate 76 g",
        ],
      },
      {
        title: "Protein",
        value: ["10g"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin B6"],
      },
      {
        title: "Minerals",
        value: [
          "Iron",
          "Thiamin",
          "Niacin",
          "Calcium",
          "Selenium",
          "Manganese",
          "Phosphorous",
          "Copper",
          "Folate",
        ],
      },
      {
        title: "Fat",
        value: [],
      },
    ],
    benifits: [],
    outro:
      "JNTB EXPORT IMPORT is a top choice for wheat imports from India, renowned for its expertise and reliability. Wheat, a global staple enjoyed in many forms, presents a significant opportunity for expansion in international markets. With extensive experience in wheat export, JNTB EXPORT IMPORT ensures that all health and safety standards are meticulously maintained, delivering only the highest quality wheat. Our commitment to excellence and adherence to stringent quality controls make us the ideal partner for your wheat export needs. Whether you’re looking to import premium wheat or explore new market opportunities, JNTB EXPORT IMPORT stands ready to support your success. Choose JNTB EXPORT IMPORT for a seamless and reliable wheat export experience!",
    varieties: [
      {
        heading: "VL-832",
        description:
          "The VL-832 variety of wheat, exported globally from India, matures in approximately 155-160 days. It boasts an average yield of 25-30 q/ha and is known for its resistance to brown and yellow rusts, as well as loose smut. This robust variety combines high yield potential with strong disease resistance, making it a top choice for international markets seeking reliable and resilient wheat.",
      },
      {
        heading: "VL-804",
        description:
          "The VL-804 wheat variety requires 164-178 days to mature after planting and is notably tolerant of snowfall. It stands out for its resilience, being resistant to both brown and yellow rusts. This robust variety offers reliable performance even in challenging weather conditions, making it a favored choice for regions with variable climates. Its durability and disease resistance ensure a high-quality yield, making VL-804 a valuable option for international wheat markets.",
      },
      {
        heading: "HS-365",
        description:
          "The HS-365 wheat variety thrives in Tamil Nadu, particularly in the Nilgiri and Palni hills regions. Known for its adaptability, HS-365 excels at high altitudes, performing well in diverse conditions. This variety's robust growth in elevated terrains makes it a preferred choice for the region, ensuring a consistent and high-quality yield. Its resilience and performance in challenging environments highlight its value for farmers and exporters in Tamil Nadu and beyond.",
      },
      {
        heading: "HS-240",
        description:
          "The HS-249 wheat variety flourishes in the hills of Uttarakhand, Himachal Pradesh, J&K, Sikkim, and other eastern states. Ideal for medium-altitude regions, it thrives under both timely irrigation and rainfed conditions. Its adaptability to diverse climates and consistent performance make HS-249 a reliable choice for farmers seeking high-quality yields in these mountainous and far-eastern areas.",
      },
      {
        heading: "HD 2687",
        description:
          "The HD 2687 wheat variety is commonly cultivated in Punjab, Haryana, Delhi, Western Uttar Pradesh, Rajasthan, and the foothills of Himachal Pradesh, as well as Jammu & Kashmir and Uttarakhand. It thrives under irrigated, timely-sown conditions, making it a preferred choice for these diverse regions. Its adaptability to these environments ensures robust growth and high yields, meeting the needs of farmers across northern India.",
      },
      {
        heading: "PBW-343",
        description:
          "The PBW-343 wheat variety is cultivated in Punjab, Haryana, Delhi, Western Uttar Pradesh, Rajasthan, and the foothills of Himachal Pradesh, as well as Jammu & Kashmir and Uttarakhand. It thrives under irrigated, timely-sown conditions, making it ideal for these diverse regions. This variety’s adaptability and high yield potential ensure it meets the needs of farmers across northern India.",
      },
      {
        heading: "Shresth (HD 2687)",
        description:
          "Shreshth, also known as HD 2687, is a prominent wheat variety exported from India. Grown across Punjab, Haryana, Delhi, Western Uttar Pradesh, Rajasthan, the foothills of Himachal Pradesh, Jammu & Kashmir, and Uttarakhand, it thrives under irrigated, timely-sown conditions. Its robust growth and high yield make Shreshth a valuable choice for both domestic cultivation and international markets.",
      },
    ],
    varietyHeading: "Wheat Varieties We Export",
    verietyDesc:
      "Wheat imports from India feature a range of premium varieties, including VL-832, VL-804, and HS-365. Indian wheat exporters deliver high-quality products to continents worldwide, ensuring that global markets receive the best. The diverse range of key varieties in wheat export from India highlights the country's commitment to quality and excellence. Whether for baking or other uses, Indian wheat stands out for its superior quality and consistency in international trade.",
  },
  {
    heading: "Indian Barley Exporters",
    picture: barleyBanner,
    link: "Barley",
    firstLayout: false,
    description:
      "Barley, a major cereal grain from the grass family, thrives in temperate climates around the world. India plays a significant role in the global barley market, with exporters shipping tonnes of high-quality barley to various countries. As global demand for barley rises, Indian barley exports continue to grow each year. Indian exporters are known for their premium barley and diverse barley products, meeting international standards and preferences. With a range of barley varieties available, Indian barley exporters are well-positioned to cater to the increasing global appetite for this versatile grain, making barley export from India a thriving and lucrative industry.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 354 calories",
          "Dietary Fiber - 17 g",
          "Total Carbohydrate 73 g",
        ],
      },
      {
        title: "Protein",
        value: ["22g"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin B1"],
      },
      {
        title: "Minerals",
        value: [
          "Molubdenum",
          "Manganese",
          "Selenium",
          "Copper",
          "Chromium",
          "Phosphorous",
          "Magnesium",
          "Niacin",
        ],
      },
      {
        title: "Fat",
        value: [],
      },
    ],
    benifits: [],
    outro:
      "JNTB EXPORT IMPORT stands out as a top barley importer from India, renowned for its expertise in exporting high-quality barley and a range of other food products globally. With the growing demand for barley, India offers a prime opportunity for vendors and farmers to expand into international markets and achieve substantial revenue. JNTB EXPORT IMPORT leverages its extensive network and industry knowledge to ensure seamless export processes, delivering premium barley to numerous destinations around the world. If you're looking to export barley and tap into global markets, JNTB EXPORT IMPORT is your ideal partner for reliable service and outstanding results. Choose JNTB EXPORT IMPORT to maximize your barley export potential and grow your business internationally!",
    varieties: [
      {
        heading: "Azad (K.125)",
        description:
          "The Azad barley variety thrives in eastern Uttar Pradesh, Bihar, and West Bengal. Known for its resistance to yellow rust disease, Azad matures in 115-120 days and offers a yield potential of 35-38 quintals per hectare under irrigated conditions. Valued for both fodder and grain, this variety stands out for its resilience and productivity, making it a reliable choice for farmers seeking high-quality barley in these regions.",
      },
      {
        heading: "Ratna",
        description:
          "The Ratna barley variety is ideal for rainfed areas in eastern Uttar Pradesh, Bihar, and West Bengal. Notable for its high tolerance to saline and alkaline soils, Ratna matures in approximately 125-130 days. It delivers a robust yield even in challenging environmental conditions, thanks to its excellent tillering ability. This resilience makes Ratna a dependable choice for barley cultivation in less favorable climates, ensuring a reliable harvest under various circumstances.",
      },
      {
        heading: "Vijaya",
        description:
          "The Vijaya barley variety is tailored for rainfed regions in western Uttar Pradesh, Delhi, and Madhya Pradesh. Maturing in about 120-125 days, Vijaya is characterized by its shorter plant height compared to other barley varieties. Despite its compact stature, it offers a strong yield potential of 30-35 quintals of grain per hectare. Its adaptability and efficiency make Vijaya a valuable choice for barley cultivation in these diverse and challenging environments.",
      },
      {
        heading: "RS-6",
        description:
          "The RS-6 barley variety, developed in Rajasthan, excels under both rainfed and irrigated conditions. Recommended for central and eastern Rajasthan, it matures in approximately 130-135 days. Known for its high yield potential, RS-6 produces 35 to 40 quintals of grain per hectare. Its adaptability and robust performance make it an excellent choice for farmers seeking reliable and productive barley cultivation in Rajasthan’s diverse environments.",
      },
      {
        heading: "Ranjit (DL- 70)",
        description:
          "Ranjit, also known as DL-70, is a six-row barley variety renowned for its heavy tillering, specifically recommended for commercial cultivation in Punjab. Thriving under irrigated conditions, Ranjit offers a yield potential of 30-35 quintals per hectare. Its robust growth and high productivity make it an ideal choice for farmers in Punjab seeking a reliable and high-yielding barley variety for their commercial needs.",
      },
      {
        heading: "C- l64",
        description:
          "The C-164 barley variety is a tall, six-row type known for its compact ears and long awns. This variety features bold, bright, amber grains and is resistant to yellow rust. With its stiff straw, C-164 is particularly well-suited for irrigated conditions. It delivers a yield of approximately 30-32 quintals of grain per hectare, making it a reliable choice for farmers seeking a high-performing barley variety under controlled irrigation.",
      },
      {
        heading: "LSB-2",
        description:
          "LSB is a six-row naked barley variety ideal for high-altitude regions such as Himachal Pradesh and the Uttar Pradesh hills. Maturing in approximately 145-150 days, LSB offers a yield potential of 25-30 quintals of grain per hectare. Its adaptability to elevated terrains and robust performance make it a valuable choice for cultivating barley in challenging hill environments, ensuring a dependable and productive harvest.",
      },
    ],
    varietyHeading: "Barley Varieties We Export",
    verietyDesc:
      "India grows a diverse range of barley varieties, including Azad, Ratna, and Vijaya. Barley import from India encompasses these key varieties, showcasing the country’s rich barley production. Indian barley exporters deliver these varieties to global markets, meeting international demand with high-quality products. The export of barley from India features these prominent varieties, reflecting the country’s commitment to providing diverse and premium barley options worldwide.",
  },
  {
    heading: "Indian Triticale Exporter",
    picture: triticaleBanner,
    link: "Triticale",
    firstLayout: true,
    description:
      "India's Triticale exports have been on the rise, thanks to the crop's versatility and global demand. Triticale, a hybrid of wheat and rye, serves multiple purposes—from grain and forage to fuel and cover crops. Indian exporters are meeting this growing demand by supplying various Triticale varieties to markets worldwide. With its broad applications in animal feed, wildlife food plots, and sustainable farming, Triticale has become a key export commodity. The steady increase in India's Triticale exports reflects both the crop's value and the country's expanding role in global agriculture. As more countries recognize its benefits, India continues to solidify its position as a leading Triticale exporter.",
    marketDemand:
      "Triticale, a man-made hybrid of wheat and rye, offers unique advantages, combining rye's disease resistance with wheat's superior seed and forage quality. This powerful combination has led to a surge in global demand for Triticale. Indian exporters are at the forefront, delivering premium-quality Triticale to various international markets. Thanks to its strong resistance to plant diseases and viruses, Triticale from India is increasingly favored by global buyers. As the demand for resilient and high-quality crops continues to rise, India's Triticale exports are expected to grow significantly in the coming years, cementing the country's position as a key player in the global agricultural market.",
    specifications: [
      {
        title: "Product Name",
        value: "Triticale",
      },
      {
        title: "Origin",
        value: "Scotland and Germany",
      },
      {
        title: "Family",
        value: "Poaceae",
      },
      {
        title: "Binomial name",
        value: "Triticosecale",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories 336",
          "Total Carbohydrate - 72g",
          "Dietary Fiber - 0g",
        ],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Iron", "Potassium"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin D"],
      },
      {
        title: "Protein",
        value: ["13 g"],
      },
      {
        title: "Fats",
        value: ["2.1g"],
      },
    ],
    benifits: [
      "Triticale is versatile in grain milling, easily processed into flour using either wheat or rye milling techniques.",
      "Whole and refined triticale are ideal for baking, used in products like breads, oriental noodles, and various soft wheat-based goods.",
      "Certain triticale varieties excel in producing dense, flatbreads under specific conditions, like low mixing speeds and shorter fermentation times, making them perfect for specialized bread-making techniques.",
    ],
    outro:
      "JNTB EXPORT IMPORT is the leading importer of Triticale from India, simplifying the complex export process for our clients. With extensive experience in the food export industry, our team at JNTB EXPORT IMPORT ensures a seamless Triticale export experience, handling everything from documentation to final delivery. We are dedicated to making your experience hassle-free, guiding you through each step with expertise and care. If you’re looking to import top-quality Triticale from India, JNTB EXPORT IMPORT is your trusted partner. Contact us today to make your Triticale export process smooth and efficient!",
    varieties: [],
  },
  {
    heading: "Indian Fonio Exporter",
    picture: fonioBanner,
    link: "Fonio",
    firstLayout: true,
    description:
      "Fonio, an ancient grain known for its versatility in making porridge, is gaining global popularity, leading to a rise in exports from India. As a leading Fonio exporter, India supplies premium-quality grain to various countries, meeting the growing international demand. The plant’s blade-like, linear, and tapering leaves are a distinctive feature of this annual grass. Indian exporters ensure that only the finest varieties of Fonio reach global markets, contributing to its increasing recognition as a nutritious and sustainable food source. With each passing year, Fonio exports from India continue to thrive, catering to health-conscious consumers worldwide.",
    marketDemand:
      "The rising global demand for Fonio, driven by its rich nutritional profile, presents a lucrative opportunity for Indian exporters to expand into international markets. As Fonio imports from India increase, local farmers and vendors are reaping the benefits, earning higher revenues from this ancient grain. The growing popularity of Fonio on the global stage not only boosts the Indian export market but also supports sustainable agriculture and economic growth for those cultivating this nutritious crop. For Fonio exporters in India, this trend offers a promising pathway to business expansion and success in the global marketplace.",
    specifications: [
      {
        title: "Product Name",
        value: "Fonio",
      },
      {
        title: "Origin",
        value: "Africa",
      },
      {
        title: "Family",
        value: "Poaceae",
      },
      {
        title: "Binomial name",
        value: "Digitaria exilis",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: ["Calories 170", "Carbohydrate - 39g", "Fiber - 1g"],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Iron", "Phosphorous"],
      },
      {
        title: "Protein",
        value: ["2g"],
      },
      {
        title: "Fats",
        value: ["0.5g"],
      },
    ],
    benifits: [
      "Fonio can be enjoyed as porridge or couscous, and it's often ground and blended with other cereals for added versatility.",
      "Fonio grain is occasionally brewed into beer, while its straw serves as nutritious animal fodder, maximizing its agricultural value.",
      "Fonio boasts high iron levels to combat anemia, and its folic acid and amino acids support health during pregnancy.",
      "Fonio is rich in essential amino acids like cysteine and methionine, aiding in liver detoxification and overall body cleansing.",
    ],
    outro:
      "JNTB EXPORT IMPORT stands out as a top Fonio importer from India, dedicated to making your export experience seamless. We understand the complexities of Fonio export and are here to help every step of the way. With extensive experience in the food export industry, our skilled team ensures a smooth process from start to finish, handling any issues that may arise with expertise and efficiency. At JNTB EXPORT IMPORT, we’re committed to delivering exceptional service and premium-quality Fonio to global markets. If you’re seeking a reliable partner for Fonio exports from India, look no further. Contact JNTB EXPORT IMPORT today and let us assist you with all your export needs!",
    varieties: [],
  },
  {
    heading: "Indian Sorghum Exporter",
    picture: sorghumBanner,
    link: "Sorghum",
    firstLayout: true,
    description:
      "Sorghum, known in India as Jowar, Cholam, or Jonna, is a versatile crop with a range of applications across various industries. Its starchy seeds make it a valuable food source, and its strength as a grass adds to its appeal. Indian Sorghum exporters are meeting growing global demand, with substantial quantities being shipped each year. The rising popularity of Sorghum presents a prime opportunity for Indian exporters to broaden their reach and tap into international markets. With an array of varieties and qualities available, Sorghum export from India is thriving. This surge in demand highlights the potential for expanding business and establishing a stronger presence in the global marketplace.",
    marketDemand:
      "Sorghum, a staple food crop in Africa, serves multiple purposes, including as food, hay, and fodder. Renowned for its resilience to drought and heat, Sorghum thrives in hot, arid regions. Indian Sorghum exporters are meeting global demand by supplying high-quality Sorghum to various international markets. The increasing need for this versatile crop has driven a steady rise in Sorghum imports from India over the past few years. With its exceptional adaptability and diverse uses, Sorghum continues to gain traction worldwide, presenting Indian exporters with a significant opportunity to expand their global footprint and cater to the growing appetite for this valuable grain.",
    specifications: [
      {
        title: "Product Name",
        value: "Sorghum",
      },
      {
        title: "Origin",
        value: "Northeastern Africa",
      },
      {
        title: "Family",
        value: "Poaceae",
      },
      {
        title: "Binomial name",
        value: "Sorghum bicolor",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: ["Calories 316", "Carbohydrate - 69g", "Fiber - 6g"],
      },
      {
        title: "Minerals",
        value: [
          "Magnesium",
          "Iron",
          "Copper",
          "Phosphorous",
          "Zinc",
          "Potassium",
        ],
      },
      {
        title: "Vitamins",
        value: ["Vitamin B1", "Vitamin B2", "Vitamin B5", "Vitamin B6"],
      },
      {
        title: "Protein",
        value: ["10g"],
      },
      {
        title: "Fats",
        value: ["3g"],
      },
    ],
    benifits: [
      "Sorghum is versatile: used as food, and also in producing edible oil, starch, dextrose, and various alcoholic beverages.",
      "Sorghum serves as hay and fodder, with its stalks also used for animal feed and as building materials.",
      "Sorghum is crafted into durable brooms and brushes, showcasing its versatility beyond just food and feed.",
      "Sweet Sorghum is ideal for making syrup and producing ethyl alcohol, a key ingredient in biofuels.",
    ],
    outro:
      "JNTB EXPORT IMPORT is a top Sorghum importer from India, dedicated to simplifying the export process for our clients. Our experienced team provides comprehensive support at every stage of the Sorghum export journey, ensuring a smooth and hassle-free experience. We prioritize adherence to all government health and safety regulations, guaranteeing that your products are handled and delivered with the utmost care. With a strong commitment to quality and efficiency, JNTB EXPORT IMPORT makes international Sorghum trade seamless and reliable. If you're seeking a trusted partner for Sorghum export from India, look no further. Contact JNTB EXPORT IMPORT today and let us manage your export needs with expertise and precision!",
    varieties: [],
  },
  {
    heading: "Indian Teff Exporter",
    picture: teffBanner,
    link: "Teff",
    firstLayout: true,
    description:
      "Teff, a tiny ancient grain from Ethiopia and Eritrea, is a staple in their traditional cuisines. Known for its fine size, comparable to a poppy seed, Teff is gaining global recognition. Indian Teff exporters are at the forefront of this trend, shipping substantial quantities of premium-quality Teff each year. As international demand for Teff surges, Indian exporters are increasingly meeting this need with diverse varieties of this versatile grain. The rise in global popularity highlights the growing role of Indian exporters in providing high-quality Teff to the world. For top-notch Teff and reliable export services, look to Indian Teff exporters to deliver excellence on a global scale.",
    marketDemand:
      "The global demand for Teff has been rising steadily, fueled by the increasing popularity of gluten-free alternatives to wheat. This ancient grain, prized for its nutritional benefits and versatility, presents a prime opportunity for Indian Teff exporters to expand their businesses internationally. As consumers seek healthier options, Teff stands out as an excellent choice, driving a notable increase in imports from India. Indian exporters are capitalizing on this trend by supplying high-quality Teff to meet growing global needs. With its superior quality and rising international appeal, Teff import from India is on the rise, making it an exciting time for exporters to establish a strong global presence.",
    specifications: [
      {
        title: "Product Name",
        value: "Teff",
      },
      {
        title: "Origin",
        value: "Ethiopia",
      },
      {
        title: "Family",
        value: "Poaceae",
      },
      {
        title: "Binomial name",
        value: "Eragrostis tef",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: ["Calories 366", "Carbs - 70.7g", "Fiber - 12.2g"],
      },
      {
        title: "Minerals",
        value: [
          "Copper",
          "Magnesium",
          "Potassium",
          "Phosphorous",
          "Manganese",
          "Zinc",
          "Selenium",
        ],
      },
      {
        title: "Protein",
        value: ["12.2g"],
      },
      {
        title: "Fats",
        value: ["3.7g"],
      },
    ],
    benifits: [
      "Teff's global popularity stems from its gluten-free nature, making it ideal for whole grain and gluten-free flour.",
      "In Ethiopia, teff flour is fermented with yeast to create injera, a traditional sourdough flatbread with a unique flavor.",
      "Teff flour is versatile, used for baking bread and crafting packaged foods like pasta. It’s an excellent gluten-free alternative, adding a nutritious twist to a range of products.",
    ],
    outro:
      "JNTB EXPORT IMPORT is a top Teff importer from India, dedicated to ensuring a seamless export experience for our clients. From handling documentation to overseeing the final delivery, we manage every detail with expertise. Our global presence and extensive experience have consistently benefited our customers, providing reliable service and support throughout the Teff export process. Our knowledgeable team is ready to assist with any issues, doubts, or queries, ensuring a smooth and hassle-free transaction. If you're seeking a trusted partner for Teff export from India, look no further. Contact JNTB EXPORT IMPORT today and let us guide you through a successful export journey!",
    varieties: [],
  },
  {
    heading: "Indian Chickpea Exporters",
    picture: chickpeasBanner,
    link: "Chickpeas",
    firstLayout: false,
    description:
      "India is a leading global producer of chickpeas, and its exporters are shipping increasing quantities each year. Chickpea exports from India include two main varieties: 'Kabuli' and 'Desi,' along with several other types. Known for their premium quality, Indian chickpeas are highly sought after worldwide. The diverse range of varieties ensures that buyers get the perfect match for their needs, whether for culinary or industrial uses. Indian chickpea exporters are committed to delivering top-notch products, meeting the growing global demand with exceptional service. As India's reputation as a top chickpea source continues to rise, exporters are poised to expand their reach and maintain high standards of quality.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 364 calories / 100grams",
          "Carbohydrates - 61g/100 grams",
          "Fiber - 17g/100grams",
        ],
      },
      {
        title: "Protein",
        value: ["1.3g"],
      },
      {
        title: "Fat",
        value: ["0.1g"],
      },
      {
        title: "Vitamin",
        value: ["Vitamin C, Vitamin K, Vitamin B6"],
      },
      {
        title: "Minerals",
        value: ["Thiamin", "Riboflavin", "Niacin", "Pantothenic acid"],
      },
    ],
    benifits: [],
    outro:
      "JNTB EXPORT IMPORT stands out as a top chickpea importer from India, dedicated to sharing Indian culture, spices, and food products globally. Chickpeas are among our most sought-after exports, prized for their health benefits and delicious flavor. As demand for this versatile legume grows worldwide, JNTB EXPORT IMPORT ensures you receive only the highest quality chickpeas. Our commitment to excellence and deep understanding of the global market make us the ideal choice for your chickpea export needs. For premium chickpeas and reliable service, JNTB EXPORT IMPORT is your best partner. Contact us today to experience the best in chickpea exports!",
    varieties: [
      {
        heading: "L 550",
        description:
          "Released in 1978, the L 550 chickpea variety matures in 136-140 days and features bold, salmon-white seeds. Known for its impressive yield of 17-20 q/ha, L 550 is a popular choice for its robust growth and quality. This variety’s notable characteristics and reliable performance make it a top pick for both farmers and exporters, ensuring a strong presence in the global chickpea market.",
      },
      {
        heading: "JG 315",
        description:
          "Introduced in 1984, the JG 315 chickpea variety matures in 125-130 days and is known for its wilt resistance. These chickpeas feature distinctive brown, wrinkled seeds and offer a solid average yield of 19-20 q/ha. JG 315's resilience and high productivity make it a standout choice for export, catering to global markets seeking quality and reliability. Its impressive attributes contribute to its popularity among farmers and exporters alike.",
      },
      {
        heading: "Pusa 391",
        description:
          "Released in 1997, the Pusa 391 chickpea variety matures in just 110-120 days. Known for its bold, light brown seeds, Pusa 391 offers an average yield of 17-18 q/ha. This variety stands out for its quick maturation and high-quality seeds, making it a favored choice for farmers and exporters. Its efficiency and appealing characteristics ensure its growing popularity in global chickpea markets, enhancing its reputation as a reliable and productive option.",
      },
      {
        heading: "BGD 72",
        description:
          "Released in 1999, the BGD 72 chickpea variety is a key export from India, maturing in 135-140 days. Known for its resistance to wilt and root rot, BGD 72 features bold seeds and delivers an impressive average yield of 25-30 q/ha. This variety's robustness and high productivity make it a preferred choice for both farmers and international markets, showcasing India’s commitment to quality and reliability in chickpea exports.",
      },
      {
        heading: "Gujarat Gram 1",
        description:
          "Released in 1999, Gujarat Gram 1 is a standout chickpea variety known for its quick maturation in just 105-110 days. This variety is resistant to wilt and features a robust average yield of 17-22 q/ha. Gujarat Gram 1 combines resilience with productivity, making it an attractive option for both farmers and exporters. Its efficient growth and dependable yield contribute to its growing popularity in the global chickpea market.",
      },
      {
        heading: "ICCC 32",
        description:
          "The ICCC 32, released in 1985, is a notable chickpea variety exported from India. It matures in 130-150 days and features medium-sized seeds. Known for its resistance to wilt, ICCC 32 offers a reliable average yield of 18-20 q/ha. Its durability and steady productivity make it a favored choice among farmers and exporters, ensuring a strong presence in the global chickpea market.",
      },
      {
        heading: "KAK 2",
        description:
          "Released in 2000, the KAK 2 chickpea variety is a key export from India, maturing in 125-130 days. This variety is characterized by its bold seeds and delivers an average yield of 17-18 q/ha. KAK 2's robust growth and solid yield make it a popular choice for farmers and international markets alike, reflecting its high quality and reliability in the global chickpea trade.",
      },
      {
        heading: "Uday (KPG 59)",
        description:
          "Released in 1992, Uday (or KPG 59) is a prominent chickpea variety exported from India. It matures in 130-140 days and is known for its late sowing characteristics. With its solid average yield of 18-20 q/ha, Uday combines productivity with reliability. This variety's notable attributes make it a preferred choice for both farmers and international markets, showcasing its value and quality in the global chickpea trade.",
      },
      {
        heading: "GNG 663 (Vardan)",
        description:
          "Released in 1995, the GNG 63, also known as Vardan, is a top-tier chickpea variety exported from India. It matures in 150-155 days and is notable for its resistance to wilt and tolerance to Ascochyta blight. With an impressive average yield of 22-25 q/ha, Vardan combines resilience with high productivity. Its exceptional qualities make it a favored choice for farmers and international markets, ensuring its strong presence in the global chickpea industry.",
      },
      {
        heading: "Karna Chana (CSG 8962)",
        description:
          "Released in 1997, the Karna Chana, also known as CSG 8962, is a standout chickpea variety from India. It matures in 140-147 days and is especially suited for salt-affected areas. With its robust growth and high average yield of 22-25 q/ha, Karna Chana combines resilience with impressive productivity. This variety's adaptability and reliability make it a popular choice for farmers and exporters, ensuring its strong reputation in the global chickpea market.",
      },
    ],
    varietyHeading: "Chick Pea Varieties We Export",
    verietyDesc:
      "Chickpea imports from India include a range of varieties like L550, JG315, and Pusa 391, all known for their excellent quality. Indian chickpea exporters provide these sought-after varieties to meet the growing global demand. As chickpeas gain popularity worldwide for their versatility and health benefits, the export of chickpeas from India continues to rise, reflecting the country's commitment to delivering top-quality legumes to international markets.",
  },
  {
    heading: "Indian Pea Exporters",
    picture: peasBanner,
    link: "Peas",
    firstLayout: false,
    description:
      "With the global demand for peas on the rise, Indian pea exporters are expanding their exports to meet this growing need. Peas are celebrated for their health benefits and versatility in a variety of cuisines, driving an increase in exports from India. Indian exporters offer peas in both fresh and frozen forms, catering to diverse market preferences. Additionally, the export portfolio includes a range of pea varieties, ensuring that buyers receive the quality and type that best suits their needs. As the international appetite for peas continues to grow, Indian exporters are well-positioned to provide premium products and reliable service to global markets.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 62 calories / 100grams",
          "Carbs- 11 grams",
          "Fiber- 4 grams",
        ],
      },
      {
        title: "Protein",
        value: ["4 grams"],
      },
      {
        title: "Fat",
        value: ["0.4g/100 grams"],
      },
      {
        title: "Vitamin",
        value: ["Vitamin A, Vitamin C, Vitamin K"],
      },
      {
        title: "Minerals",
        value: [
          "Protein",
          "Riboflavin",
          "Niacin",
          "Vitamin B6",
          "Folate",
          "Magnesium",
          "Phosphorus",
          "Copper",
        ],
      },
    ],
    benifits: [],
    outro:
      "JNTB EXPORT IMPORT proudly exports the finest quality peas worldwide, establishing itself as a leading pea importer from India. With extensive experience in the food industry, JNTB EXPORT IMPORT has built a robust network of global connections, ensuring timely and efficient pea exports to various international markets. Our expertise in market analysis and understanding of global demands enables us to handle the export process with precision and discipline. Whether you need fresh or frozen peas, JNTB EXPORT IMPORT is your premier choice for reliable and high-quality exports. Trust us to deliver excellence in every shipment and meet your international pea export needs with unmatched professionalism. Contact JNTB EXPORT IMPORT today for a seamless export experience!",
    varieties: [
      {
        heading: "Asauji",
        description:
          "Asauji is a dwarf pea variety known for its green, smooth seeds and unique pod characteristics. Pods develop singly and mature in about 30-35 days after sowing. Each pod is around 8 cm long, curved, dark green, and narrow. Fully developed peas are round and contain seven seeds. This variety’s distinctive features and rapid growth make it a popular choice for quality pea cultivation, delivering both aesthetic appeal and excellent yield.",
      },
      {
        heading: "Arkel",
        description:
          "The Arkel pea variety, an exotic gem from France, features a dwarf plant that grows vigorously up to 45 cm. Its white flowers appear in double clusters on the lower nodes. The pods are dark green, 8.5 cm long, and incurved towards the sutures with distinctive wrinkled seeds. Ideal for both fresh markets and dehydration, Arkel combines unique aesthetics with versatile usage, making it a prized choice for pea enthusiasts and commercial growers alike.",
      },
      {
        heading: "Little Marvel",
        description:
          "Little Marvel is a dwarf pea variety from England, renowned for its wrinkled seeds and exceptional sweetness. The plant’s foliage is dark green, and the first blossoms appear at the 9th-10th node, 40 days after sowing. Pods are 8 cm long, borne singly, and feature a thick, shiny appearance. Each pod contains 5-6 very sweet seeds, making Little Marvel a standout choice for those seeking delicious, high-quality peas with a compact, productive plant.",
      },
      {
        heading: "Jawahar Matar",
        description:
          "The Jawahar Matar variety, developed in Jabalpur through the hybridization of T19 x Early Badger, features a bushy plant growing 70-75 cm tall. Its light green, roundish-oval pods contain 4-5 wrinkled seeds. This variety is known for its early harvest, with the first picking ready just 50-55 days after sowing. Jawahar Matar combines robust growth with a quick yield, making it an excellent choice for efficient and productive pea cultivation.",
      },
      {
        heading: "Harbhajan (EC 33866)",
        description:
          "The Harbhajan pea variety, developed at Jabalpur from exotic genetic stock, is known for its early harvest, with the first picking ready in just 45 days. This variety features small pods and yellow, round seeds. Harbhajan is valued for its efficiency, boasting an average pod yield of 3 t/ha. Its quick maturation and high yield make it an attractive choice for growers seeking a productive and timely pea variety.",
      },
      {
        heading: "Bonneville",
        description:
          "Introduced in the USA, the Bonneville pea variety is highly popular for its impressive features. The medium-tall plant produces flowers predominantly in doubles. Its pods are large, straight, and light green, containing 6-7 bold, wrinkled seeds that are both sweet and well-filled. Bonneville takes about 65-70 days to reach first flowering. While it excels in quality and yield, it is notably susceptible to powdery mildew disease. Its standout attributes make it a favorite among pea enthusiasts.",
      },
      {
        heading: "Lincon",
        description:
          "The Lincoln pea variety features medium-tall plants with a typically double-podded growth. Its pods are dark green, curved, and large, holding 8-9 wrinkled seeds. This variety is known for its high productivity, with the first picking ready around 85-90 days after sowing. Lincoln boasts an impressive average pod yield of about 10 t/ha, making it a top choice for growers seeking robust performance and excellent yields.",
      },
      {
        heading: "Punjab 88 (P-88)",
        description:
          "The Punjab 88 pea variety, developed at Ludhiana from the Pusa02 × Morriss-55 cross, is known for its dwarf, vigorous, and erect plants with dark green foliage. This variety's pods are dark green, long, and slightly curved in the center, containing 7-8 green, wrinkled seeds. Harvesting begins around 100 days after sowing. While the seeds are less sweet, Punjab 88's robust growth and distinctive pod characteristics make it a notable choice for diverse pea cultivation needs.",
      },
      {
        heading: "Azad P-2",
        description:
          "The Azad P-2 pea variety, developed at Kalyanpur from the cross Bonneville × 6587, is known for its powdery mildew resistance. The plants are tall, erect, and feature light green foliage with white flowers. Azad P-2 pods are medium-sized, light green, straight, smooth, and firm, typically borne in clusters of two. Each pod contains 6-7 wrinkled, brownish seeds. This variety combines resilience with quality, making it a reliable choice for growers seeking robust and productive peas.",
      },
      {
        heading: "Ooty-1",
        description:
          "The Ooty-1 pea variety, developed at Udhagamandalam from the PS 33 accession through pure line selection, is a dwarf plant with impressive attributes. It boasts a high yield potential of 11.9 t/ha and achieves this in a rapid 90-day crop duration. Notably, Ooty-1 is resistant to whitefly, ensuring healthier plants and more reliable harvests. Its combination of robust yield and pest resistance makes it a standout choice for efficient and productive pea cultivation.",
      },
    ],
    varietyHeading: "Pea Varieties We Export",
    verietyDesc:
      "The pea import from India features a diverse range of varieties, including Asauji, Arkel, and Little Marvel, among others. Each variety is exported fresh and meets high-quality standards. Indian pea exporters are committed to delivering premium products that cater to international market demands. With a focus on excellence, these fresh peas bring exceptional taste and nutritional value to global buyers. Explore the variety and quality of Indian peas with our reliable export services.",
  },
  {
    heading: "Indian Kidney Beans Exporter",
    picture: kidneybeansBanner,
    link: "Kidney beans",
    firstLayout: true,
    description:
      "Kidney beans, a variety of common beans, are known for their distinctive reddish-brown color and kidney-like shape. Available year-round, they bring a unique taste to both hot and cold dishes, making them a staple in Mexican and Indian cuisines, often featured in curries and salads. With growing global demand, the kidney beans export from India has surged, offering significant revenue opportunities for Indian exporters. JNTB EXPORT IMPORT, a leading exporter of kidney beans from India, is renowned for delivering premium quality beans to various countries. If you're seeking reliable and top-quality kidney beans, JNTB EXPORT IMPORT is your best choice for export needs.",
    marketDemand:
      "Kidney beans are a highly nutritious legume, rich in protein, minerals, vitamins, antioxidants, and fibers. This nutritional profile has contributed to the increasing demand for kidney beans worldwide, driving up imports from India. As one of the leading exporters, Indian suppliers ensure premium quality beans that meet international standards. They prioritize timely delivery and strictly adhere to health and safety protocols during the export process. JNTB EXPORT IMPORT, a prominent player in this industry, guarantees top-tier kidney beans exports, satisfying global markets with quality and reliability. This commitment to excellence has solidified India’s reputation as a major exporter of kidney beans.",
    specifications: [
      {
        title: "Product Name",
        value: "Kidney Beans",
      },
      {
        title: "Origin",
        value: "Peru",
      },
      {
        title: "Family",
        value: "Fabaceae",
      },
      {
        title: "Binomial name",
        value: "Phaseolus Vulgaris",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: ["Carbohydrates 22.8"],
      },
      {
        title: "Minerals",
        value: ["Folate", "Iron", "Copper", "Manganese", "Molybdenum"],
      },
      {
        title: "Vitamin",
        value: ["Vitamin K1"],
      },
      {
        title: "Protein",
        value: ["8.7 g"],
      },
      {
        title: "Fats",
        value: ["0.50g"],
      },
    ],
    benifits: [
      "In India, kidney beans are known as Rajma, a popular dish enjoyed as a curry with rice or bread and loved in cuisines worldwide.",
      "Kidney beans are rich in potassium and magnesium, and their dietary fiber helps lower cholesterol levels while also aiding in blood pressure management, making them a heart-healthy food choice.",
      "Rich in protein, kidney beans help regulate blood sugar levels, making them an excellent addition to a balanced diet.",
      "Packed with iron, kidney beans boost energy levels and aid digestion, making them a nutritious choice for a healthy lifestyle.",
    ],
    outro:
      "JNTB EXPORT IMPORT is a top exporter of Star Anise from India. Exporting products involves a range of responsibilities, from managing documentation to ensuring that the product meets quality and health standards. At JNTB EXPORT IMPORT, we pride ourselves on being the best in the business, guiding you through every step of the export process. Our team is dedicated to ensuring that your Star Anise reaches any corner of the world in the freshest and safest condition. Trust JNTB EXPORT IMPORT to handle your exports with care and expertise!",
    varieties: [],
  },
  {
    heading: "Indian Soya Bean Exporters",
    picture: soyabeanBanner,
    link: "Soya bean",
    firstLayout: false,
    description:
      "India's favorable climate and soil conditions make it ideal for growing a wide range of crops, including fruits, vegetables, pulses, and grains. Among these, soybean stands out as a major export commodity. Indian soybean exporters ship around 3.5 million tonnes of soybean annually to countries such as Vietnam, Japan, Thailand, Indonesia, the UAE, and Greece. The rising global demand for soybeans has led to a significant increase in soybean exports from India. As a result, the soybean export industry is booming, with Indian exporters delivering high-quality produce to meet the growing needs of international markets.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 446g/100 grams",
          "Carbohydrates - 30g",
          "Fiber - 9g",
        ],
      },
      {
        title: "Protein",
        value: ["36 g"],
      },
      {
        title: "Fat",
        value: ["20g"],
      },
      {
        title: "Vitamin",
        value: ["Vitamin K1"],
      },
      {
        title: "Minerals",
        value: ["Folate", "Copper", "Manganese", "Phosphorus", "Thiamine"],
      },
    ],
    benifits: [],
    outro:
      "JNTB EXPORT IMPORT is a top soybean importer from India, recognized for its commitment to quality and reliability. With the rising global demand for soybeans, Indian exporters have significantly increased production to meet this demand, making soybean export a lucrative industry. JNTB EXPORT IMPORT plays a key role in this thriving sector, helping clients navigate the complexities of international trade with ease. Our extensive experience and dedication to customer satisfaction ensure that your soybean export needs are met efficiently. If you’re looking to export soybeans from India, JNTB EXPORT IMPORT is the trusted partner you need for seamless, profitable transactions. Choose JNTB EXPORT IMPORT for the best in soybean export!",
    varieties: [
      {
        heading: "Ahilya-1 (NRC 2)",
        description:
          "Ahilya-1, also known as NRC 2, is a robust soybean variety renowned for its resistance to Rhizoctonia, pod blight, green mosaic virus, bacterial blight, and tolerance to Cercospora leaf spot and Anthracnose. With a maturation period of approximately 103-106 days, Ahilya-1 offers an impressive average yield of 25-30 quintals per hectare. Its resilience and high yield make it a favored choice among soybean exporters, contributing to its growing popularity in global markets.",
      },
      {
        heading: "Ahilya-3 (NRC 7)",
        description:
          "Ahilya-3 is a high-performing soybean variety known for its resistance to bacterial blight, green mosaic virus, bacterial pustules, phyllody, soybean mosaic, Myrothecium, and Cercospora leaf spots. It also exhibits tolerance to pests like stem fly, girdle beetle, and green and grey semi-loopers. With a maturation period of just 90-99 days, Ahilya-3 offers a rapid turnaround and robust performance, making it a valuable choice for soybean exporters and an asset in global agricultural markets.",
      },
      {
        heading: "Alankar",
        description:
          "The Alankar variety of soybean matures in approximately 115-120 days and boasts an average yield of 20-25 quintals per hectare. This variety is notable for its resistance to bacterial pustules and its tolerance to yellow mosaic virus. Its robust characteristics and dependable yield make Alankar a preferred choice among soybean exporters, enhancing its reputation in international markets. This variety's resilience and productivity contribute to its growing popularity globally.",
      },
      {
        heading: "ADT-1",
        description:
          "The ADT-1 variety of soybean matures in about 85-90 days and is recognized for its robust tolerance to leaf miner and leaf webber. This variety offers a quick turnaround with dependable performance, making it a popular choice among soybean exporters. Its resilience against common pests ensures a healthy yield and contributes to its growing demand in international markets. The ADT-1's efficient growth and pest resistance enhance its appeal for both local and global buyers.",
      },
      {
        heading: "Durga (JS 72-280)",
        description:
          "The Durga variety of soybean matures in approximately 102-105 days and yields an average of 20-22 q/ha. Renowned for its resilience, Durga is tolerant to bacterial pustules, making it a reliable choice for export. This variety's robust performance and consistent yield contribute to its popularity among Indian soybean exporters. With its resistance to key diseases, Durga ensures a high-quality crop that meets international standards and satisfies global demand.",
      },
      {
        heading: "Gujarat soybean 1 (J-231)",
        description:
          "The Gujarat Soybean 1, also known as J-231, matures in 90-95 days and yields 15-20 q/ha. This variety is well-regarded for its resilience, offering fair tolerance to diseases and pests prevalent in Gujarat. Its adaptability and dependable performance make it a valuable choice for soybean exports. With its robust growth and consistent yield, Gujarat Soybean 1 meets international standards, supporting global demand for high-quality soybeans from India.",
      },
      {
        heading: "Hara Soy (Himso 1563)",
        description:
          "The Hara Soy variety is a standout in soybeans, known for its immunity to bacterial pustule and high resistance to brown spot, bacterial blight, frog eye leaf spot, and pod blight. Recognized as the first-ever culinary purpose variety, Hara Soy offers an average yield of 15-20 q/ha. Its exceptional disease resistance and suitability for culinary uses make it a prized choice for exporters. With its reliable performance and quality, Hara Soy meets the growing global demand for premium soybeans.",
      },
      {
        heading: "Indira Soy 9",
        description:
          "Indira Soy 9 stands out with its resistance to rust and moderate resistance to stem tunneling, girdle beetle, and leaf folder. This variety thrives under low to moderate plant densities, showcasing its adaptability and robust performance. With its disease resistance and reliability in various conditions, Indira Soy 9 offers a solid yield and quality, making it a preferred choice for soybean exporters aiming to meet global demands efficiently.",
      },
      {
        heading: "JS 2",
        description:
          "JS 2 is a high-performing soybean variety, maturing in about 90-95 days and yielding approximately 18-20q/ha. This variety is renowned for its resistance to bacterial pustule and tolerance to Macrophomina. Its robust characteristics make it an excellent choice for exporters looking to provide a reliable, high-quality product. With its balanced performance and resilience, JS 2 meets the growing demands of the global soybean market, ensuring a consistent and valuable supply.",
      },
    ],
    varietyHeading: "Soybean Varieties We Export",
    verietyDesc:
      "Soybean import from India features a diverse range of varieties, including Ahilya-1, Alankar, Ahilya-3, among others. Indian soybean exporters supply these premium-quality varieties to various countries worldwide. The demand for Indian soybeans continues to grow due to their high nutritional value and versatile use in numerous industries. Exporters ensure that these different varieties meet international standards, making Indian soybeans a popular choice globally. The export of soybean from India includes these and other excellent varieties.",
  },
  {
    heading: "Indian Pigeon Pea Exporters",
    picture: turBanner,
    link: "Pigeon pea",
    firstLayout: false,
    description:
      "Pigeon pea, a fast-growing, drought-resistant legume, thrives in tropical and subtropical regions. Renowned for its versatility, pigeon peas are a staple in global cuisines, particularly in India and Indonesia. The growing demand has led to a significant increase in pigeon pea exports from India, making it a lucrative venture for Indian exporters. Used extensively in cooking and as animal feed, this legume is a vital export commodity. With various varieties available, India's pigeon pea exports continue to rise, reflecting its importance in the agricultural sector and contributing to the success of exporters.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 343 calories",
          "Dietary Fiber - 15 g",
          "Fat - 1.5g",
          "Carbohydrate 63 g",
        ],
      },
      {
        title: "Protein",
        value: ["22g"],
      },
      {
        title: "Minerals",
        value: [
          "Thiamin",
          "Magnesium",
          "Phosphorus",
          "Potassium",
          "Copper",
          "Manganese",
        ],
      },
    ],
    benifits: [],
    outro:
      "Looking for a reliable pigeon pea importer from India? JNTB EXPORT IMPORT is your ideal choice! With vast experience in the food export industry and strong global connections, JNTB EXPORT IMPORT excels in exporting high-quality spices, pulses, grains, fruits, vegetables, and more to countries worldwide. Our deep industry knowledge and understanding of international markets ensure that only the freshest products reach our customers promptly. Whether you're seeking premium pigeon peas or a diverse range of food products, JNTB EXPORT IMPORT is your trusted partner for seamless, efficient exports. Choose JNTB EXPORT IMPORT for quality and reliability in every shipment!",
    varieties: [
      {
        heading: "UPAS-120",
        description:
          "UPAS-120, a pigeon pea variety developed at Pantnagar, is celebrated for its extra-early maturity. The plants are medium tall, with an open, semi-spreading growth habit. This variety stands out with its yellow pods adorned with red streaks, adding to its unique appearance. The seeds of UPAS-120 are small and light brown, making them easily distinguishable. Renowned for its quick growth and reliable yield, UPAS-120 is a favored choice for farmers seeking early harvests.",
      },
      {
        heading: "Pant A3",
        description:
          "Pant A3 is a pigeon pea variety that matures within 120-130 days after transplanting. These semi-erect, determinate plants are compact and dwarf in size, making them ideal for efficient farming. Each pod typically contains 3-4 seeds, offering a reliable yield. Pant A3’s manageable size and steady growth make it a popular choice among farmers seeking a resilient and productive pigeon pea variety.",
      },
      {
        heading: "ICPL 87 (Pragati)",
        description:
          "ICPL 87, also known as the Pragati variety, is a high-yielding, wilt-tolerant pigeon pea developed through pedigree selection from the cross ICPX 73052 (T 21 x JA 277). Released in 1986 for India's peninsular zone, this short-duration variety is well-suited for both single and multiple harvest systems. ICPL 87's resilience and adaptability make it a favored choice among farmers, offering reliable yields and resistance to wilt, ensuring successful cultivation across diverse farming conditions.",
      },
      {
        heading: "Pusa Ageti",
        description:
          "Pusa Ageti is a robust pigeon pea variety known for its dwarf, compact, and determinate growth habit. This bold-seeded variety features pods with a potential of 4-5 seeds each. With a maturity period of 150-160 days, Pusa Ageti delivers an impressive average yield of around 2.5 tons per hectare. Its compact size and high yield potential make it an excellent choice for farmers looking for a productive and reliable pigeon pea variety.",
      },
      {
        heading: "Pusa 84",
        description:
          "Pusa 84 is a pigeon pea variety known for its medium tall, semi-spreading plants. Released in 1980, this variety matures in approximately 140-150 days, making it a reliable choice for farmers. The seeds of Pusa 84 are brown, adding to its distinct characteristics. Its balanced growth habit and timely maturity make Pusa 84 a popular option for those seeking a dependable and well-established pigeon pea variety for their agricultural needs.",
      },
      {
        heading: "HY 3A",
        description:
          "HY 3A is a pigeon pea variety characterized by its tall, erect plants with green stems. The seeds are bold and white, contributing to its distinctive appearance. With a maturity period of 160-170 days, HY 3A offers an average yield of 1.6-2.0 tons per hectare. This variety is favored for its robust growth and reliable production, making it an excellent choice for farmers aiming for a high-yield pigeon pea crop.",
      },
      {
        heading: "Gwalior 3",
        description:
          "Gwalior 3 is a late-maturing pigeon pea variety known for its tall, bushy plants. The stems are typically greenish-red, giving the plants a distinctive look. The grains produced by Gwalior 3 are medium-sized and light brown, making them easily recognizable. This variety’s robust growth and late maturity make it a reliable choice for farmers seeking a strong, bushy pigeon pea plant that delivers consistent yields.",
      },
      {
        heading: "Laxmi",
        description:
          "Laxmi is a standout pigeon pea variety exported from India, known for its perennial growth and semi-spreading nature. This variety is particularly valued for its wilt tolerance, making it a resilient choice for farmers. With a potential yield of approximately 2 tons per hectare, Laxmi offers both reliability and productivity. Its robust growth and disease resistance make it an excellent option for those seeking a high-performing pigeon pea variety for diverse agricultural conditions.",
      },
      {
        heading: "BDN 1",
        description:
          "BDN 1 is a resilient pigeon pea variety, known for its moderate resistance to wilt and strong resistance to sterility mosaic. Released specifically for Western Maharashtra and Marathwada regions, it thrives in low rainfall areas and light to medium soils. With a maturity period of around 150-155 days, BDN 1 offers a reliable yield while adapting well to challenging conditions. Its robust resistance and adaptability make it a top choice for farmers in these regions.",
      },
    ],
    varietyHeading: "Pigeon Pea Varieties We Export",
    verietyDesc:
      "India's pigeon pea export includes top varieties like UPAS-120, IPCL 87, and Pusa Ageti, making India a leading exporter of diverse arhar types.",
  },
  {
    heading: "Indian Cowpea Exporter",
    picture: blackgramBanner,
    link: "Black-eyed beans",
    firstLayout: true,
    description:
      "Cowpea, an annual herbaceous legume, is renowned for its self-pollinating nature and versatile uses. India’s thriving cowpea export industry sends tonnes of this nutritious legume abroad, as global awareness of its benefits grows. Known for its rich protein content and adaptability in various cuisines, cowpea, including popular black-eyed beans, has seen a significant rise in export demand over recent years. For Indian exporters, this surge represents a lucrative business opportunity. The export market features a range of cowpea varieties, catering to diverse global needs and preferences. Embracing this trend, Indian cowpea exporters are well-positioned to capitalize on the increasing international interest in this valuable legume.",
    marketDemand:
      "Global demand for cowpea is steadily rising, driving Indian black-eyed bean exporters to boost exports annually. Known for its high nutritional value and versatility, cowpea is a staple grain legume in South Africa and parts of Sub-Saharan Africa. Indian cowpea exports cater to international markets by providing premium-quality beans, meeting the growing global appetite. As more countries recognize the value of this essential legume, Indian exporters are expanding their reach, ensuring a steady supply of top-grade cowpea worldwide. This trend highlights the increasing importance of cowpea in global agriculture and trade, making it a key player in the international legume market.",
    specifications: [
      {
        title: "Product Name",
        value: "Cowpea",
      },
      {
        title: "Origin",
        value: "West Africa",
      },
      {
        title: "Family",
        value: "Vigna Unguiculata",
      },
      {
        title: "Binomial name",
        value: "Fabaceae",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: ["Calories 116", "Total Carbohydrate 21 g", "Dietary fiber 7 g"],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Iron", "Magnesium"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin C", "Vitamin D", "Vitamin A", "Vitamin B6"],
      },
      {
        title: "Protein",
        value: ["8 g"],
      },
      {
        title: "Fats",
        value: ["0.5g"],
      },
    ],
    benifits: [
      "Cowpea leaves, often enjoyed as a vegetable dish, can also be dried and used as a nutritious meat substitute. This versatile plant offers both fresh and preserved options, adding value to various culinary creations.",
      "Cowpea seeds are occasionally roasted as a coffee substitute. Ground dried seeds, mixed with onions and spices, can be fried in oil, offering a flavorful and unique addition to various dishes.",
      "Cowpeas are ideal for hay production, with the harvested hay providing a profitable selling opportunity for farmers.",
      "Cowpea seeds are highly nutritious, making them a vital part of our diet. They also serve as excellent livestock feed, offering a healthy and protein-rich option for animals while enhancing overall agricultural sustainability.",
    ],
    outro:
      "For seamless cowpea export, JNTB EXPORT IMPORT is your top choice! We understand the complexities involved in exporting cowpea from India and are here to simplify the process for you. Our experienced team guides you through every step, ensuring smooth handling from start to finish. As a leading cowpea importer from India, JNTB EXPORT IMPORT prioritizes adherence to all health and safety regulations, guaranteeing that your products meet the highest standards. Trust us to manage your cowpea export needs with expertise and reliability. Ready to take the next step? Contact JNTB EXPORT IMPORT today for hassle-free and efficient cowpea export solutions!",
    varieties: [],
  },
  {
    heading: "Indian Horse Gram Exporter",
    picture: horsegramBanner,
    link: "Horse gram",
    firstLayout: true,
    description:
      "Horse gram, a low-growing, sub-erect herb, thrives in tropical and subtropical climates. Renowned as a miraculous superfood, horse gram is increasingly in demand worldwide. Each year, Indian exporters ship tonnes of this nutritious legume to various countries, reflecting its growing global popularity. The surge in horse gram export from India presents a prime opportunity for exporters to expand their reach and tap into international markets. With numerous varieties available, the export market is diverse and promising. Indian horse gram exporters are well-positioned to leverage this trend, offering high-quality products that meet the rising global appetite for this health-boosting superfood.",
    marketDemand:
      "Global demand for horse gram is soaring, driven by its numerous health benefits as a superfood. As awareness of its nutritional value spreads, the horse gram exporter in India is responding by increasing exports to various parts of the world. This rising international interest has also led to a significant uptick in horse gram imports from India. The expanding market presents a valuable opportunity for Indian exporters to capitalize on the growing global appetite for this versatile legume. With its remarkable health benefits and versatility, horse gram is becoming a sought-after commodity, making it an exciting time for exporters to expand their global reach.",
    specifications: [
      {
        title: "Product Name",
        value: "Horse Gram",
      },
      {
        title: "Origin",
        value: "Peninsular India",
      },
      {
        title: "Family",
        value: "Macrotyloma Uniflorum",
      },
      {
        title: "Binomial name",
        value: "Fabaceae",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: ["Calories 321 kcal", "Carbs - 57g", "Dietary Fiber - 5.0g"],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Iron", "Potassium"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin D"],
      },
      {
        title: "Protein",
        value: ["22 g"],
      },
      {
        title: "Fats",
        value: ["0g"],
      },
    ],
    benifits: [
      "Horse gram aids digestion and helps prevent diarrhea, thanks to its high fiber content. The fiber absorbs excess fluids in the intestines and stomach, reducing occurrences of diarrhea and loose motions. This makes horse gram a valuable addition to a diet for improved digestive health.",
      "write in 60 words and it should be plagiarism free and catchy",
      "Experts suggest that horse gram helps lower LDL (bad) cholesterol levels. Its lipid content aids in reducing harmful cholesterol in the veins, promoting heart health. Regular consumption of horse gram can be an effective, natural way to manage cholesterol levels and support cardiovascular well-being.",
      "Horse gram is beneficial for the skin, thanks to its antimicrobial and antibacterial properties. Used as a topical face pack, it may help treat rashes, boils, and skin disorders. Rich in antioxidants and essential minerals, horse gram nourishes the skin, promoting a healthier and more radiant complexion.",
    ],
    outro:
      "JNTB EXPORT IMPORT stands out as a top horse gram importer from India, dedicated to facilitating global trade for a variety of food products, including fruits, vegetables, grains, pulses, and oilseeds. Our expertise in the export process provides local farmers and vendors with an excellent opportunity to enter the international market and boost their revenues. Horse gram, with its rising global demand, presents a lucrative avenue for expansion. At JNTB EXPORT IMPORT, we streamline the export process, ensuring high-quality products reach international buyers efficiently. If you're seeking reliable and profitable horse gram export solutions from India, get in touch with JNTB EXPORT IMPORT today for exceptional service and support!",
    varieties: [],
  },
  {
    heading: "Indian Puffed Rice Exporter",
    picture: puffedriceBanner,
    link: "Puffed rice",
    firstLayout: true,
    description:
      "Puffed rice, created by air-puffing rice grains to make them larger, lighter, and crispier, is a popular export product from India. The Indian puffed rice exporter ships tonnes of this versatile snack abroad, with export volumes steadily increasing over the years. This rise can be attributed to the numerous benefits of puffed rice, including its crisp texture and nutritional value. As global demand grows, Indian exporters are enjoying significant revenue from this thriving market. The export of puffed rice from India encompasses a range of varieties, catering to diverse international preferences. For high-quality puffed rice and reliable export solutions, Indian exporters are leading the way in meeting global demand and boosting their business.",
    marketDemand:
      "The global demand for puffed rice has surged in recent years, prompting Indian exporters to increase their shipments to numerous countries worldwide. Major import hubs like New York, Hong Kong, and Singapore are among the top ports receiving puffed rice from India. This rising popularity is driven by the numerous nutritional benefits of puffed rice, which makes it a sought-after snack and ingredient. As the international market continues to expand, Indian exporters are capitalizing on this trend, providing high-quality puffed rice and a range of related products to meet growing global demands. For a reliable source of nutritious and popular puffed rice, look no further than India’s leading exporters.",
    specifications: [
      {
        title: "Product Name",
        value: "Puffed Rice",
      },
      {
        title: "Origin",
        value: "India",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: ["Calories 33 kcal", "Carbohydrates - 10.3g", "Fiber - 0.04g"],
      },
      {
        title: "Minerals",
        value: ["Thiamine", "Riboflavin", "Niacin", "Folate"],
      },
      {
        title: "Protein",
        value: ["1 g"],
      },
      {
        title: "Fats",
        value: ["0.01g"],
      },
    ],
    benifits: [
      "Puffed rice is a key ingredient in the iconic Indian snack, ‘Bhelpuri.’ Its light, crispy texture adds a delightful crunch to this popular street food, making it a favorite in Indian cuisine and beyond.",
      "Puffed rice aids in preventing constipation thanks to its fiber content and beneficial bacteria. Its light, easily digestible consistency supports healthy digestion, helping to alleviate constipation issues effectively.",
      "Puffed rice can aid in maintaining healthy blood pressure levels, thanks to its light, low-sodium, and nutritious profile.",
      "Puffed rice is an excellent choice for weight loss, as it's light and low in calories. Its airy texture helps reduce calorie intake while aiding in the breakdown of stored fats, making it a great addition to a weight management plan.",
    ],
    outro:
      "If you’re seeking a reliable puffed rice importer from India, JNTB EXPORT IMPORT is your top choice! With our extensive experience and expertise in the food export industry, we ensure a seamless process from start to finish. Our dedicated team will guide you through every step, from meticulous documentation to the final delivery of your puffed rice products. At JNTB EXPORT IMPORT, we handle all aspects of the export process, ensuring timely and efficient service. Whether you're interested in importing high-quality puffed rice or need a trusted partner for your export needs, JNTB EXPORT IMPORT is here to deliver excellence. Reach out to us today to get started!",
    varieties: [],
  },
  {
    heading: "Indian Red Lentil Exporter",
    picture: masoordalBanner,
    link: "Masoor Dal",
    firstLayout: true,
    description:
      "Red lentils are celebrated globally for their exceptional nutritional value. Each year, Indian exporters ship tonnes of these versatile lentils to international markets. With their delicious taste and various culinary uses, red lentils have seen a steady increase in export volumes. This growing demand presents a prime opportunity for Indian red lentil exporters to expand their reach and thrive in the global market. The export of red lentils from India includes a range of varieties, catering to diverse international preferences. For those looking to tap into this lucrative market, Indian exporters are well-positioned to deliver high-quality products and meet the rising global appetite for red lentils.",
    marketDemand:
      "Global demand for red lentils has surged, with bids climbing to 22.5 cents per pound at the start of 2020, up from 20 cents just a month prior. This rising interest is driven by the growing recognition of red lentils' health benefits. In response, Indian exporters have been ramping up their red lentil shipments, meeting the increased global appetite for this nutritious legume. The upward trend in red lentil imports from India reflects a broader awareness of the product's health advantages and versatility. As the market continues to expand, Indian exporters are well-positioned to capitalize on this growth, delivering high-quality red lentils to international buyers and strengthening their presence in the global market.",
    specifications: [
      {
        title: "Product Name",
        value: "Red Lentil",
      },
      {
        title: "Origin",
        value: "Western and Central Asia",
      },
      {
        title: "Binomial Name",
        value: "Lens culinaris",
      },
      {
        title: "Family",
        value: "Legumes",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: ["Calories 116", "Total Carbohydrate 20 g", "Dietary fiber 8 g"],
      },
      {
        title: "Minerals",
        value: ["Magnesium", "Cobalamin"],
      },
      {
        title: "Protein",
        value: ["9 g"],
      },
      {
        title: "Fats",
        value: ["0.4g"],
      },
    ],
    benifits: [
      "Red lentils are rich in polyphenols, active compounds that combat harmful agents in the body. These protective substances help guard against ultraviolet rays, radiation, heart disease, and cancer, offering a range of health benefits.",
      "Red lentils are a protein powerhouse, making them an excellent choice for vegans and vegetarians. They help address protein deficiencies and provide essential nutrients for a balanced diet.",
      "Red lentils are rich in iron, essential for transporting oxygen throughout your body, boosting energy levels, and supporting overall health.",
      "Red lentils are packed with fiber, crucial for healthy digestion and effective weight loss. Their high fiber content supports a well-functioning digestive system and aids in slimming.",
    ],
    outro:
      "If you're seeking a reliable red lentil importer from India, JNTB EXPORT IMPORT is your ideal partner! We understand that the red lentil export process can be complex, but we’re here to simplify it for you. At JNTB EXPORT IMPORT, our team is dedicated to guiding you through every step, from initial documentation to the final delivery of your red lentils. With our extensive experience and industry knowledge, we ensure a seamless export experience, meeting all your needs efficiently. Our commitment to quality and customer satisfaction makes us the best choice for exporting red lentils from India. Ready to take your red lentil business to the next level? Contact JNTB EXPORT IMPORT today and let us handle the details for you!",
    varieties: [],
  },
  {
    heading: "Indian Green Gram Exporters",
    picture: greengramBanner,
    link: "Green gram",
    firstLayout: false,
    description:
      "Green gram, or mung bean, is a valuable legume known for its numerous health benefits. Each year, Indian exporters ship tonnes of green gram to international markets, reflecting its growing global popularity. This rising demand is driving a rapid increase in green gram exports from India. With its versatile uses and nutritional advantages, green gram has become a sought-after commodity worldwide. This is an opportune moment for Indian green gram exporters to expand into the international marketplace and tap into the burgeoning global demand. The export of green gram from India includes a variety of types, catering to diverse international preferences. For a thriving business opportunity in the global market, Indian green gram exporters are well-positioned to deliver excellence.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 347 calories",
          "Total Carbohydrate 63 g",
          "Dietary fiber 16 g",
        ],
      },
      {
        title: "Protein",
        value: ["24g"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin A", "Vitamin C", "Vitamin D", "Vitamin B6"],
      },
      {
        title: "Minerals",
        value: ["Cobalamin", "Magnesium"],
      },
      {
        title: "Fat",
        value: [],
      },
    ],
    benifits: [],
    outro:
      "JNTB EXPORT IMPORT is your premier choice for green gram import from India. We recognize that exporting green gram can be complex, but we’re here to simplify the process for you. Our skilled team will assist you through every stage of the export process, ensuring your green gram products are efficiently handled from start to finish. With extensive experience in the food export industry, JNTB EXPORT IMPORT is committed to providing exceptional support and resolving any issues that may arise. If you're ready to navigate the world of food export with confidence, contact JNTB EXPORT IMPORT today for expert guidance and reliable service!",
    varieties: [
      {
        heading: "CO 1",
        description:
          "Released in 1952, CO 1 is a prominent green gram variety exported from India. Developed as a pure-line selection from the Coimbatore local, it matures in approximately 135 days with a grain yield of around 750 kg/ha. Ideal for rainfed conditions, CO 1 offers reliable performance and quality. Its resilience and productivity make it a favored choice for both local and international markets.",
      },
      {
        heading: "ADT 1",
        description:
          "Released in 1966, the ADT variety of green gram is a pure-line selection from Aduthurai local. It matures in approximately 80 days, with a rainfed grain yield of around 500 kg/ha. Ideal for rice fallow conditions, ADT offers a quick turnaround and dependable performance. Its efficiency and adaptability make it a preferred choice for both local cultivation and export markets, meeting the needs of various agricultural settings.",
      },
      {
        heading: "JGG 1 (Rajendran)",
        description:
          "Released in 1972, the JGG 1 variety of green gram is a select cultivar from Jayankondam local. It matures in about 65 days, offering a grain yield of approximately 625 kg/ha. Known for its drought tolerance, JGG 1 is ideal for challenging growing conditions. Its resilience and efficient production make it a valuable choice for both domestic and international markets, catering to diverse agricultural needs and ensuring a reliable harvest.",
      },
      {
        heading: "KM 1",
        description:
          "The KM 1 variety of green gram, introduced in 1978, is a notable export from India. Developed from the cross of S.8 and PS 16, it matures in about 65-70 days. With a grain yield of approximately 708 kg/ha, KM 1 stands out for its productivity and efficiency. Its consistent performance and high yield make it a popular choice for international markets, ensuring a reliable supply of quality green gram for global buyers.",
      },
      {
        heading: "KM 2",
        description:
          "Released in 1978, the KM 2 variety of green gram is renowned for its resilience and high yield. With a rainfed grain yield of about 767 kg/ha, KM 2 excels in productivity. This variety is particularly noted for its tolerance to Yellow Mosaic Virus (YMV) and pod borers, making it a reliable choice for diverse growing conditions. Its robust performance and pest resistance make KM 2 a favored option for both local and international markets.",
      },
      {
        heading: "Paiyur1",
        description:
          "Released in 1988, the Paiyur 1 variety of green gram is a pure line selection from DPT 703. It matures in about 85-90 days and yields approximately 700 kg/ha. Known for its low incidence of Yellow Mosaic Virus (YMV), Paiyur 1 is ideal for rainfed areas in Dharmapuri, Madurai, Ramnad, Tirunelveli, and Periyar Districts. Its resilience and suitability for local conditions make it a popular choice for farmers in these regions.",
      },
      {
        heading: "Vamban 1",
        description:
          "Released in 1989, the Vamban 1 variety of green gram is a hybrid derived from (S.8 x PIMS 3). It matures in approximately 65-70 days and offers a high grain yield of around 800 kg/ha. Vamban 1 is known for its tolerance to Yellow Mosaic Virus (YMV), making it a robust choice for various growing conditions. Its impressive yield and disease resistance make it a preferred option for farmers seeking reliable and productive green gram varieties.",
      },
      {
        heading: "VBN (Gg) 2",
        description:
          "Released in 2001, this green gram variety is a cross between VGG 4 and MH 309. It matures in approximately 65-70 days and achieves a rainfed grain yield of 750 kg/ha. Known for its shiny grains and lobed leaves, this variety also boasts resistance to Yellow Mosaic Virus (YMV). Its high yield and disease resistance make it a standout choice for farmers seeking a robust and productive green gram option.",
      },
    ],
    varietyHeading: "Green Gram Varieties We Export",
    verietyDesc:
      "Green gram imports from India feature a range of premium varieties, including CO 1, ADT 1, and JGG 1. Indian mung bean exporters prioritize quality, adhering to strict health and safety regulations to ensure top-notch products. These major green gram varieties cater to diverse international needs, reflecting the commitment to delivering excellence and meeting global standards. For top-quality green gram, Indian exporters offer a variety of options to satisfy international demand.",
  },
  {
    heading: "Indian Black Gram Exporters",
    picture: blackgramBanner,
    link: "Black gram",
    firstLayout: false,
    description:
      "Black Gram, also known as Black Lentil, is a staple in India with its round, black, and slightly elongated beans. Known for their sticky texture and mild flavor, Black Gram is increasingly in demand worldwide. Indian exporters are shipping tonnes of this versatile lentil to global markets. As international interest grows, Black Gram exports from India have surged, offering a prime opportunity for Indian exporters to expand their reach and thrive in the global marketplace. The export includes various Black Gram varieties, catering to diverse needs.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 341 kcal",
          "Dietary Fiber - 18.3 g",
          "Total Carbohydrate - 58.99 g",
        ],
      },
      {
        title: "Protein",
        value: ["25.21g"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin A", "Vitamin C", "Vitamin B-6"],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Iron", "Magnesium"],
      },
      {
        title: "Fat",
        value: [],
      },
    ],
    benifits: [],
    outro:
      "JNTB EXPORT IMPORT is your top choice for importing Black Gram from India. We understand that navigating the export process can be complex, but our team is here to simplify it for you. With our extensive expertise in the food export industry, we ensure that every step of the Black Gram export—from documentation to delivery—is handled smoothly and efficiently. Our dedicated executives are committed to resolving any issues and providing seamless support throughout the entire process. Ready to explore food export from India? Contact JNTB EXPORT IMPORT today for expert assistance and reliable service!",
    varieties: [
      {
        heading: "ADT 1",
        description:
          "The ADT 1 variety of Black Gram, released in 1965, is a refined selection from Aduthurai local. Known for its efficiency, this variety matures in approximately 80 days from transplantation. It boasts a rainfed grain yield of around 450 kg/ha and is notable for its high protein content of 19.9%. This makes ADT 1 an excellent choice for those seeking a nutrient-rich legume with reliable productivity. Its robust performance and nutritional benefits contribute to its growing popularity in both local and international markets. Ideal for diverse agricultural settings, ADT 1 stands out as a valuable variety in the world of Black Gram.",
      },
      {
        heading: "CO 1",
        description:
          "The CO 1 variety of Black Gram, introduced in 1968, is a pure line selection from Srivaikundam local. Renowned for its reliability, CO 1 matures in approximately 110 days. This versatile variety delivers a grain yield of about 600 kg/ha under rainfed conditions and up to 750 kg/ha when irrigated. Developed specifically for rainfed environments, CO 1 offers consistent performance and high productivity, making it a preferred choice for diverse agricultural settings. Its robust yield potential and adaptability contribute to its strong presence in both local and international markets. Ideal for farmers seeking a dependable and high-yielding Black Gram variety, CO 1 continues to make an impact in the world of legumes.",
      },
      {
        heading: "KM 1",
        description:
          "Released in 1977, the KM 1 variety of Black Gram is a standout choice for its resilience and efficiency. This dwarf-type variety matures in just 65-70 days from transplantation. Despite its compact size, KM 1 delivers a solid grain yield of approximately 25 kg/ha under rainfed conditions. Its drought tolerance makes it particularly valuable in challenging climates, ensuring consistent performance even in less favorable weather. KM 1’s quick maturation and adaptability contribute to its growing popularity among farmers seeking reliable and productive Black Gram options. With its impressive features, KM 1 continues to be a top choice in the global Black Gram market, combining resilience with high-yield potential.",
      },
      {
        heading: "TMV 1",
        description:
          "Developed from the cross between Midhi Ulundu and KM 1, the TMV 1 variety of Black Gram was released in 1979. Known for its impressive productivity, TMV 1 matures in just 65-70 days. This variety achieves a remarkable grain yield of about 800 kg/ha when irrigated, making it a top performer in optimal conditions. TMV 1 is celebrated for its resilience, being resistant to Yellow Mosaic Virus (YMV) and tolerant to root rot. These attributes enhance its reliability and performance, making it a favored choice among farmers seeking a robust and high-yielding Black Gram variety. TMV 1’s combination of quick maturation and disease resistance continues to drive its popularity in both local and international markets.",
      },
      {
        heading: "Vamban 1",
        description:
          "Released in 1987, the Vamban 1 variety of Black Gram is celebrated for its high yield and resilience. Maturing in just 60-65 days from transplantation, Vamban 1 is a quick-turnaround choice for farmers. It delivers an impressive grain yield of around 780 kg/ha under rainfed conditions and up to 900 kg/ha when irrigated, making it highly productive across various farming practices. Additionally, Vamban 1 stands out for its tolerance to Yellow Mosaic Virus (YMV), ensuring robustness in challenging conditions. Its combination of rapid growth, high yield, and disease resistance makes Vamban 1 a top pick for both local and international markets, offering exceptional value and reliability for Black Gram cultivation.",
      },
      {
        heading: "APK 1",
        description:
          "Released in 1993, the APK 1 variety of Black Gram matures in approximately 75 days from transplantation. It boasts a remarkable grain yield of around 940 kg/ha under irrigated conditions. APK 1 is particularly well-suited for rainfed environments and excels in intercropping with cotton, offering versatility and high productivity. Its efficient growth and adaptability make APK 1 a valuable choice for farmers looking to optimize their crop yields and integrate Black Gram effectively into diverse agricultural systems.",
      },
      {
        heading: "VBN (Bg) 4",
        description:
          "Released in 2003, the VBN (Bg) 4 variety of Black Gram matures in 75-80 days post-transplanting. It delivers a solid grain yield of approximately 780 kg/ha under rainfed conditions and up to 900 kg/ha when irrigated. Notably, VBN (Bg) 4 is resistant to Yellow Mosaic Virus, ensuring robust performance and reliable productivity. Its resilience and high yield make it an excellent choice for farmers seeking a dependable Black Gram variety that excels in various growing conditions.",
      },
      {
        heading: "MDU 1",
        description:
          "Released in 2014, the MDU 1 variety of Black Gram matures in 70-75 days after transplanting. It offers an impressive grain yield of about 790 kg/ha under irrigated conditions. Known for its high productivity, MDU 1 also stands out for its resistance to Leaf Crinkle Virus, making it a reliable choice for farmers. Its robust yield and disease resistance ensure that MDU 1 delivers both exceptional performance and resilience in diverse growing environments.",
      },
      {
        heading: "KKM 1",
        description:
          "Released in 2017, the KKM 1 variety of Black Gram matures in 65-70 days post-transplanting. It yields approximately 607 kg/ha in rice fallow conditions. Renowned for its high productivity, KKM 1 is moderately resistant to Yellow Mosaic Virus (YMV) and Powdery Mildew. Its suitability for rice fallow makes it an excellent choice for diverse agricultural systems, offering both resilience and robust performance for farmers seeking reliable and high-yielding Black Gram varieties.",
      },
    ],
    varietyHeading: "Black Gram Varieties We Export",
    verietyDesc:
      "Black Gram imports from India feature a range of premium varieties, including ADT 1, CO 1, KM 1, and TMV 1. Indian exporters ensure that these products meet top-quality standards and adhere to strict health and safety regulations. By focusing on quality and compliance, they deliver reliable and sought-after Black Gram varieties to global markets. Discover the major Black Gram varieties driving India’s export success and experience excellence in every shipment.",
  },
  {
    heading: "Indian Chana Dal Exporter",
    picture: chanadalBanner,
    link: "Chana Dal",
    firstLayout: true,
    description:
      "Chana Dal, a split lentil made from polished baby chickpeas, is a staple in many global cuisines. The Chana Dal exporter in India ships tons of this nutritious and delicious legume to various countries each year. Its rich taste and health benefits have led to a significant rise in Chana Dal exports from India. This growing demand presents a prime opportunity for Indian Chana Dal exporters, farmers, and local vendors to expand their reach and tap into international markets. With its increasing global popularity, Chana Dal export offers a valuable chance to boost business and connect with customers worldwide.",
    marketDemand:
      "The global demand for Chana Dal has been surging, driven by its numerous benefits and versatile uses. As a top source of protein, especially for vegetarians, Chana Dal supports heart health and is diabetic-friendly. This has boosted the Chana Dal export from India, where exporters ensure the highest quality standards. Each year, premium Chana Dal is shipped worldwide, meeting stringent health and safety regulations. For smooth and reliable Chana Dal import from India, adherence to these measures is essential. With its growing popularity, Chana Dal offers a valuable opportunity for international markets to enjoy this nutritious and beneficial legume.",
    specifications: [
      {
        title: "Product Name",
        value: "Chana Dal",
      },
      {
        title: "Origin",
        value: "Indian Subcontinent",
      },
      {
        title: "Family",
        value: "Fabaceae",
      },
      {
        title: "Binomial name",
        value: "Cicer arietinum",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 364 kcal",
          "Total Carbohydrate 61 g",
          "Dietary Fiber 17 g",
        ],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Iron", "Magnesium"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin A", "Vitamin C", "Vitamin B6"],
      },
      {
        title: "Protein",
        value: ["19 g"],
      },
      {
        title: "Fats",
        value: ["6g"],
      },
    ],
    benifits: [
      "Chana Dal is an excellent energy booster, packed with around 100 calories and rich in essential vitamins and minerals.",
      "Chana Dal boosts insulin response due to its high magnesium content. It helps lower insulin resistance, making it a valuable addition for better blood sugar management.",
      "Chana Dal helps regulate blood pressure thanks to its high potassium and low sodium content. This combination makes it an excellent choice for maintaining healthy blood pressure levels.",
      "Chana Dal is rich in fiber, which helps slow digestion and keeps you feeling full for longer. This makes it a great choice for maintaining a healthy diet.",
    ],
    outro:
      "JNTB EXPORT IMPORT is a premier Chana Dal importer from India, dedicated to assisting clients throughout the entire export process—from documentation to the final delivery of products. With a global presence and extensive experience in Chana Dal exports, JNTB EXPORT IMPORT has consistently delivered exceptional service, ensuring client satisfaction. Our knowledgeable executives are always on hand to address any issues, doubts, or queries, making the export process seamless and stress-free. Looking to export Chana Dal from India? Contact JNTB EXPORT IMPORT today and experience hassle-free service!",
    varieties: [],
  },
  {
    heading: "Indian Beaten Rice Exporter",
    picture: beatenriceBanner,
    link: "Beaten rice",
    firstLayout: true,
    description:
      "Beaten rice, also known as flattened rice, is a versatile and nutritious food product made from rice that is flattened into light, dry flakes. It is a cost-effective, wholesome food with high nutritional value, making it a popular choice globally. The Beaten Rice exporter in India plays a significant role in meeting the growing international demand, exporting large quantities of premium quality beaten rice each year. With its increasing popularity worldwide, the export of beaten rice from India has seen a steady rise. Indian exporters ensure that only the best varieties of beaten rice reach global markets, contributing to the expanding footprint of this traditional food staple.",
    marketDemand:
      "Beaten rice, also known as Chira, is a popular food made from paddy and enjoyed across various parts of India. Its versatility and appeal to people of all ages make it a staple in many households. Given its mass consumption, the export of beaten rice offers a significant business opportunity for Indian exporters. The high quality of Indian Beaten Rice has led to a steady increase in its international demand, making it an ideal time for exporters to expand their reach and tap into global markets.",
    specifications: [
      {
        title: "Product Name",
        value: "Beaten Rice",
      },
      {
        title: "Origin",
        value: "India",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 100 kcal",
          "Total Carbohydrate 20 g",
          "Dietary Fiber 2 g",
        ],
      },
      {
        title: "Vitamins",
        value: ["Vitamin C", "Vitamin B1"],
      },
      {
        title: "Protein",
        value: ["3 g"],
      },
      {
        title: "Fats",
        value: ["1g"],
      },
    ],
    benifits: [
      "Beaten Rice is a versatile and convenient food that can be enjoyed in various ways. It can be soaked in water, fried, mixed with curd or milk, or cooked after soaking. Its quick preparation makes it an ideal choice for those needing a fast, wholesome meal. Perfect for any time, Beaten Rice is a delicious and easy-to-make option.",
      "Beaten Rice is a versatile ingredient commonly used in snacks and mixtures. It's especially popular in roadside eateries, dhabas, hostels, and similar places, making it a staple in various regions.",
      "Beaten Rice is beneficial for diabetics due to its high fiber content, which helps regulate blood sugar levels by promoting a slow and steady release of sugar into the bloodstream.",
      "Beaten Rice contains 76.9% carbohydrates and about 23% fats. The healthy carbs in Beaten Rice provide essential energy, helping the body carry out its daily functions efficiently.",
    ],
    outro:
      "JNTB EXPORT IMPORT is a leading importer of Beaten Rice from India, offering seamless export services to clients worldwide. We understand that the process of exporting Beaten Rice can be complex, but you can leave the hassle to us. At JNTB EXPORT IMPORT, our experienced executives guide you through every step, ensuring your products reach their destination safely and in top condition. With our expertise in the food export industry, we make sure your experience is smooth and stress-free. Looking to export Beaten Rice from India? Contact JNTB EXPORT IMPORT today!",
    varieties: [],
  },
  {
    heading: "Indian Field Beans Exporters",
    picture: fieldbeansBanner,
    link: "Field beans",
    firstLayout: false,
    description:
      "Field beans, primarily grown for their ripe edible seeds, are becoming increasingly popular worldwide. The demand for this nutritious legume has led to a significant rise in field bean exports from India. Indian exporters have a prime opportunity to capitalize on this growing market, exporting premium-quality field beans to various countries and boosting their revenues. The global appetite for field beans continues to expand, making it a promising and profitable venture for Indian exporters to explore and grow their businesses internationally.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 88 kcal",
          "Dietary Fiber - 8 g",
          "Total Carbohydrate - 18 g",
        ],
      },
      {
        title: "Protein",
        value: ["25.21g"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin A", "Vitamin C", "Vitamin B-6"],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Iron", "Magnesium"],
      },
      {
        title: "Fat",
        value: [],
      },
    ],
    benifits: [],
    outro:
      "JNTB EXPORT IMPORT stands out as the premier Field Beans importer from India. We specialize in guiding our clients through every step of the process, from meticulous documentation to ensuring the final delivery of products. With our extensive global reach and deep industry experience, we have consistently delivered exceptional service to our customers. Our dedicated executives are ready to assist with any questions, concerns, or issues related to the export of Field Beans. Trust JNTB EXPORT IMPORT to make your Field Beans export journey smooth and successful. For reliable and efficient Field Beans export from India, reach out to us today!",
    varieties: [
      {
        heading: "Rongai",
        description:
          "The Rongai variety of field beans is a late-flowering cultivar known for its high dry matter production. It features white flowers and light brown seeds, with a seed weight of approximately 5,000/kg. Popular as a forage cultivar, Rongai thrives in summer and is a vigorous, twining herbaceous annual or short-lived perennial. Its robust growth and productivity make it a top choice for both forage and field bean cultivation.",
      },
      {
        heading: "Koala",
        description:
          "The Koala variety is an early-maturing grain type, known for its impressive yield ranging from 1.1 to 2.8 tons per hectare. This variety is relatively insensitive to day length and typically reaches 50% flowering in about 50–70 days after a December planting in northern New South Wales. Its rapid growth and high yield make Koala a preferred choice for efficient and productive field bean cultivation.",
      },
      {
        heading: "Pusa Early Prolific",
        description:
          "Pusa Early Prolific is a standout Field Bean variety, known for its early-bearing, medium-sized pods that are thin, stringless, and grow in clusters. Ideal for both summer and rainy season sowing, it offers versatility and productivity for farmers seeking reliable yields throughout the year.",
      },
      {
        heading: "Pusa Sem-2",
        description:
          "The Pusa Sem-2 variety of Field Beans features long, dark-green, stringless pods with a semi-round shape. Known for its high yield and resistance to viruses and insect pod borer, it thrives when sown from June to July in North India. The flowers, appearing on separate spikes above the plant canopy, add to its distinctive growth pattern, making it a reliable choice for farmers seeking robust and productive crops.",
      },
      {
        heading: "IGFRI-S-2214-II",
        description:
          "The IGFRI-S-2214-II variety of Field Beans is known for its quick growth and erect stature. With a medium twining habit, it produces broad, flat, green pods that are glabrous and fibrous. Each pod contains 4-6 greenish-brown, round seeds. This variety boasts a leafiness ranging from 40–50%, making it a robust choice for high yield and efficient growth.",
      },
      {
        heading: "IGFRI-S-2218-I",
        description:
          "The IGFRI-S-2218-I variety of Field Beans features a medium growth rate and decumbent habit with excellent twining. Its vines are medium-thick, pigmented, and glabrous. Each pod contains 3–5 seeds. Under dryland conditions, this variety yields 240–260 q/ha of green fodder from a single cut at full bloom, making it an efficient choice for robust production and high-quality feed.",
      },
      {
        heading: "HA-3",
        description:
          "The HA-3 variety is a versatile, photo-insensitive, and determinate type of Field Beans, yielding 12-15 q/ha. With a maturation period of about 4 months, it can be cultivated year-round. This variety is favored for its unique pod fragrance, appealing to consumers. The green pods are used as vegetables, whether tender or with immature seeds, making HA-3 a popular choice for both fresh consumption and culinary use.",
      },
      {
        heading: "CO.1",
        description:
          "The CO.1 variety features short, bushy, and erect plants. Flowering begins 45 days after sowing and lasts 90-100 days, with each inflorescence boasting 55-60 flowers, of which about 50% set pods. The green pods are valued as a vegetable and can be harvested 10-12 days post-flowering, offering a fresh, nutritious addition to meals.",
      },
      {
        heading: "Konkan Bhushan",
        description:
          "This high-yielding genotype boasts impressive pod yields of 8.8–13.6 tonnes per hectare, surpassing the 5.0–9.3 tonnes range of other varieties. Bushy in form, it matures in just 55–60 days after sowing, making it an efficient choice for substantial and rapid harvests.",
      },
    ],
    varietyHeading: "Field Beans Varieties We Export",
    verietyDesc:
      "Field beans imported from India include varieties such as Rongai, Koala, and Pusa Early Prolific, among others. This diverse range offers a fantastic opportunity for Indian field bean exporters to make a significant impact in the global market. By exporting these premium varieties, exporters can tap into international demand and establish a strong presence in the marketplace. The export of field beans from India showcases the country's high-quality produce and opens doors for global business growth.",
  },
  {
    heading: "Indian Urad Dal Split Exporter",
    picture: uraddalBanner,
    link: "Urad Dal Split",
    firstLayout: true,
    description:
      "Urad Dal Split, also known as split black lentils, is prized for its distinctive flavor and versatility. Retaining its skin, this dal can be ground into flour or a paste, enriching a variety of recipes. The Urad Dal Split exporter in India plays a vital role in meeting the growing global demand, with substantial quantities exported each year. Its diverse uses and health benefits have fueled the increase in Urad Dal Split exports from India. This trend presents a promising opportunity for Indian exporters to expand their reach across international markets. The Urad Dal Split export encompasses a range of varieties, ensuring high-quality products for buyers around the world.",
    marketDemand:
      "Urad Dal Split is a key ingredient in many recipes, especially in South Indian cuisine, where it's essential for dishes like dosa and chutney. The Urad Dal Split exporter in India ensures the export of premium-quality dal, adhering to stringent quality and safety guidelines to prevent any complications. This dedication to excellence has enhanced the reputation of Urad Dal Split in the global market. As demand for this versatile ingredient continues to rise, the Urad Dal Split import from India has also been increasing, reflecting the high standards and growing popularity of the product. Indian exporters are well-positioned to meet this demand, providing top-quality dal for diverse culinary applications around the world.",
    specifications: [
      {
        title: "Product Name",
        value: "Urad Dal Split",
      },
      {
        title: "Origin",
        value: "India",
      },
      {
        title: "Family",
        value: "Fabaceae",
      },
      {
        title: "Binomial name",
        value: "Vigna mungo. (L.) Hepper",
      },
    ],
    ingredients: [],
    benifits: [
      "Urad Dal Split is rich in folic acid, essential for key metabolic processes and overall health. It's a great addition to your diet for maintaining optimal bodily functions.",
      "Urad Dal Split boosts blood health with its folic acid, which aids in forming and maintaining new cells, keeping your body’s blood functions in top shape.",
      "Urad Dal Split is packed with calcium and phosphorus, making it excellent for strengthening bones and teeth, and promoting overall skeletal health.",
    ],
    outro:
      "JNTB EXPORT IMPORT is your top choice for importing Urad Dal Split from India. With extensive experience in the food export industry, we ensure a smooth and hassle-free export process for all our clients. From assisting with finding buyers to handling documentation and ensuring timely delivery, our team is dedicated to supporting you every step of the way. Our commitment to quality and customer satisfaction makes us the leading Urad Dal Split importer. Ready to expand your market with premium Urad Dal Split? Contact JNTB EXPORT IMPORT today and let us handle the rest!",
    varieties: [],
  },
  {
    heading: "Indian Moth Bean Exporter",
    picture: turkishgramBanner,
    link: "Turkish gram",
    firstLayout: true,
    description:
      "Moth bean, a herbaceous creeping plant from the Fabaceae family, is known for forming a low-lying soil cover when fully grown. The Moth Beans exporter in India ships large quantities of these beans annually, and the growing global demand has fueled a rise in Moth Beans exports. This presents an excellent opportunity for Indian exporters to broaden their reach and tap into international markets. Moth Beans come in various varieties and qualities, catering to diverse needs. As demand continues to increase, now is the perfect time for exporters to showcase their premium Moth Beans on the global stage. Expand your business with Moth Beans export—seize the opportunity today!",
    marketDemand:
      "The Moth Beans exporter in India is known for delivering high-quality beans to markets worldwide. As awareness of the benefits and versatility of Moth Beans grows, global demand has surged. This increased interest has driven a steady rise in Moth Beans imports from India. The beans are celebrated for their nutritional value and various uses, contributing to their strong reputation. Indian exporters are committed to maintaining top-notch quality, ensuring that each shipment meets rigorous standards. As a result, Moth Beans from India are in high demand, presenting a prime opportunity for exporters to expand their reach and cater to international markets. Explore the potential of Moth Beans export today and capitalize on this growing trend!",
    specifications: [
      {
        title: "Product Name",
        value: "Moth Bean",
      },
      {
        title: "Origin",
        value: "India and Pakistan",
      },
      {
        title: "Family",
        value: "Fabaceae",
      },
      {
        title: "Binomial name",
        value: "Vigna aconitifolia",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories 343 kcal",
          "Total Carbohydrates 62g",
          "Dietary Fiber 2.8g",
        ],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Iron", "Magnesium"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin B-6", "Vitamin C"],
      },
      {
        title: "Protein",
        value: ["23 g"],
      },
      {
        title: "Fats",
        value: ["Fat 1.6g"],
      },
    ],
    benifits: [
      "Moth beans are a great choice for weight loss. They boost muscle mass, which helps burn more calories, making them an excellent addition to any weight management plan.",
      "Moth beans are packed with protein, vital for muscle building and repair. They're an excellent protein source, especially for vegetarians looking to boost their intake with a nutritious, plant-based option.",
      "Regularly eating moth beans helps busy individuals manage stress better. Rich in zinc, these beans help mitigate stress effects, making them a great addition to a hectic lifestyle for maintaining well-being and balance.",
      "Moth beans are a nutrient powerhouse, boosting your immune system and defending your body against bacteria, fungi, and viruses. Regular consumption supports overall health and enhances your body’s natural defenses.",
    ],
    outro:
      "JNTB EXPORT IMPORT stands out as a leading Moth Beans importer from India. Our team ensures a seamless export experience, guiding clients through every step of the process with our extensive industry expertise. We prioritize smooth operations and minimal hassle, adhering strictly to all health and safety regulations. At JNTB EXPORT IMPORT, we are committed to delivering your Moth Beans safely and efficiently. Whether you need assistance with documentation or final delivery, our dedicated team is here to support you. For reliable Moth Beans export from India, reach out to JNTB EXPORT IMPORT today and experience our exceptional service firsthand!",
    varieties: [],
  },
  {
    heading: "Indian Whole Masoor Exporter",
    picture: wholemasoorBanner,
    link: "Whole Masoor",
    firstLayout: true,
    description:
      "Masoor Dal, or red lentils, is renowned for its high nutritional value and health benefits, making it a staple in Indian cuisine. The Whole Masoor exporter in India ships substantial quantities of this nutritious dal to international markets each year. As global demand for Masoor Dal rises, the Whole Masoor export from India has seen significant growth. Indian exporters are committed to providing premium-quality products, meeting global standards and catering to diverse international markets. This trend presents a promising opportunity for the Indian Whole Masoor exporter to expand their reach and capitalize on the growing global appetite for this versatile and healthful pulse.",
    marketDemand:
      "The global popularity of Masoor Dal has led to a surge in exports from India. Known for its exceptional nutritional benefits, Masoor Dal is a powerhouse of nutrients that may aid in combating various health issues. This rising demand has driven the Whole Masoor exporter in India to increase shipments annually. The stellar reputation and superior quality of Indian Masoor Dal contribute to its growing appeal in international markets. As more countries seek out this healthful pulse, the Whole Masoor import from India continues to climb, presenting a prime opportunity for Indian exporters to expand their global footprint and cater to an ever-growing global audience.",
    specifications: [
      {
        title: "Product Name",
        value: "Whole Masoor",
      },
      {
        title: "Origin",
        value: "Mediterranean",
      },
      {
        title: "Family",
        value: "Leguminosae",
      },
      {
        title: "Binomial name",
        value: "Lens culinaris Medikus",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories 127 kcal",
          "Total Carbohydrates 20g",
          "Dietary Fiber 3.3g",
        ],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Iron", "Magnesium"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin A", "Vitamin B1", "Vitamin B2", "Vitamin B3"],
      },
      {
        title: "Protein",
        value: ["7.3 g"],
      },
      {
        title: "Fats",
        value: ["Fat 1.9g"],
      },
    ],
    benifits: [
      "Whole Masoor helps stabilize blood sugar levels with its low glycemic index. It slows the absorption of food in the small intestine, reducing blood sugar spikes and supporting better glucose control.",
      "Masoor Dal's high dietary fiber content helps lower cholesterol levels, promoting heart health. By reducing harmful cholesterol in the body, it supports a healthier cardiovascular system and contributes to overall well-being.",
      "Masoor Dal aids weight loss with its balanced carbohydrate content and low fat. This nutritious pulse keeps you full longer, making it an ideal choice for those looking to manage their weight while enjoying a healthy diet.",
      "Masoor Dal is packed with essential vitamins, calcium, and magnesium, making it a great choice for maintaining strong teeth and bones. Its nutrient-rich profile supports overall bone health and contributes to a balanced diet.",
    ],
    outro:
      "If you're seeking a top-notch Whole Masoor importer from India, look no further than JNTB EXPORT IMPORT! We specialize in the export of Whole Masoor along with a diverse range of vegetables, spices, grains, and pulses. Navigating the food export process can be complex, but with JNTB EXPORT IMPORT, you’re in expert hands. Our dedicated team ensures a smooth and efficient export experience, handling every detail from start to finish. Trust us to manage your Whole Masoor export with precision and care. Reach out to JNTB EXPORT IMPORT today, and let us simplify your export journey!",
    varieties: [],
  },
  {
    heading: "Indian Urad Dal Gota Exporter",
    picture: uraddalgotaBanner,
    link: "Urad Dal Gota",
    firstLayout: true,
    description:
      "Urad Dal, also known as Split Black Gram, is a staple lentil renowned for its versatility and widespread use across Southern Asia. The Urad Dal Gota exporter in India handles substantial volumes of this prized dal each year. With its numerous culinary applications and health benefits, the demand for Urad Dal Gota has surged globally, presenting a prime opportunity for Indian exporters to broaden their reach and tap into international markets. From rich, flavorful dishes to its nutritional perks, Urad Dal Gota continues to gain popularity worldwide. As a key player in the Urad Dal Gota export sector, Indian exporters are well-positioned to deliver high-quality varieties and capitalize on this growing demand.",
    marketDemand:
      "The Urad Dal Gota exporter in India is renowned for delivering top-quality dal to global markets. With its diverse uses and substantial health benefits, Urad Dal has become increasingly popular worldwide. This rising awareness has driven up global demand, leading to a steady increase in Urad Dal Gota imports from India. As consumers and chefs alike appreciate its rich flavor and nutritional value, Indian exporters are meeting the growing international need with premium products. The consistent rise in Urad Dal Gota import reflects its expanding presence in global cuisines and its significant role in the international food market. If you're seeking high-quality Urad Dal Gota, Indian exporters are your trusted source for excellence.",
    specifications: [
      {
        title: "Product Name",
        value: "Urad Dal",
      },
      {
        title: "Origin",
        value: "India",
      },
      {
        title: "Family",
        value: "Fabaceae",
      },
      {
        title: "Binomial name",
        value: "Vigna mungo",
      },
    ],
    ingredients: [],
    benifits: [
      "Urad dal is packed with both soluble and insoluble fiber, enhancing digestion by bulking up stool and stimulating peristaltic motion for smoother, more efficient bowel movements.",
      "Urad dal, rich in fiber, magnesium, and potassium, supports heart health by managing cholesterol levels and preventing atherosclerosis, keeping your cardiovascular system in top shape.",
      "Urad dal's high iron content boosts overall energy levels, keeping you active and vibrant throughout the day.",
      "Urad dal is packed with essential minerals like magnesium, iron, and calcium, boosting bone mineral density and supporting overall skeletal health.",
    ],
    outro:
      "JNTB EXPORT IMPORT stands out as a top Urad Dal Gota importer from India. We provide seamless support throughout the entire export process, from documentation to delivery. Our experienced team ensures that every step of the Urad Dal Gota export is handled with utmost care, minimizing confusion and hassle. With a strong commitment to health and safety regulations, we guarantee that your products reach their destination safely and on time. Whether you're seeking high-quality Urad Dal Gota or reliable export services, JNTB EXPORT IMPORT is your go-to partner. Contact us today for a smooth and efficient export experience!",
    varieties: [],
  },
  {
    heading: "Indian Cashew Nuts Exporters",
    picture: cashewBanner,
    link: "Cashew",
    firstLayout: false,
    description:
      "The cashew tree, a tropical evergreen from the Anacardiaceae family, is primarily grown for its distinctive curved seeds, known as cashew nuts. As one of the leading exporters, India supplies cashew nuts to 61 countries worldwide, with a total export value of approximately USD 41.64 million. The top destinations for Indian cashew nuts include Vietnam, the USA, Malaysia, the UAE, and Norway. Indian exporters offer a wide range of cashew varieties to meet diverse global demands. With its extensive reach and quality products, the Indian cashew nuts export industry plays a crucial role in satisfying the global appetite for this nutritious and versatile snack.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 48 calories",
          "Dietary Fiber - 3.3 g",
          "Carbohydrate 11 g",
        ],
      },
      {
        title: "Protein",
        value: ["18g"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin B-6"],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Iron", "Magnesium"],
      },
      {
        title: "Fat",
        value: [],
      },
    ],
    benifits: [],
    outro:
      "JNTB EXPORT IMPORT stands as the top importer of Cashew Nuts from India, committed to simplifying the export process for our clients. We know that exporting can be complex, but our experienced team is here to assist you at every stage, from handling documentation to ensuring the final delivery of your products. With our expertise, you can navigate the export process with ease and confidence. If you're seeking a reliable partner for Cashew Nuts export, JNTB EXPORT IMPORT is your go-to choice. Connect with us today and let us help you achieve a smooth and successful export experience!",
    varieties: [
      {
        heading: "BPP-1",
        description:
          "The BPP-1 cashew variety, introduced in 1980, is a hybrid derived from a cross between Tree No.1 and Tree No.273. This variety yields an average of 10 kg per tree, producing medium-sized nuts that weigh approximately 5g each. With a shelling percentage of around 27.5%, BPP-1 offers a balance of quality and efficiency. Known for its reliable yield and consistent nut size, BPP-1 is a preferred choice for growers seeking dependable performance in cashew production.",
      },
      {
        heading: "BPP-8",
        description:
          "The BPP-8 cashew variety, a hybrid from the cross between Tree No.1 and Tree No.39, was released in 1993 for cultivation in Andhra Pradesh. Known for its superior performance, BPP-8 has excelled in regions like Orissa and West Bengal, outshining the other six varieties developed at Bapatla. Its adaptability and robustness make it a standout choice for growers, offering excellent yield and quality, making it a preferred variety in cashew farming across these regions.",
      },
      {
        heading: "VRI-1",
        description:
          "The VRI-1 cashew variety, selected from germplasm in Vazhi Sothanai Palayam, Tamil Nadu, was released in 1981. This medium-sized tree typically sets 5 to 7 fruits per panicle, yielding an average of 7.2 kg per tree under Vridhachalam conditions. The VRI-1 variety produces small nuts, each weighing around 5g, with a shelling percentage of approximately 28%. Its consistent yield and quality make VRI-1 a valuable choice for cashew growers seeking reliable performance.",
      },
      {
        heading: "VRI-3",
        description:
          "The VRI-3 cashew variety, released in 1991, originates from the seedling progeny of a high-yielding tree in Edayanchavadi, South Arcot District, Tamil Nadu. Known for its robust performance, VRI-3 averages a yield of around 10 kg per tree. The nuts are medium-sized, with a weight of 7.2g and a shelling percentage of approximately 29.1%. This variety is prized for its balance of yield and nut quality, making it a preferred choice among cashew cultivators.",
      },
      {
        heading: "Bhuvaneshwar",
        description:
          "The Bhuvaneshwar cashew variety, released in 1989, is a selection from the seedling progeny of WBDC V (Vengurla 36/3), sourced from the Regional Fruit Research Station, Vengurla. It features a cluster-bearing habit, producing about 12 fruits per bunch. The flowering season for Bhuvaneshwar spans from January to March, lasting around 70 days. This variety's consistent fruiting and medium-duration flowering make it a reliable choice for growers seeking productive and manageable cashew cultivation.",
      },
      {
        heading: "Balabhadra",
        description:
          "The Balabhadra cashew variety is known for its early flowering season, from December to February, and produces bold nuts weighing around 7.4g each. With an impressive average yield of 2.0 tons per hectare (10.0 kg per tree), Balabhadra stands out for its productivity. Additionally, it boasts a high shelling percentage of about 30.0%, making it a valuable choice for growers seeking high-yield and quality nuts. Balabhadra's early maturity and robust performance make it a top contender in cashew cultivation.",
      },
      {
        heading: "Jhargram-1",
        description:
          "Jhargram-1, released in 1989, is a cashew variety selected from T.No.16 originally collected from Bapatla. It features a medium, compact canopy with an intensive branching habit, producing an average of 6 fruits per bunch. This variety yields around 8.5 kg per tree, with small nut sizes and a shelling percentage of approximately 30%. Jhargram-1 is valued for its balanced growth and reliable yield, making it a solid choice for cashew cultivators seeking consistent performance.",
      },
      {
        heading: "Jhargram-2",
        description:
          "Jhargram-2, selected from the seedling plantation of H-2/15 at the Regional Research Station in West Bengal, boasts a midseason flowering habit. This variety produces 3-4 fruits per panicle and features an average nut weight of 9.2g, with a kernel weight of 2.85g. With a shelling percentage of about 32%, Jhargram-2 delivers both quality and quantity. Its impressive nut size and high shelling efficiency make it a standout choice for cashew growers seeking superior performance.",
      },
      {
        heading: "Vengurla-1",
        description:
          "The Vengurla-1 cashew variety, released in 1974, is selected from germplasm collected in Ansur village, Vengurla Tehsil, Maharashtra. Known for its robust performance, Vengurla-1 yields approximately 19 kg per tree. This variety features nuts weighing around 6.2g each and a shelling percentage of 31%. With about 8% perfect flowers, Vengurla-1 offers a strong combination of yield and quality, making it a reliable choice for cashew growers seeking consistent results and high efficiency.",
      },
      {
        heading: "Vengurla-8",
        description:
          "Released in 2001, the Vengurla-8 is a hybrid cashew variety known for its impressive yield of 15-20 kg per tree. It produces bold nuts, with approximately 86 nuts per kilogram, and is recognized for its vibrant red apple variety. Vengurla-8 also boasts a high juice recovery rate of 85%, making it a standout choice for both nut production and juice extraction. Its robust performance and quality characteristics make Vengurla-8 a top pick for cashew cultivators.",
      },
      {
        heading: "Balli-2",
        description:
          "Released in 1999 by the ICAR Research Complex in Goa, Balli-2 is the first cashew variety from the region. This standout variety yields an average of 7.0 kg per tree and features nuts weighing approximately 7.6g each. With a shelling percentage of 30.0%, Balli-2 combines reliable yield with quality nut production. Its introduction marks a significant advancement in Goan cashew cultivation, making it a preferred choice for growers seeking both performance and excellence.",
      },
      {
        heading: "Tiswadi-3",
        description:
          "Developed and released by the ICAR Research Complex, Goa, in 2007, the Tiswadi-3 variety is a premium selection from Ela village in Tiswadi taluk, North Goa. Known for its robust performance, Tiswadi-3 yields an average of 5.5 kg per tree. The variety features nuts weighing about 9.4g each and boasts a shelling percentage of around 29.25%. Tiswadi-3 stands out for its quality and consistency, making it a top choice for cashew growers in the region.",
      },
    ],
    varietyHeading: "Cashew Nuts Varieties We Export",
    verietyDesc:
      "India's Cashew Nuts import includes a wide range of premium varieties, such as BPP-1, BPP-8, and VRI-1, among others. Indian exporters are renowned for delivering top-quality cashews to global markets, ensuring that each variety meets the highest standards. From rich flavor to superior texture, these cashews are exported to various corners of the world, catering to diverse tastes and preferences. Choose Indian cashews for unmatched quality and variety in every shipment.",
  },
  {
    heading: "Indian Walnut Exporters",
    picture: walnutBanner,
    link: "Walnut",
    firstLayout: true,
    description:
      "Walnuts are rounded, single-seeded stone fruits from the walnut tree, packed with healthy fats, fiber, vitamins, and minerals. Indian walnut exporters have seen a significant increase in exports over the years, driven by the growing global awareness of walnuts' health and medicinal benefits. This surge in demand has translated into substantial revenue for Indian exporters. The walnut export industry from India is thriving, with a diverse range of walnut varieties being shipped worldwide. As the global appetite for walnuts continues to grow, Indian exporters are capitalizing on this trend, showcasing the country’s rich production and quality in the international market.",
    marketDemand:
      "In the fiscal year 2018-19, Indian walnut exporters shipped approximately 1,874.87 MT of walnuts globally, generating a revenue of Rs. 66.75 crores (USD 9.61 million). Key importers include Germany, the UK, the USA, the Netherlands, and France, highlighting a robust international demand. This growing export volume reflects the increasing global appreciation for walnuts, driven by their nutritional benefits and culinary versatility. The rising demand underscores India's significant role in the global walnut market and the expanding opportunities for Indian exporters to cater to diverse international markets.",
    specifications: [
      {
        title: "Product Name",
        value: "Walnut",
      },
      {
        title: "Origin",
        value: "Iran (Persia)",
      },
      {
        title: "Family",
        value: "Juglandaceae",
      },
      {
        title: "Binomial name",
        value: "Juglans",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories 654 kcal",
          "Total Carbohydrates 14g",
          "Dietary Fiber 7g",
        ],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Iron", "Magnesium"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin B-6", "Vitamin C"],
      },
      {
        title: "Protein",
        value: ["15 g"],
      },
      {
        title: "Fats",
        value: ["65g"],
      },
    ],
    benifits: [
      "Walnuts are packed with antioxidants that combat oxidative damage, including harm caused by 'bad' LDL cholesterol. These powerful compounds help protect your body from cellular damage and support overall health.",
      "Walnuts are an excellent source of plant-based omega-3 fats, which may help lower the risk of heart disease. Including them in your diet can support cardiovascular health and boost overall well-being.",
      "Walnuts are rich in plant compounds and nutrients that can help reduce inflammation, a major factor in many chronic diseases. Incorporating walnuts into your diet may support better health by addressing this underlying cause of illness.",
      "Walnuts nourish your body and support gut bacteria, potentially enhancing gut health and lowering disease risk. Their beneficial effects on your digestive system contribute to overall wellness and a stronger, healthier body.",
    ],
    outro:
      "JNTB EXPORT IMPORT is a top-tier importer of walnuts from India, dedicated to simplifying the complex walnut export process. We know that exporting can be challenging, so our expert team is here to guide you through every step, from handling documentation to ensuring timely delivery. With our extensive global network and deep market knowledge, we’ve consistently delivered exceptional service to our clients. Whether you're navigating export regulations or seeking reliable delivery, JNTB EXPORT IMPORT provides the support you need for a smooth and successful walnut export experience. For seamless walnut imports, reach out to JNTB EXPORT IMPORT today!",
    varieties: [],
  },
  {
    heading: "Indian Almond Nuts Exporters",
    picture: almondsBanner,
    link: "almonds",
    firstLayout: false,
    description:
      "Almonds, often mistaken for nuts, are actually teardrop-shaped seeds from the almond tree. Indian almond exporters ship tonnes of these prized nuts globally each year, capitalizing on their rising popularity. This surge in global demand has driven a rapid increase in almond exports from India. As almond consumption continues to grow worldwide, it presents a prime opportunity for Indian exporters to expand their presence in the international market. The export industry features a diverse range of almond varieties, catering to various preferences and needs. Now is the perfect time for Indian almond exporters to seize the opportunity and make their mark on the global stage.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 161 calories",
          "Dietary Fiber - 3.5 g",
          "Carbohydrate 2.5 g",
        ],
      },
      {
        title: "Protein",
        value: ["6g"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin E", "Vitamin B"],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Selenium", "Zinc", "Magnesium", "Folate", "Biotin"],
      },
      {
        title: "Fat",
        value: [],
      },
    ],
    benifits: [],
    outro:
      "JNTB EXPORT IMPORT stands as a leading importer of almond nuts from India, dedicated to simplifying the often complex export process. We know that navigating almond nut exports can be challenging, but our expert team is here to ensure a seamless experience. From handling all aspects of the export process to ensuring timely delivery, our highly trained executives are with you every step of the way. With our extensive experience in the food export industry, we are well-equipped to address any issues that may arise. If you're seeking a reliable partner for almond nut exports, look no further—contact JNTB EXPORT IMPORT today and let us handle the details while you enjoy smooth, successful transactions!",
    varieties: [
      {
        heading: "Nonpareil",
        description:
          "The Nonpareil almond variety stands out for its appealing look and robust almond flavor. Known for its thin outer shell and smooth, blemish-free nuts, Nonpareil almonds offer a medium-flat shape with a light color and flawless surface. This premium variety is celebrated for its visual and taste qualities, making it a top choice for both snacking and culinary use. Enjoy the perfect blend of aesthetics and flavor with Nonpareil almonds, a true favorite in the almond world.",
      },
      {
        heading: "Carmel",
        description:
          "The Carmel almond variety, a prominent California type, is popular enough to stand on its own. Known for its soft shell, Carmel almonds are ideal for blanching and roasting. This versatile variety is often used as a substitute for Nonpareil almonds, offering a similar texture and flavor. Carmel almonds are favored for their adaptability in various culinary applications, making them a staple choice for both home cooks and professionals alike.",
      },
      {
        heading: "Butte",
        description:
          "The Butte almond variety, a Mission type, features a semi-hard shell with a light color and smooth surface. The nuts are small, short, and plump, with a distinctive wrinkled texture. Renowned for its quality, the Butte variety is well-suited for export from India. Its appealing characteristics make it a popular choice for various culinary uses, ensuring that it meets both domestic and international market standards.",
      },
      {
        heading: "Peerless",
        description:
          "The Peerless almond, a California type, is distinguished by its hard shell, light color, and smooth surface. This variety produces medium-sized nuts with a broad shape and a slightly wrinkled surface. Valued for its robust quality and visual appeal, Peerless almonds are ideal for a range of culinary uses and are a favored choice in the export market. Their consistent texture and attractive appearance make them a standout option for both consumers and producers alike.",
      },
      {
        heading: "Sonora",
        description:
          "The Sonora almond, a California variety, is known for its paper-thin shell and dark brown color with a rough texture. These large, long, and narrow nuts feature a light color and a smooth surface, making them highly desirable. Their striking appearance and substantial size make Sonora almonds a popular choice for various applications, from snacking to culinary use. With their unique characteristics and premium quality, Sonora almonds stand out in both domestic and international markets.",
      },
    ],
    varietyHeading: "Almond Nuts Varieties We Export",
    verietyDesc:
      "Indian almond nut exports feature a range of premium varieties, including Nonpareil, Carmel, and Butte, among others. Each variety is carefully selected to meet stringent health and safety regulations, ensuring top-notch quality. Indian exporters are committed to delivering the finest almond nuts, adhering to high standards and catering to diverse global preferences. Explore these major varieties and experience the excellence of Indian almond nuts in every shipment.",
  },
  {
    heading: "Indian Apricots Exporters",
    picture: apricotsBanner,
    link: "Apricots",
    firstLayout: false,
    description:
      "Apricots, nutrient-packed stone fruits, are exported from India to nearly 95 countries worldwide. The total value of apricot exports from India reaches approximately USD 27.97 million. Indian exporters supply a diverse range of apricot varieties to global markets, including top destinations like the Netherlands, China, the USA, France, and the United Kingdom. These top five countries alone account for about USD 17.4 million of the total export value. The growing international demand highlights India’s role as a key player in the global apricot market, providing high-quality fruits to satisfy diverse global tastes and preferences.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 48 calories",
          "Dietary Fiber - 2 g",
          "Carbohydrate 11 g",
        ],
      },
      {
        title: "Protein",
        value: ["1.4g"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin A", "Vitamin C", "Vitamin B-6"],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Iron", "Magnesium"],
      },
      {
        title: "Fat",
        value: [],
      },
    ],
    benifits: [],
    outro:
      "JNTB EXPORT IMPORT is a leading importer of apricots from India, bringing extensive expertise and deep industry knowledge to the table. Our experienced team is dedicated to guiding you through every step of the export-import process, from securing buyers to ensuring the smooth delivery of your products. We understand the nuances of the food export industry and provide comprehensive support to streamline your experience. With JNTB EXPORT IMPORT, you benefit from our proven track record and commitment to excellence in apricot exports. For a reliable and efficient partner in importing apricots, look no further—contact JNTB EXPORT IMPORT today!",
    varieties: [
      {
        heading: "Aprium",
        description:
          "The Aprium apricot variety features medium to large fruits with a clear yellow skin and a hint of plum flavor. Known for its late harvest, Aprium apricots offer a unique taste and appealing appearance. This variety’s quality and distinct flavor profile make it an excellent choice for export. Its versatility and robust characteristics ensure it stands out in international markets, making it a popular selection for buyers seeking premium apricots.",
      },
      {
        heading: "August Glo",
        description:
          "The August Glo apricot variety, ideal for export from India, is a medium-sized fruit known for its exceptional sweet-tart flavor. Harvested late in the season, August Glo offers a distinct taste that stands out in the market. Its quality and flavor make it a desirable choice for international buyers seeking premium apricots.",
      },
      {
        heading: "Autumn Royal",
        description:
          "This apricot variety, akin to Blenheim, features medium-sized fruits with a yellow skin and a hint of orange blush. The yellow flesh is slightly acidic, offering a unique flavor profile. Versatile in use, these apricots are ideal for enjoying fresh, or for canning and drying. Their vibrant color and tangy taste make them a favorite choice for various culinary applications, ensuring they stand out in both domestic and international markets.",
      },
      {
        heading: "Blenheim",
        description:
          "The Blenheim apricot is medium to large, featuring thick yellow-orange flesh and a juicy, aromatic flavor. Renowned as the classic California apricot, it offers a delightful blend of sweet and tangy notes. Ready for harvest from early to midseason, Blenheim apricots are celebrated for their rich taste and versatility. Their vibrant color and exceptional flavor make them a standout choice for fresh consumption, canning, and culinary uses, appealing to both domestic and international markets.",
      },
      {
        heading: "Mormon",
        description:
          "The Mormon apricot variety features small, orange-skinned fruits with a vibrant red blush. Known for its smooth texture and firm flesh, Mormon apricots offer a sweet and juicy flavor. Perfect for midseason to late harvest, this variety is prized for its appealing taste and attractive appearance. Whether enjoyed fresh or used in culinary creations, Mormon apricots stand out for their quality and deliciousness, making them a top choice for both domestic and international markets.",
      },
      {
        heading: "Earligold",
        description:
          "The Earligold apricot variety is medium-sized with a stunning golden-yellow color and rich, juicy flesh. Known for its versatility, Earligold is ideal for both canning and fresh consumption. This variety is prized for its early harvest, offering a delightful flavor and vibrant color that make it a favorite in both home kitchens and commercial settings. Enjoy the sweet, succulent taste of Earligold apricots as a standout choice for various culinary uses.",
      },
      {
        heading: "Floragold",
        description:
          "Floragold apricots are small to medium in size, featuring a vibrant yellow skin and flesh. Ideal for mid-season harvest, this variety is well-suited for export, offering consistent quality and appealing color. With its smooth texture and delicious flavor, Floragold apricots meet international standards and cater to diverse market preferences. Whether fresh or processed, these apricots stand out for their reliability and taste, making them a top choice for global buyers.",
      },
      {
        heading: "Garden Annie",
        description:
          "The Garden Annie apricot is a medium to large variety with vibrant yellow skin. Its clingstone flesh is both juicy and firm, offering a delightful texture and flavor. Ideal for early harvest, Garden Annie apricots are prized for their bright color and robust taste. Whether enjoyed fresh or used in various culinary applications, this variety delivers consistent quality and appeal. Garden Annie stands out in the market, making it a preferred choice for both domestic and international buyers.",
      },
      {
        heading: "Goldcot",
        description:
          "The Goldcot apricot is medium to large, featuring nearly round fruits with bright golden skin. Its thick orange flesh is firm and offers a sprightly-sweet flavor. Versatile in use, Goldcot apricots are ideal for processing, canning, or enjoying fresh. Their vibrant color and robust taste make them a favorite for a variety of culinary applications. Goldcot stands out for its exceptional quality and flavor, making it a top choice for both consumers and processors.",
      },
      {
        heading: "Moorpark",
        description:
          "The Moorpark apricot is a large, historic variety developed in the eighteenth century. Known for its juicy and aromatic qualities, it boasts a plum-like taste. The fruit features distinctive brownish-red skin with spots and dots, and vibrant yellow to orange flesh. Ready for midseason harvest, Moorpark apricots are celebrated for their rich flavor and appealing appearance. This classic variety stands out for its exceptional taste and texture, making it a cherished choice for both fresh eating and culinary use.",
      },
      {
        heading: "Riland",
        description:
          "The Riland apricot variety is medium-sized and nearly round, featuring a light yellow skin with a striking deep red blush covering half of the fruit. Its fine, velvety texture adds to its appeal. The flesh is firm and meaty, offering a satisfying bite and rich flavor. Ideal for various uses, Riland apricots stand out for their distinctive appearance and robust texture, making them a popular choice for both fresh consumption and culinary applications.",
      },
    ],
    varietyHeading: "Apricots Varieties We Export",
    verietyDesc:
      "India's apricot exports feature a range of premium varieties, including Aprium, August Glo, and Autumn Royal, among others. These exports adhere to stringent health and safety regulations, ensuring top-quality products for global markets. The commitment to quality and compliance makes Indian apricots a trusted choice for buyers around the world. Discover the diverse selection of apricot varieties available and experience the excellence of Indian exports in every shipment.",
  },
  {
    heading: "Indian Pistachio Exporters",
    picture: pistaBanner,
    link: "Pistachio",
    firstLayout: true,
    description:
      "Pistachio nuts, the edible seeds of the Pistacia vera tree, are packed with healthy fats, protein, fiber, and antioxidants. As the global demand for pistachios continues to grow, the export of these nuts from India has seen a significant rise. This presents a valuable opportunity for Indian Pistachio exporters to expand their businesses and tap into the international market. With various varieties and qualities available, Indian exporters can cater to diverse consumer preferences, making it an ideal time to establish a strong presence globally.",
    marketDemand:
      "Indian Pistachio exporters deliver high-quality pistachios to markets across the globe, meeting the growing demand for these nutritious nuts. As more people become aware of the numerous uses and health benefits of pistachios, the global market demand has been steadily increasing. This surge in popularity has led to a significant rise in Pistachio imports from India over the past few years. Indian exporters are well-positioned to capitalize on this trend, offering premium pistachios that cater to diverse consumer needs worldwide, further solidifying their presence in the international market.",
    specifications: [
      {
        title: "Product Name",
        value: "Pistachio",
      },
      {
        title: "Origin",
        value: "Central Asia",
      },
      {
        title: "Family",
        value: "Cashews",
      },
      {
        title: "Binomial name",
        value: "Pistacia vera",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories 562 kcal",
          "Total Carbohydrates 28 g",
          "Dietary Fiber 10 g",
        ],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Iron", "Magnesium"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin A", "Vitamin B-6", "Vitamin C"],
      },
      {
        title: "Protein",
        value: ["20 g"],
      },
      {
        title: "Fats",
        value: ["45 g"],
      },
    ],
    benifits: [
      "Pistachios are among the most antioxidant-rich nuts, packed with lutein and zeaxanthin. These powerful antioxidants help promote eye health, protecting against potential damage and supporting clear vision. Enjoy pistachios as a tasty way to care for your eyes.",
      "Pistachios are lower in calories yet higher in protein than most other nuts. They also boast the highest essential amino acid content, making them a nutritious and efficient choice for those seeking a protein-packed snack.",
      "Pistachio nuts can support weight loss efforts, especially when consumed in-shell, as they encourage mindful eating. The process of shelling slows down consumption, helping you enjoy each nut while keeping portion sizes in check.",
      "Pistachios are high in fiber, promoting gut health by increasing beneficial bacteria that produce short-chain fatty acids like butyrate. These acids support a healthy digestive system, making pistachios a tasty way to nourish your gut.",
    ],
    outro:
      "JNTB EXPORT IMPORT is a top Pistachio importer from India, committed to ensuring a seamless export process for our clients. With extensive experience in the export industry, our team guides you through every step, minimizing confusion and hassle. As a trusted exporter, we strictly adhere to all health and safety regulations, ensuring your products are delivered safely and efficiently. If you're looking for a reliable partner for Pistachio export from India, JNTB EXPORT IMPORT is here to help. Contact us now!",
    varieties: [],
  },
  {
    heading: "",
    picture: foxnutBanner,
    link: "",
    firstLayout: "",
    description: "",
    marketDemand: "",
    specifications: [],
    ingredients: [],
    benifits: [],
    outro: "",
    varieties: [],
  },
  {
    heading: "Indian Dry Fig Exporters",
    picture: figsBanner,
    link: "Figs",
    firstLayout: true,
    description:
      "Figs, from the Ficus tree in the Mulberry family, are predominantly dried, either naturally or through artificial means. India is a major exporter of dry figs, sending vast quantities to global markets annually. The surge in popularity of dry figs has driven a significant increase in exports over recent decades. Indian dry fig exporters are capitalizing on this growing demand, offering a diverse range of varieties and qualities to meet international standards. As the market for dry figs continues to expand, Indian exporters are well-positioned to supply premium products worldwide. Explore the opportunities with India's leading dry fig exporters and tap into the thriving global market!",
    marketDemand:
      "The global demand for dry figs has surged in recent years, driven by their numerous health and medicinal benefits. Renowned for their rich nutritional profile, dry figs are increasingly sought after across the world. India, a key player in the dry fig export market, supplies high-quality figs to various international regions. The rising popularity and excellent reputation of Indian dry figs have led to a significant increase in imports. Indian dry fig exporters are capitalizing on this growing demand, ensuring their products meet global standards for quality and excellence. As the market for dry figs expands, Indian exporters are poised to deliver top-notch figs to discerning buyers worldwide.",
    specifications: [
      {
        title: "Product Name",
        value: "Dry Fig",
      },
      {
        title: "Origin",
        value: "Northern Asia Minor",
      },
      {
        title: "Family",
        value: "Moraceae",
      },
      {
        title: "Binomial name",
        value: "Ficus carica",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories 30 kcal",
          "Total Carbohydrates 8 g",
          "Dietary Fiber 1 g",
        ],
      },
      {
        title: "Minerals",
        value: ["Copper", "Magnesium", "Potassium", "Riboflavin", "Thiamine"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin B-6", "Vitamin K"],
      },
      {
        title: "Protein",
        value: ["0 g"],
      },
      {
        title: "Fats",
        value: ["0 g"],
      },
    ],
    benifits: [
      "Figs, used since ancient times, are a natural remedy for digestive issues like constipation. Their high fiber content makes them a perfect choice for easing bowel movements and promoting digestive health.",
      "Figs are known to support heart health by improving blood pressure and reducing blood fat levels. Their benefits contribute to better vascular health and may lower the risk of heart disease, making them a heart-friendly choice.",
      "Figs offer skin benefits, especially for those with allergic dermatitis or dry, itchy skin. Their soothing properties help alleviate discomfort and improve skin health, making them a great natural remedy for skin issues caused by allergies.",
      "Figs help manage blood fat and sugar levels and show potential in killing cancer cells. While promising, more research is needed to fully understand these benefits and their implications for health and disease management.",
    ],
    outro:
      "JNTB EXPORT IMPORT is a premier importer of Dry Figs from India, committed to sharing the richness of Indian culture and food with the world. Our dedicated team ensures a seamless export process, guiding you from initial steps to final delivery. With extensive expertise and a deep understanding of the export industry, we handle every detail to make your Dry Fig export experience smooth and hassle-free. If you're searching for a reliable Dry Fig exporter, look no further—contact JNTB EXPORT IMPORT today and let us help you bring top-quality Indian Dry Figs to the global market!",
    varieties: [],
  },
  {
    heading: "Indian Dates Exporters",
    picture: datesBanner,
    link: "Dates",
    firstLayout: false,
    description:
      "Dates are sweet, cylindrical fruits ranging from 3-7 cm long, with a central seed. These naturally sugary fruits are staples in Middle-Eastern cuisine. India’s Date export industry thrives, shipping dates to numerous global destinations. With growing recognition of their health benefits and diverse uses, the demand for dates has surged. This creates an excellent opportunity for Indian Date exporters to broaden their reach in the international market. The export includes a variety of high-quality dates, catering to global preferences and standards. For those looking to enter the date export business or expand their existing operations, India offers a promising landscape.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories - 20 calories",
          "Dietary Fiber - 0.6 g",
          "Carbohydrate 5.33 g",
        ],
      },
      {
        title: "Protein",
        value: ["0.17g"],
      },
      {
        title: "Fat",
        value: [],
      },
    ],
    benifits: [],
    outro:
      "JNTB EXPORT IMPORT stands as a premier importer of dates from India, dedicated to sharing the rich heritage and exceptional quality of Indian produce with the world. Our team of experts ensures a seamless experience at every stage of the export process, from initial documentation to final delivery. With deep industry knowledge and extensive experience, we handle all aspects of export logistics to make the process as smooth and hassle-free as possible for our clients. Whether you're seeking to import premium dates or expand your product offerings, JNTB EXPORT IMPORT is your trusted partner. Explore the world of high-quality Indian dates with us—contact JNTB EXPORT IMPORT today to get started!",
    varieties: [
      {
        heading: "Medjool",
        description:
          "Medjool dates, often dubbed the ‘Queen of Dates,’ are perfect for adding a touch of luxury to smoothies, shakes, and sweet dishes. These large, fleshy dates boast a bright yellow hue and a delightful taste. Although they aren’t ideal for raw consumption, their rich, caramel-like flavor makes them a favored choice for culinary creations. Their luscious texture and vibrant color elevate any recipe, making Medjool dates a must-have ingredient for gourmet delights.",
      },
      {
        heading: "Hallawi",
        description:
          "Hallawi dates are versatile and can be enjoyed both raw and dried. Known for their rain tolerance and early fruiting, this variety offers a substantial yield, averaging 50-80 kg per tree. Hallawi dates are not only delightful when eaten fresh but are also perfect for making high-quality chuhara, a traditional dried date treat. Their resilience and excellent yield make them a valuable choice for both fresh consumption and dried products, appealing to a wide range of culinary preferences.",
      },
      {
        heading: "Zahidi",
        description:
          "Zahidi dates are a mid-season variety that handles high humidity and rainfall with ease. These semi-dry dates are ideally suited for processing into pind khajoor, a traditional delicacy. Although small in size and less suitable for raw consumption, Zahidi dates excel in culinary applications where their unique texture and flavor shine. Their resilience and processing versatility make them a preferred choice for creating delightful, time-honored treats.",
      },
      {
        heading: "Khadrawi",
        description:
          "Khadrawi dates offer a subtly less sweet flavor than Medjool but stand out for their soft, silky texture and golden-red flesh. Available from fall through early winter, this high-yielding variety thrives on smaller trees, making it an efficient choice for date cultivation. Its rich consistency and attractive color make Khadrawi dates a favored option for various culinary uses, delivering both taste and quality in every bite.",
      },
      {
        heading: "Barhee",
        description:
          "The Barhee variety of dates features small, spherical fruits known for their sweet flavor and smooth, crunchy texture. With a golden-yellow hue, these dates are ideal for eating raw, thanks to their high flesh content. A standout in date cultivation, Barhee boasts a high yield of approximately 60-110 kg per tree, making it a prolific choice for both fresh consumption and various culinary applications. Its appealing taste and texture make it a popular option for date enthusiasts.",
      },
      {
        heading: "Hayany",
        description:
          "The Hayany variety of dates is perfect for desserts and table use, known for its sweet flavor and dark brown color. These oblong, soft dates have a rich, fleshy texture that makes them ideal for eating raw. With an average yield of 30-40 kg per palm, Hayany dates are a versatile and delectable choice, enhancing both sweet and savory dishes. Their delightful taste and texture make them a popular option for a variety of culinary applications.",
      },
      {
        heading: "Deglet",
        description:
          "The Deglet cultivar dates are available from late summer through fall, showcasing their medium size and vibrant yellow to amber color. Known for their crunchy, semi-dry texture and sizable seed, these dates offer a less intense sweetness compared to Medjool dates. Their firm consistency and mild flavor make them an excellent choice for various culinary uses, from snacking to baking, providing a delightful and versatile option for date lovers.",
      },
      {
        heading: "Kimia",
        description:
          "Kimia dates are renowned for their delectable flavor and meaty texture. With a luscious, smooth brown-black skin, these dates are perfect for table use and can be enjoyed both raw or dried. Their rich taste makes them an excellent choice for a variety of desserts, adding a touch of indulgence to any treat. Whether used in baking or as a sweet snack, Kimia dates offer a delightful and versatile addition to your culinary repertoire.",
      },
    ],
    varietyHeading: "Apricots Varieties We Export",
    verietyDesc:
      "The import of dates from India features an array of premium varieties, including Medjool, Hallwi, Zahidi, and more. Each of these varieties is known for its distinctive taste and texture, contributing to the growing global demand. Indian dates are celebrated for their high quality and rich flavor, making them a popular choice for international markets. The diverse selection of dates available for export ensures that buyers can find the perfect variety to meet their needs. With a focus on maintaining top-notch quality and adhering to international standards, Indian date exporters are well-positioned to supply these sought-after products worldwide. Discover the excellence of Indian dates and their remarkable varieties in the global market.",
  },
  {
    heading: "Indian Raisins Exporters",
    picture: raisinsBanner,
    link: "Raisins",
    firstLayout: true,
    description:
      "Raisins, essentially dried grapes, are bite-sized morsels of natural sweetness and a healthy snack option. The Raisins exporter in India ships vast quantities of these dried fruits each year to various countries worldwide. With the rising popularity and diverse uses of raisins, the export market for this product has seen significant growth. This trend presents a lucrative opportunity for Indian Raisins exporters to expand their businesses and tap into the international market. The Raisins export from India encompasses a wide variety of these flavorful and nutritious products, catering to global demand.",
    marketDemand:
      "Raisins, packed with minerals and nutrients, offer numerous health and medicinal benefits. This has led to a rising global demand for raisins, with Indian exporters playing a significant role in meeting this need. The Raisins exporter in India supplies high-quality raisins to various countries, including Morocco, Saudi Arabia, Russia, and Sri Lanka, among others. The growing reputation for excellence in the quality of Indian raisins has driven an increase in Raisin imports from India, making it an ideal time for exporters to strengthen their presence in the international market.",
    specifications: [
      {
        title: "Product Name",
        value: "Raisins",
      },
      {
        title: "Origin",
        value: "Corinth, Greece",
      },
      {
        title: "Family",
        value: "Rhamnaceae",
      },
      {
        title: "Binomial name",
        value: "Vitis vinifera L.",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories 299 kcal",
          "Total Carbohydrates 79g",
          "Dietary Fiber 3.7g",
        ],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Iron", "Magnesium"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin B-6", "Vitamin C"],
      },
      {
        title: "Protein",
        value: ["3.1 g"],
      },
      {
        title: "Fats",
        value: ["Fat 0.5g"],
      },
    ],
    benifits: [
      "Raisins are beneficial for digestion, as they contain fibers that swell when combined with water. This natural swelling provides a gentle laxative effect, helping to relieve constipation and promoting a healthy digestive system.",
      "Raisins are packed with potassium and magnesium, which help lower acidity levels and flush out toxins. This can prevent health issues like arthritis, gout, and kidney stones, supporting overall well-being and a healthier system.",
      "Raisins are rich in iron and B-complex vitamins, aiding in the treatment of anemia. Their copper content supports the production of red blood cells, enhancing overall blood health and vitality.",
      "Raisins are packed with polyphenolic phytonutrients, powerful anti-inflammatory antioxidants. These compounds exhibit antibacterial properties that can help reduce fever risk and combat harmful bacteria, supporting overall health and wellness.",
    ],
    outro:
      "JNTB EXPORT IMPORT stands out as a top Raisins importer from India, offering extensive support in exporting a wide range of food products including fruits, vegetables, grains, pulses, and oilseeds. Our expertise and global connections provide an excellent opportunity for Raisins exporters to tap into the international market and achieve substantial revenue growth. With our dedicated team guiding you through each step of the export process, we ensure a smooth and successful experience. If you're seeking to expand your Raisins export business from India, contact JNTB EXPORT IMPORT today to explore exciting opportunities and maximize your market potential!",
    varieties: [],
  },
  {
    heading: "Indian Hazelnut Exporters",
    picture: hazelnutBanner,
    link: "hazelnut",
    firstLayout: true,
    description:
      "The hazelnut, a small, round fruit of the hazel tree, features a cinnamon-colored shell and creamy white-yellowish flesh, known for its rich oil content. India’s Hazelnut exporters are meeting increasing global demand by exporting substantial quantities of these nuts each year. The rising popularity of hazelnuts has driven a significant boost in exports, offering a prime opportunity for Indian exporters to expand into international markets. By providing a range of varieties and high-quality hazelnuts, Indian exporters are well-positioned to capitalize on this growing trend. If you’re looking to explore hazelnut exports, now is the perfect time to connect with top exporters and seize the opportunity to grow your business on a global scale.",
    marketDemand:
      "Indian Hazelnut exporters are renowned for delivering top-quality hazelnuts across the globe. As the health benefits and versatile uses of hazelnuts become more widely recognized, global demand for these nuts has surged. This growing awareness has led to a steady increase in hazelnut imports from India over the past few years. Indian exporters are well-positioned to meet this rising demand, offering a range of premium hazelnut varieties that cater to diverse markets. With a focus on quality and reliability, Indian hazelnut exporters are contributing to the global trend and opening new opportunities for businesses worldwide. If you're looking to capitalize on the booming hazelnut market, partnering with leading Indian exporters is a strategic move.",
    specifications: [
      {
        title: "Product Name",
        value: "Hazelnut",
      },
      {
        title: "Origin",
        value: "Asia",
      },
      {
        title: "Family",
        value: "Betulaceae",
      },
      {
        title: "Binomial name",
        value: "Corylus",
      },
    ],
    ingredients: [
      {
        title: "Nutrients",
        value: [
          "Calories 628 kcal",
          "Total Carbohydrates 17g",
          "Dietary Fiber 10g",
        ],
      },
      {
        title: "Minerals",
        value: ["Calcium", "Iron", "Magnesium"],
      },
      {
        title: "Vitamins",
        value: ["Vitamin B-6", "Vitamin C"],
      },
      {
        title: "Protein",
        value: ["15 g"],
      },
      {
        title: "Fats",
        value: ["61 g"],
      },
    ],
    benifits: [
      "Hazelnuts are packed with dietary fiber, which supports healthy digestion by promoting regular bowel movements and preventing constipation. Adding these nuts to your diet can help keep your digestive system running smoothly and comfortably.",
      "Hazelnuts are rich in antioxidants that shield your cells from oxidative damage caused by free radicals. These powerful compounds help reduce cell damage, supporting overall health and protecting against various chronic diseases.",
      "Eating hazelnuts can boost insulin sensitivity and help lower cholesterol levels. Their beneficial compounds support better blood sugar management and promote heart health, making them a smart choice for a balanced diet.",
      "Hazelnuts support heart health by improving cardiovascular risk biomarkers. A 2013 study found that a diet rich in hazelnuts may lower the risk of heart issues, making them a heart-friendly choice for a healthier lifestyle.",
    ],
    outro:
      "JNTB EXPORT IMPORT stands out as a premier Hazelnut importer from India, dedicated to making your export experience seamless and efficient. Our expert team provides comprehensive support throughout the process, from navigating documentation to ensuring timely delivery. With extensive industry experience and a commitment to adhering to all health and safety regulations, we guarantee that your hazelnuts reach their destination in top condition. As a trusted partner in hazelnut exports, JNTB EXPORT IMPORT helps you capitalize on the growing global demand. For a hassle-free and reliable export experience, connect with JNTB EXPORT IMPORT today!",
    varieties: [],
  },
  {
    heading: "",
    picture: woodappleBanner,
    link: "",
    firstLayout: "",
    description: "",
    marketDemand: "",
    specifications: [],
    ingredients: [],
    benifits: [],
    outro: "",
    varieties: [],
  },
  {
    heading: "",
    picture: limerindBanner,
    link: "",
    firstLayout: "",
    description: "",
    marketDemand: "",
    specifications: [],
    ingredients: [],
    benifits: [],
    outro: "",
    varieties: [],
  },
  {
    heading: "Indian Aloe Vera Exporter",
    picture: aloeveraBanner,
    link: "Aloe vera",
    firstLayout: true,
    description: "India is emerging as a key player in the global Aloe Vera market, exporting this versatile evergreen perennial to countries worldwide. Renowned for its agricultural and medicinal benefits, Aloe Vera is in high demand, especially with the rising popularity of Aloe Vera-based products like creams, lotions, and gels. This surge in demand presents a lucrative opportunity for Indian exporters to scale their businesses internationally. Indian Aloe Vera exports encompass a wide variety of Aloe Vera, catering to the diverse needs of the global market. With its growing popularity, Aloe Vera export from India is poised for significant growth, making it an opportune time for exporters to tap into this expanding market.",
    marketDemand: "The global demand for Aloe Vera has surged over the years, driven by its long-standing reputation as a powerful medicinal plant. Renowned for treating various health and skin conditions, Aloe Vera is a key ingredient in creams, ointments, gels, and other skincare products. Indian exporters have capitalized on this trend, supplying premium-quality Aloe Vera in large quantities to international customers. The rise in Aloe Vera imports from India reflects the global market's recognition of the superior quality and diverse varieties available. As the world continues to embrace the benefits of Aloe Vera, Indian exporters are well-positioned to meet this growing demand and expand their presence in the global marketplace.",
    specifications: [
      {
        title:"Product Name",
        value:"Aloe Vera"
      },
      {
        title:"Origin",
        value:"Arabian Peninsula"
      },
      {
        title:"Family",
        value:"Liliaceae"
      },
      {
        title:"Binomial name",
        value:"Aloe barbadensis miller"
      },
    ],
    ingredients: [
      {
        title:"Minerals",
        value:["Magnesium","Manganese","Copper","Phosphorous","Zinc","Copper"]
      },
      {
        title:"Vitamins",
        value:["Vitamin C","Vitamin K1","Vitamin A"]
      }
    ],
    benifits: ["Aloe Vera is an ideal remedy for burns, thanks to its soothing, moisturizing, and cooling properties. It helps heal burns effectively by calming the skin and promoting recovery, making it a go-to natural solution for burn treatment.","Aloe Vera is known to support digestive health, helping to soothe and improve your digestive system. Consuming Aloe Vera can ease stomach ailments and promote overall digestive wellness, making it a natural choice for a healthier gut.","Aloe Vera is beneficial for oral health, with toothpaste and mouthwash made from it helping to enhance oral hygiene and reduce plaque. Its natural properties make it an effective choice for maintaining a healthy and clean mouth.","Aloe Vera is highly effective for treating various skin conditions, particularly acne. Its soothing and anti-inflammatory properties help reduce breakouts and promote clear skin, making it a natural and gentle solution for achieving a healthier complexion."],
    outro: "JNTB EXPORT IMPORT is a leading importer of Aloe Vera from India, committed to making the export process seamless for our clients. We understand that navigating the complexities of Aloe Vera export can be challenging, but you can trust JNTB EXPORT IMPORT to guide you every step of the way. With the global demand for Aloe Vera on the rise, this is the perfect time for Indian exporters and vendors to capitalize on this opportunity. Our expertise ensures that your products reach international markets efficiently and effectively. If you're considering exporting Aloe Vera from India, look no further—contact JNTB EXPORT IMPORT today and let us help you succeed in the global marketplace.",
    varieties: [],
  },
  {
    heading: "Indian Tulsi Exporter",
    picture: basilBanner,
    link: "Holy basil",
    firstLayout: true,
    description: "Tulsi,an upright bushy shrub revered for its health and medicinal benefits, is gaining worldwide popularity. As a result, Tulsi exports from India have seen a significant surge over the decades. Indian exporters are meeting this growing demand by supplying vast quantities of Tulsi to international markets each year. The rising global interest in Tulsi has prompted Indian exporters to expand their offerings, providing a wide range of Tulsi varieties and qualities. This trend underscores the increasing recognition of Tulsi's value, making it an ideal time for exporters to capitalize on this opportunity. As demand continues to grow, Indian Tulsi exporters are well-positioned to strengthen their presence in the global marketplace.",
    marketDemand: "The global demand for Tulsi has been steadily increasing, driven by its wide range of uses and benefits across various fields. Recognized for its medicinal properties, Tulsi has become a sought-after product in international markets. Indian exporters are at the forefront, supplying high-quality Tulsi to regions worldwide. The rise in Tulsi imports from India is a testament to the global market's trust in the superior quality and effectiveness of Indian Tulsi. As demand continues to grow, Indian exporters are well-positioned to expand their reach and capitalize on this thriving market. With a strong reputation for excellence, Tulsi from India remains a preferred choice for consumers and businesses globally.",
    specifications: [
      {
        title:"Product Name",
        value:"Tulsi"
      },
      {
        title:"Origin",
        value:"North central India"
      },
      {
        title:"Family",
        value:"Lamiaceae"
      },
      {
        title:"Binomial name",
        value:"Ocimum tenuiflorum"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 22 kcal","Total Carbohydrate 2.7 g","Dietary Fiber 1.6 g"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin B","Vitamin B-6"]
      },
      {
        title:"Protein",
        value:["3.2 g"]
      },
    ],
    benifits: ["Tulsi adds a delightful flavor to juices and syrups, offering both culinary appeal and health benefits. Its versatility makes it a popular choice for enhancing beverages naturally.","Tulsi leaves are renowned for alleviating fevers with their powerful antibacterial and antibiotic properties. Their natural healing abilities make them a go-to remedy for effective fever relief.","Tulsi leaves are packed with antioxidants and essential oils that support the healthy functioning of pancreatic beta cells. These beneficial compounds help maintain optimal metabolic balance, making Tulsi a valuable addition to a health-conscious lifestyle.","Research suggests that Tulsi helps regulate the stress hormone Cortisol, keeping it within normal limits. By balancing this key hormone, Tulsi supports overall stress management and promotes a more relaxed, healthier state of mind."],
    outro: "JNTB EXPORT IMPORT stands out as a leading importer of Tulsi from India, committed to sharing the rich heritage of Indian culture and natural products with the world. Our dedicated team at JNTB EXPORT IMPORT is trained to guide and support clients through every step of the export process, ensuring a smooth and hassle-free experience. With our extensive expertise and deep experience in the export industry, we manage all aspects of Tulsi export with precision and care. As global demand for Tulsi grows, now is the perfect time to explore export opportunities. For seamless Tulsi export from India, trust JNTB EXPORT IMPORT to deliver excellence—contact us today and let us help you succeed in the international market!",
    varieties: [],
  },
  {
    heading: "Indian Amla Exporter",
    picture: amlaBanner,
    link: "Amla",
    firstLayout: true,
    description: "Amla, known as the Indian gooseberry, is a translucent green fruit revered for its health benefits, earning its name from the Sanskrit word ‘Amlaki,’ meaning “nectar of life.” Indian exporters are meeting the rising global demand by exporting large quantities of Amla each year. With its extensive health and medicinal uses, Amla's popularity has soared, driving a significant increase in exports over the decades. Indian Amla exporters are expanding their reach, providing a diverse range of Amla varieties to international markets. As the global interest in Amla continues to grow, this is an opportune moment for exporters to showcase the exceptional quality and benefits of Amla from India.",
    marketDemand: "The global demand for Amla has steadily risen, driven by increasing awareness of its numerous health benefits and versatile uses. This has led to a surge in Amla exports from India, where exporters are reaching markets around the world. The rise in Amla imports from India is a testament to the premium quality and diverse range of Amla products offered. Indian exporters are capitalizing on this growing interest by providing high-quality Amla to international buyers, enhancing their global presence. As demand continues to grow, India's reputation for delivering exceptional Amla ensures that the country's exports remain a top choice for health-conscious consumers worldwide.",
    specifications: [
      {
        title:"Product Name",
        value:"Amla"
      },
      {
        title:"Origin",
        value:"Subtropical regions of India"
      },
      {
        title:"Family",
        value:"Phyllanthaceae"
      },
      {
        title:"Binomial name",
        value:"Phyllanthus emblica"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 58 kcal","Total Carbohydrate 13.7 g","Dietary Fiber 3.4%"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Carotene","Thiamine","Riboflavin","Nicene"]
      },
      {
        title:"Protein",
        value:["0.5%"]
      },
      {
        title:"Fat",
        value:["0.1%"]
      },
    ],
    benifits: ["Amla is a powerhouse of Vitamin C, with its natural form being more easily absorbed by the body than synthetic supplements. This makes Amla a superior choice for boosting your Vitamin C intake and overall health.","Amla supports eye health thanks to its high carotene content, which research shows can enhance vision. Incorporating Amla into your diet helps maintain clear and healthy eyesight naturally.","Amla strengthens the immune system with its antibacterial and astringent properties. By boosting your body's natural defenses, Amla enhances overall immunity and helps keep you healthier and more resilient against infections.","Amla is an excellent hair tonic, combating greying, preventing dandruff, and strengthening hair follicles. Its properties boost blood circulation to the scalp, promoting healthier and more vibrant hair growth naturally."],
    outro: "JNTB EXPORT IMPORT is a premier Amla importer from India, dedicated to sharing the richness of Indian culture and natural products with the world. We believe in spreading the benefits of Indian food, flowers, and more globally. Our experienced team at JNTB EXPORT IMPORT is trained to assist clients through every step of the Amla export process, ensuring a smooth and hassle-free experience. With our deep expertise and extensive industry knowledge, we guarantee that your Amla export from India is handled with utmost care and efficiency. If you're looking to explore Amla export opportunities, trust JNTB EXPORT IMPORT to deliver excellence. Contact us today and let us help you navigate the international market with ease!",
    varieties: [],
  },
  {
    heading: "Indian Banana Exporters",
    picture: bananaBanner,
    link: "Bananas",
    firstLayout: false,
    description: "The export of food products from India is experiencing impressive growth annually, with banana exports emerging as a standout success. As one of the world's leading banana exporters, India has firmly established itself in the global market. Indian banana exporters are committed to maintaining the highest health and quality standards, ensuring that every shipment meets international expectations. This dedication to excellence has driven the rapid expansion of India's banana export sector. With its rich agricultural heritage and stringent quality control, India is well-positioned to continue its dominance in the global banana market. For premium quality bananas and reliable export solutions, look no further than India's trusted banana exporters.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Net Carbs - 24 gm","Fiber - 3.1 gm","Calories - 89g/100gm"]
      },
      {
        title:"Minerals",
        value:["Potasium","Calcium","Magnesium","Manganese","Iron","Folate","Niacin","Riboflavin","B6"]
      },
      {
        title:"Vitamin",
        value:["Vitamin B6 and B12"]
      },
      {
        title:"Protein",
        value:["1.1g/100gm"]
      },
      {
        title:"Fats",
        value:["0.3g/100gm"]
      },
    ],
    benifits: [],
    outro: "Looking to export bananas from India? JNTB EXPORT IMPORT is your go-to partner! With global demand for bananas rising, Indian banana exports are increasing annually. As one of the most versatile fruits, bananas are essential in a variety of dishes and applications, making them a top choice for importers worldwide. JNTB EXPORT IMPORT stands out as a leading banana importer from India, known for supplying premium quality bananas to every corner of the globe. Our commitment to excellence ensures that your export experience is smooth and successful. If you’re seeking reliable and top-notch food export solutions, JNTB EXPORT IMPORT is the best choice. Contact us today and let us help you meet your global banana export needs!",
    varieties: [
      {
        heading:"Dwarf Cavendish",
        description:"Dwarf Cavendish bananas are a key commercial cultivar, known for their compact size and distinctive maroon or purple blotches on young leaves. Widely grown across Asia for mass cultivation, they also add a tropical touch when planted as tall specimens in gardens. While ideal for creating a lush, exotic look, Dwarf Cavendish plants require substantial winter protection to thrive. Their versatility makes them a popular choice for both large-scale production and ornamental use."
      },
      {
        heading:"Basrai",
        description:"Basrai is a prominent commercial variety within the Cavendish group, primarily cultivated in Maharashtra. This dwarf banana plant is designed to withstand wind damage better than taller varieties. Basrai bananas are noted for their impressive bunch size, along with desirable fruit length and size. Its robust growth and high-quality fruit make Basrai a favorite among growers, combining durability with excellent yield for both local and broader markets."
      },
      {
        heading:"Robusta",
        description:"Robusta bananas are known for their large size and versatile uses. Initially green, they ripen to a vibrant yellow, offering a sweet flavor ideal for various culinary applications. Enjoy them raw, use them in baking, fruit salads, or desserts, and incorporate them into fruit compotes or as a complement to other dishes. Their size and flavor make Robusta bananas a favorite for both everyday enjoyment and creative cooking."
      },
      {
        heading:"Lal Velchi",
        description:"Red bananas, or Lal Velchi, are a cherished variety in Kerala and Tamil Nadu. Known for their sweet flavor and striking orange-yellow color, these bananas also emit a delightful aroma. Commercially cultivated primarily in Kanyakumari and Tirunelveli districts of Tamil Nadu, Lal Velchi stands out for its unique taste and appealing appearance, making it a highly prized choice among banana varieties."
      },
      {
        heading:"Safed Velchi",
        description:"Safed Velchi bananas, renowned for their premium quality, are a top choice for table use. Cultivated in Maharashtra's Thane and Nasik districts, this medium-sized variety boasts a slender yellowish-green pseudostem and distinctive reddish petiole margins. With a growing duration of around 13 months, Safed Velchi is celebrated for its appealing appearance and superior flavor, making it a standout among banana varieties for both quality and visual appeal."
      },
      {
        heading:"Rajeli Nendran",
        description:"The Rajeli Nendran variety is notable for its diverse plant characteristics, including plant stature, pseudostem color, and bunch size. These bananas feature a distinctive neck with thick green skin that ripens to a buff yellow. Unlike many other varieties, Rajeli Nendran bananas retain their starchy texture even when fully ripe, making them ideal for various culinary uses. Their unique attributes and robust flavor contribute to their popularity and versatility."
      },
      {
        heading:"Grand Naine",
        description:"Grand Nain bananas, a prominent cultivar of Musa acuminata, are among the most widely cultivated bananas globally. As a key member of the commercial Cavendish group, Grand Nain is celebrated for its consistent quality and widespread appeal. Known for their robust flavor and reliability, these bananas are a staple in both local and international markets, making them a top choice for growers and consumers alike."
      },
      {
        heading:"Poovan",
        description:"Poovan bananas are a leading commercial cultivar widely grown across India, with Tamil Nadu being the primary producer. Cultivated as a perennial crop, Poovan is also used in the leaf industry throughout Tamil Nadu and parts of Kerala. These bananas are known for their slightly acidic taste, firm texture, and distinctive sour-sweet aroma, making them a popular choice for both fresh consumption and various commercial applications."
      },
    ],
    varietyHeading:"Banana Varieties We Export",
    verietyDesc:"As one of the world’s most popular fruits, bananas are exported in large quantities from India each year. Indian banana exporters supply a diverse range of varieties to meet global demand. Key varieties include the Cavendish, known for its sweetness and long shelf life; the Red Banana, which offers a unique flavor and vibrant color; the Plantain, used primarily in cooking for its starchy texture; and the Nendran, cherished for its rich taste and nutritional benefits. Each variety brings its own distinctive qualities to the table, catering to different preferences and culinary uses. With such a rich selection, Indian banana exporters continue to play a vital role in feeding the world’s appetite for this versatile fruit."
  },
  {
    heading: "Indian Mango Exporters",
    picture: mangoBanner,
    link: "Mangoes",
    firstLayout: false,
    description: "Mango, hailed as the 'King of Fruits', holds a special place in India, producing around 15.03 million tons annually and contributing to 40.48% of the global mango supply. The export of Indian mangoes has seen significant growth, with revenue increasing from Rs. 142 crores in 2006-2007 to Rs. 209 crores in 2010-2011—a remarkable rise of 47%. This surge highlights the expanding international demand for Indian mangoes, presenting a valuable opportunity for further growth in the global market. With its rich flavor and superior quality, Indian mangoes are well-positioned to capture even more market share and enhance their presence worldwide.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 99","Dietary Fiber - 2.6 gm","Carbs - 24.7 gm"]
      },
      {
        title:"Protein",
        value:["1.4 gm"]
      },
      {
        title:"Fat",
        value:["0.6 gm"]
      },
      {
        title:"Vitamin",
        value:["Vitamin A and C"]
      },
      {
        title:"Minerals",
        value:["Folate","B6","Iron and a little Calcium","Zinc and Vitamin E"]
      },
    ],
    benifits: [],
    outro: "Mango imports from India have surged dramatically in recent years, reflecting the growing global appreciation for this beloved fruit. JNTB EXPORT IMPORT is a top leader in mango exports, leveraging extensive experience and expertise in the food industry to deliver premium mangoes to every corner of the world. Our commitment to quality and reliability ensures that your mango export process is seamless and efficient. Whether you're looking to expand your international reach or tap into new markets, JNTB EXPORT IMPORT is your ideal partner for exporting mangoes. Trust us to handle your export needs with professionalism and excellence. Contact JNTB EXPORT IMPORT today to start your journey to global success!",
    varieties: [
      {
        heading:"Alphonso",
        description:"Alphonso mangoes, renowned globally for their exceptional quality, are a seasonal delight available from mid-April to June. These mangoes boast a rich, creamy texture and a juicy, non-fibrous pulp. When fully ripe, Alphonso mangoes showcase a vibrant golden-yellow skin with a hint of red. Inside, the flesh gleams with a saffron hue, offering a luxurious taste experience. Alphonso mangoes are celebrated for their sweetness and aromatic flavor, making them a favorite choice among mango enthusiasts."
      },
      {
        heading:"Kesar",
        description:"Kesar mangoes, also known as 'Gir Kesar,' are a prized cultivar grown in the foothills of Girnar in Gujarat. Renowned for their vibrant orange pulp, these mangoes offer a distinctive flavor and aroma. Typically available in the market starting in April, Kesar mango cultivation begins around October, following the monsoon season. Their bright color and exquisite taste make Kesar mangoes a sought-after choice for mango lovers seeking premium quality fruit."
      },
      {
        heading:"Pairi",
        description:"Pairi mangoes are among the finest varieties in India, prized for their exceptional quality. Oval-shaped and weighing between 250 to 450 grams, these mangoes feature a vibrant yellowish-orange flesh. Firm and juicy with a fiberless texture, Pairi mangoes are renowned for their sweet taste and distinct aroma. Their superior flavor and delightful scent make them a standout choice for mango enthusiasts, ensuring a premium fruit experience that captures the essence of Indian mango excellence."
      },
      {
        heading:"Totapuri",
        description:"Totapuri mangoes, predominantly found in Andhra Pradesh, Karnataka, and Tamil Nadu, are notable for their large size and distinct appearance. These oblong mangoes feature a striking golden-yellow color and a characteristic beak-like pointed end. The thick skin can range from green to yellow, offering durability and a unique texture. Renowned for their tangy flavor and robust qualities, Totapuri mangoes stand out as a versatile choice for both eating fresh and using in various culinary dishes."
      },
      {
        heading:"Neelum",
        description:"Neelum mangoes are celebrated for their stunning shape, distinctive taste, and floral aroma. Grown primarily in Andhra Pradesh, Karnataka, and Tamil Nadu, this variety is known for its large size and oblong shape, featuring a pointed base. Neelum mangoes offer a delightful eating experience with their unique flavor and fragrant aroma, making them a favorite among mango enthusiasts. Their impressive appearance and exceptional quality ensure they stand out in both local and international markets."
      },
      {
        heading:"Langra",
        description:"Langra, also known as Banarasi Langra, is a renowned mango variety primarily cultivated in Varanasi, Banaras, and northern India. This distinctive cultivar is known for retaining its greenish hue even as it ripens. Langra mangoes are typically harvested in the latter half of July, offering a unique flavor profile and aroma. Their signature green tinge and delectable taste make Langra a popular choice among mango lovers, celebrated for its exceptional quality and regional significance."
      },
      {
        heading:"Dashehari",
        description:"Dashehari is a standout mango variety in North India, celebrated for its exquisite taste and delightful aroma. Known for its exceptional quality, Dashehari mangoes are so prevalent that they represent 80% of mango varieties in the region. This popular variety is not only a staple in North Indian cuisine but is also genetically linked to a significant portion of the region’s mangoes, underscoring its importance and widespread appeal in the mango industry."
      },
      {
        heading:"Rajapuri",
        description:"Rajapuri mangoes are among the largest varieties in India, known for their impressive size and rounded shape, resembling Kesar but more robust. Their smooth skin displays a vibrant mix of yellow, orange, and red hues. With fruits ranging from 500g to 1kg, Rajapuri mangoes offer a rich, sweet flavor, especially in the smaller sizes. Their exceptional size and delectable taste make them a standout choice for mango lovers seeking both quantity and quality."
      },
      {
        heading:"Himsagar",
        description:"Himsagar mangoes, hailing from West Bengal, are a beloved cultivar known for their rich flavor and smooth texture. This medium-sized mango, weighing between 250 and 350 grams, features a pulp that ranges from yellow to orange and is completely fiberless. Renowned for its sweet, juicy taste and lack of fiber, Himsagar mangoes offer a delightful eating experience, making them a favorite among mango enthusiasts and a prized choice in both local and international markets."
      },
      {
        heading:"Chausa",
        description:"Chausa mangoes, also known as Chaunsa, are a prized cultivar originally from Pakistan and now widely cultivated in both Pakistan and India. Renowned for their golden-yellow color, Chausa mangoes are nearly fiberless and offer a delectable, aromatic flavor. With their sweet taste and pleasant fragrance, Chausa mangoes are celebrated for their superior quality and are a favorite choice among mango connoisseurs looking for an exceptional fruit experience."
      },
    ],
    varietyHeading:"Mango Varieties We Export",
    verietyDesc:"Indian mango exporters offer a rich variety of mangoes to international markets, with key destinations including the UAE, Bangladesh, the UK, Saudi Arabia, and Nepal. Among the most famous varieties exported are Alphonso, known for its sweetness and rich flavor; Kesar, prized for its vibrant color and aromatic taste; Langra, with its unique tangy flavor; Dasheri, celebrated for its juicy texture; and Chausa, renowned for its rich, sweet taste. These varieties continue to captivate global taste buds."
  },
  {
    heading: "Indian Orange Exporters",
    picture: orangeBanner,
    link: "Oranges",
    firstLayout: false,
    description: "Oranges, one of the world's most beloved citrus fruits, are enjoyed raw, in salads, juices, smoothies, and desserts. The global demand for oranges is soaring, prompting Indian orange exporters to ramp up their exports. As the third-largest orange producer globally, India is well-positioned to meet this growing demand. With its vast production capabilities and high-quality fruit, India’s orange export market is experiencing significant growth. This surge presents a tremendous opportunity for expanding Indian oranges into international markets, showcasing their freshness and versatility. Whether you're looking for vibrant, juicy oranges for direct consumption or various culinary uses, Indian oranges stand out for their exceptional quality and flavor.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["60 Calories","3 grams of Fiber","12g/100grams Carbohydrates"]
      },
      {
        title:"Protein",
        value:["1gm of Protein"]
      },
      {
        title:"Minerals",
        value:["Vitamin C, Thiamine, Folate, and Potassium"]
      },
      {
        title:"Vitamin",
        value:["Vitamin C"]
      },
      {
        title:"Fats",
        value:["No Fat or Sodium"]
      },
    ],
    benifits: [],
    outro: "If you're seeking a reliable orange exporter in India, JNTB EXPORT IMPORT is your top choice! With extensive experience in the food industry, we are fully equipped to manage all aspects of orange exports. Our expert team conducts thorough market analysis and assists in finding buyers to ensure successful transactions. We hold various certifications and guarantee that only premium quality oranges are exported. Whether you're looking to import oranges from India or expand your market reach, JNTB EXPORT IMPORT offers exceptional service and quality. Contact us today for seamless and efficient orange exports!",
    varieties: [
      {
        heading:"Nagpur Santra",
        description:"Nagpur oranges, also known as Nagpur Santra, are a prized variety cultivated in Nagpur, Maharashtra. Renowned for their sweet, juicy pulp and rustic, pockmarked exterior, these oranges are a highlight of the region, earning Nagpur the nickname ‘Orange City.’ Blossoming during the monsoon season, Nagpur oranges are ready for harvest starting in December. Their distinctive flavor and quality make them a popular choice for both domestic and international markets, showcasing the best of India’s citrus produce."
      },
      {
        heading:"Coorg Santra",
        description:"Coorg Santra, or Coorg Mandarin, hails from Kodagu in Karnataka and was awarded Geographical Indication status in 2006. These oranges, often seen as man-made hybrids of mandarins, are characterized by their greenish-yellow color and tight skin. Known for their unique sweet-sour flavor, Coorg Santra stands out for its distinct taste and quality. This celebrated variety offers a refreshing twist on traditional oranges, making it a favorite among citrus enthusiasts."
      },
      {
        heading:"Dancy",
        description:"Dancy oranges are medium-sized fruits with a broadly depressed apex. Their thin, leathery rind is tough yet easily removable, revealing a smooth, glossy surface that may become bumpy with age. As they mature, Dancy oranges transition from a deep orange-red to a vibrant scarlet hue. This distinctive coloration and texture make Dancy oranges a visually appealing and flavorful choice, combining a striking appearance with a delightful taste."
      },
      {
        heading:"Darjeeling Mandarin",
        description:"The Citrus reticulata Blanco, or Darjeeling Mandarin, is a key cash crop in the Darjeeling Hills, cultivated across approximately 930 hectares. Renowned for its quality, this variety contributes significantly to the region's economy. With an impressive annual production of around 148.224 metric tons, Darjeeling Mandarin oranges are celebrated for their distinct flavor and vibrant appeal, making them a standout choice in the citrus market."
      },
      {
        heading:"Kinnow Mandarin",
        description:"Kinnow Mandarin, pronounced as Kinoo, is a high-yield hybrid of the 'King' and 'Willow Leaf' citrus cultivars. Renowned for its juiciness, Kinnow stands out among oranges for its refreshing flavor. Predominantly grown in Punjab, Himachal Pradesh, Jammu & Kashmir, Rajasthan, and Haryana, this variety offers a vibrant, succulent taste, making it a favorite in the citrus market. Its superior juiciness and widespread cultivation highlight its significance and appeal."
      },
    ],
    varietyHeading:"Orange Varieties We Export",
    verietyDesc:"Indian orange exporters ship to numerous countries worldwide, including the United States, China, Hong Kong, Australia, and Bangladesh. They handle a diverse range of orange varieties to meet global demand. Among the key types of oranges exported are the juicy Navel, tangy Valencia, and aromatic Blood Orange. Each variety offers unique flavors and uses, ensuring that global markets receive top-quality oranges suited to their preferences and needs. Discover the best of Indian oranges for every taste and requirement."
  },
  {
    heading: "Indian Apple Exporters",
    picture: appleBanner,
    link: "Apples",
    firstLayout: false,
    description: "Apples, renowned for their affordability and popularity worldwide, have driven a significant growth in the Indian apple export business over the past decade. With numerous varieties available, the demand for high-quality Indian apples continues to rise. Exporting apples from India offers substantial revenue opportunities for exporters, but it's crucial to maintain strict quality standards and adhere to health regulations to ensure premium products reach global markets. By focusing on excellence and compliance, Indian apple exporters can leverage the growing international demand and enhance their presence in the global fruit trade.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 95","Carbs - 25 gm","Fiber - 4 gm"]
      },
      {
        title:"Minerals",
        value:["Calcium","Copper","Iron","Magnesium","Phosphorous","Potassium","Selenium","Sodium","Zinc"]
      },
      {
        title:"Vitamin",
        value:["Vitamin A, Vitamin B1, Vitamin B2, Vitamin B6, Vitamin C, Folate (Folic Acid)"]
      },
      {
        title:"Protein",
        value:["0.3g/100 gm"]
      },
      {
        title:"Fats",
        value:["0.2g/gm"]
      },
    ],
    benifits: [],
    outro: "Apple imports from India have surged in recent years due to the growing global demand for high-quality apples and apple products. JNTB EXPORT IMPORT stands out as a leading apple importer in India, renowned for its commitment to excellence. We export premium-quality apples, ensuring they meet the stringent health and quality standards required by international markets. Our dedicated team manages every step of the export process, from selection to delivery, guaranteeing a seamless experience. For top-notch apple export services and exceptional quality, JNTB EXPORT IMPORT is your ideal partner. Choose JNTB EXPORT IMPORT for reliable and superior apple exports from India!",
    varieties: [
      {
        heading:"Green Apple (Granny Smith)",
        description:"Granny Smith apples, also known as Green apples, feature bright green skin often dotted with faint white specks. Medium to large and round, these apples are known for their firm, juicy texture and thick skin. The crisp, bright white flesh offers a tart, acidic flavor with a subtle sweetness. Ideal for both snacking and baking, Granny Smith apples stand out for their refreshing taste and satisfying crunch."
      },
      {
        heading:"Pink Lady Apple",
        description:"The Pink Lady Apple is celebrated for its crisp bite and lively finish, boasting high sugars and acids. Its flavor leans more towards tartness than sweetness, complemented by a bright white, juicy flesh. This apple is not only refreshingly versatile but also perfect for pre-packaged slices. Ideal for baking, snacking, and making sauces, Pink Lady apples bring a delightful crunch and vibrant flavor to a variety of dishes, making them a popular choice among fruit enthusiasts."
      },
      {
        heading:"Fuji Apple",
        description:"Fuji Apples, among the world's favorites, feature a distinctive pink hue with yellow-green undertones. Renowned for their crisp and juicy texture, Fuji apples offer a refreshingly sweet flavor with a subtle, dull white flesh. Their balanced sweetness and satisfying crunch make them a delightful choice for snacking or adding a touch of freshness to various dishes. Whether enjoyed raw or incorporated into recipes, Fuji apples consistently deliver a burst of sweetness and crispness."
      },
      {
        heading:"Gala Apple",
        description:"Gala Apples are one of the most widely cultivated apple varieties globally, prized for their versatility in both temperate and warm climates. These apples feature a light-colored base with vibrant orange streaks, deepening to a rich red as they mature. Their distinctive color and sweet flavor make them a popular choice for snacking and cooking. With their adaptability and appealing appearance, Gala apples consistently deliver quality and taste, no matter where they're grown."
      },
      {
        heading:"Honey Crisp Apple",
        description:"Honeycrisp apples are a modern favorite, known for their vibrant appearance and exceptional flavor. These apples range from medium to large in size, featuring a light green or yellow background adorned with a striking red-orange blush and a hint of pink. With their crisp texture and juicy sweetness, Honeycrisp apples offer a refreshing and enjoyable eating experience. Perfect for snacking or adding to recipes, they’ve become a supermarket staple thanks to their eye-catching color and delicious taste."
      },
      {
        heading:"Envy Apple",
        description:"Envy apples, a trademarked brand of the Scilate variety, are a delightful cross between Royal Gala and Braeburn. Developed by HortResearch in New Zealand, Envy apples feature a striking red skin with yellow specks. Their thick, tough peel encases a pale yellow flesh that’s both crisp and sweet. Renowned for their exceptional flavor and texture, Envy apples offer a perfect blend of tartness and sweetness, making them a popular choice for snacking and culinary creations."
      },
      {
        heading:"Red Apple",
        description:"Red apples, true to their name, are vibrant red and widely loved across the globe. Known for their mildly sweet flavor and juicy crunch, they offer a refreshing snack option. Their skin can be a bit tough, but the crisp, lightly sweet flesh inside makes them a perfect choice for a quick, satisfying treat. Whether enjoyed fresh or in a variety of dishes, Red apples stand out for their classic taste and versatility."
      },
    ],
    varietyHeading:"Apple Varieties We Export",
    verietyDesc:"Indian apple exporters offer a diverse range of apple varieties, including the crisp Green Apple, the sweet and tangy Pink Lady, and the juicy Fuji Apple. These popular varieties showcase the rich flavors and high quality of Indian apples. Each type brings its own unique taste and texture, catering to various preferences and markets worldwide. With such a wide selection, Indian apple exporters are well-equipped to meet global demand and deliver exceptional fruit to international customers."
  },
  {
    heading: "Indian Guava Exporters",
    picture: guavaBanner,
    link: "Guavas",
    firstLayout: false,
    description: "Guava, an evergreen shrub celebrated for its delectable fruit, is increasingly sought after worldwide. Indian guava exporters are meeting this demand by shipping tonnes of guavas across the globe each year. The rapid growth in guava export from India highlights the fruit's rising popularity and offers a lucrative opportunity for local farmers and vendors. With its versatility in consumption and numerous health benefits, guava has carved out a significant niche in the international market. For those looking to capitalize on the expanding global demand, guava export from India presents a promising business prospect. Embrace the growth of the guava market and seize the opportunity to connect with consumers worldwide!",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 68 calories","Dietary Fiber - 5 g","Total Carbohydrate - 14 g"]
      },
      {
        title:"Protein",
        value:["2.6g"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin C","Vitamin B-6"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium"]
      },
      {
        title:"Fat",
        value:[]
      },
    ],
    benifits: [],
    outro: "JNTB EXPORT IMPORT stands out as one of the leading guava exporters from India, dedicated to making the export process seamless for you. Understanding that navigating exports can be complex, our expert team at JNTB EXPORT IMPORT is here to guide you through every stage—from meticulous documentation to the final delivery. Our extensive global network and deep market knowledge have consistently benefited our clients, ensuring smooth transactions and successful outcomes. With our commitment to quality and support, we make guava import from India hassle-free. If you’re looking to explore guava export opportunities or need assistance with import, JNTB EXPORT IMPORT is your trusted partner. Contact us today and let us help you connect with the global market!",
    varieties: [
      {
        heading:"L-49 (Lucknow-49)",
        description:"The L-49 variety of guava is renowned for its prolific yield and delightful taste. Featuring a greenish-yellow skin, this variety boasts a sweet, milky white pulp and a thick shell. The seeds are relatively few and soft, enhancing its appeal. Popular in Maharashtra and Andhra Pradesh, the L-49 guava produces approximately 25 tons per hectare. Its exceptional flavor and high yield make it a favored choice among growers and consumers alike."
      },
      {
        heading:"Allahabad Safeda",
        description:"The Allahabad Safeda is a renowned guava variety from Uttar Pradesh, cherished for its table-worthy quality. Medium-sized and round, this guava features a white, flavorful flesh with minimal seeds. Known for its excellent keeping quality, the Allahabad Safeda is a favorite among consumers for its taste and durability. Its consistent texture and pleasant flavor make it a popular choice for fresh consumption and a standout in the guava market."
      },
      {
        heading:"Banarasi",
        description:"The Banarasi guava variety, known for its broad crown and height of 4 to 5.5 meters, produces round, light yellow fruits. Ideal for table use, this guava variety is celebrated for its appealing color and sweet flavor. Its robust growth and attractive fruit make it a popular choice for fresh consumption, adding a touch of quality to any fruit selection. The Banarasi guava's vibrant hue and delectable taste enhance its appeal in markets and kitchens alike."
      },
      {
        heading:"Chittidar",
        description:"The Chittidar guava, akin to the Safeda variety, stands out with its distinctive pinkish-red pinhead-sized dots on the fruit's surface. This visual difference makes it unique, while it shares the same sweet, creamy pulp characteristics as Safeda. The vibrant speckles add an attractive touch, making the Chittidar variety a visually appealing and delicious choice for both consumers and exporters. Its appealing appearance and flavorful flesh ensure it remains a sought-after variety in the guava market."
      },
      {
        heading:"Harijha",
        description:"The Harijha guava variety grows to a height of 3.5 to 4.5 meters and features a sparsely branched plant. Its fruits are round, greenish-yellow, and known for their sweet, succulent flavor. This variety's attractive size and taste make it a popular choice among consumers and a valuable export option. The Harijha guava stands out for its delightful sweetness and vibrant color, contributing to its growing demand in both local and international markets."
      },
      {
        heading:"Red Fleshed",
        description:"The Red Fleshed guava tree typically reaches heights of 3 to 5 meters, boasting spreading branches adorned with roundish, oval fruits. These guavas feature a vibrant yellow skin and striking pink flesh inside. Known for their visually appealing color contrast and delightful flavor, they are a standout choice for both local markets and international exports. The Red Fleshed guava's unique appearance and sweet taste make it a sought-after variety, enhancing its appeal to fruit lovers everywhere."
      },
      {
        heading:"Arka Mridula",
        description:"Arka Mridula, derived from Allahabad Safeda seedlings, showcases semi-tall, spreading plants. The guavas are round with smooth, yellow skin and crisp, white flesh. This variety is celebrated for its attractive appearance and excellent flavor, making it a popular choice for both local consumption and international export. Its smooth skin and sweet, juicy interior highlight its premium quality, ensuring it stands out in the global market."
      },
    ],
    varietyHeading:"Guava Varieties We Export",
    verietyDesc:"Guava exporters from India are renowned for delivering premium quality guavas worldwide. As global demand rises, Indian guava export has flourished, reflecting the superior quality of the fruits. The export includes several major varieties, each celebrated for its unique flavor and texture. These include the popular Allahabad Safeda, known for its sweet taste and smooth texture, and the vibrant and aromatic Pink Guava. Indian guavas are consistently in high demand for their exceptional quality and rich flavor."
  },
  {
    heading: "Indian Papaya Exporters",
    picture: papayaBanner,
    link: "Papayas",
    firstLayout: false,
    description: "Papaya, with its vibrant orange, sweet, and juicy pulp, is a tropical delight enjoyed worldwide. Indian papaya exporters ship tons of this fruit abroad each year, reflecting its rising global popularity. As demand for papaya grows, Indian papaya export has surged, offering significant opportunities for local farmers and vendors to expand their reach into the international market. The increase in papaya export not only boosts local economies but also introduces this nutritious fruit to new audiences globally. If you’re looking to tap into the growing papaya market, India is the place to source premium quality papayas for your business!",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 59 calories","Fiber - 3 g","Total Carbohydrate - 15 g"]
      },
      {
        title:"Protein",
        value:["1g"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin C"]
      },
      {
        title:"Minerals",
        value:["Folate","Potassium"]
      },
      {
        title:"Fat",
        value:[]
      },
    ],
    benifits: [],
    outro: "JNTB EXPORT IMPORT stands out as the leading papaya exporter from India, simplifying the complex process of international trade. Exporting can be challenging, but with JNTB EXPORT IMPORT, you're in capable hands. We bring extensive experience in the food export industry and a vast network of global connections. Our dedicated team will guide you through every step—from handling documentation and paperwork to ensuring timely delivery of your order. With JNTB EXPORT IMPORT, you get not just a product but a partner committed to making your papaya import seamless and efficient. Ready to expand your horizons with premium Indian papayas? Contact JNTB EXPORT IMPORT today!",
    varieties: [
      {
        heading:"Coorg Honey Dew",
        description:"The Coorg Honey Dew papaya is prized for both table use and processing. This variety produces greenish-yellow, oblong fruits with vibrant orange, thick flesh and a delightful flavor. Known for its excellent quality, Coorg Honey Dew papayas stand out in the market, making them a valuable export. The rich taste and appealing appearance contribute to their high market value, ensuring they are a top choice for consumers and vendors alike."
      },
      {
        heading:"Pusa Dwarf",
        description:"The Pusa Dwarf papaya is known for its compact stature and resilience. With medium-sized oval fruits, this dioecious variety starts bearing from 25 to 30 cm above ground, making it ideal for high-density planting. Its drought-hardiness adds to its appeal, ensuring robust growth even in less favorable conditions. Perfect for space-efficient cultivation, Pusa Dwarf offers both reliability and productivity for papaya growers looking to maximize their yield."
      },
      {
        heading:"Pusa Giant",
        description:"The Pusa Giant papaya is renowned for its vigorous and sturdy plants, which can withstand strong winds. This dioecious variety produces large, robust fruits, making it a prime choice for the canning industry. With its resilience and substantial fruit size, Pusa Giant stands out as a top pick for both commercial cultivation and processing. Its ability to endure challenging conditions while delivering quality fruit makes it a valuable asset for growers and manufacturers alike."
      },
      {
        heading:"Pusa Majesty",
        description:"The Pusa Majesty papaya variety is known for its resilience against viral diseases and root-knot nematodes. It produces medium-sized, round fruits with excellent keeping quality. This variety starts bearing fruit approximately 146 days after transplanting, making it a reliable choice for growers seeking durability and consistent yields. Its robust nature and superior fruit longevity make Pusa Majesty a standout option in papaya cultivation, ensuring both high-quality produce and disease resistance."
      },
      {
        heading:"Pusa Delicious",
        description:"The Pusa Delicious variety is a gynodioecious papaya with medium-tall plants that begin yielding around 8 months after planting. It produces medium-sized fruits with vibrant deep orange flesh and a superb flavor, making it ideal for table use. Known for its excellent taste and high quality, Pusa Delicious is a preferred choice for both commercial cultivation and personal enjoyment, offering a delightful addition to any fruit basket."
      },
      {
        heading:"Pusa Dwarf",
        description:"The Pusa Dwarf papaya variety stands out for its medium-sized, oval fruits and suitability for high-density planting. With its compact plant structure, Pusa Dwarf delivers reliable yields and fits well in space-constrained gardens. This variety is both productive and efficient, making it a popular choice for commercial growers and home gardeners alike. Its robust nature and optimal fruit size ensure it meets the demands of both fresh consumption and processing."
      },
      {
        heading:"CO.1",
        description:"The Co.1 papaya variety, developed from the Ranchi cultivar by TNAU, Coimbatore, features medium-sized, spherical fruits with a smooth, greenish-yellow skin. Inside, the vibrant orange-yellow flesh is both soft and firm, offering a moderate juiciness and excellent keeping quality. This variety is known for its attractive appearance and reliable storage, making it a favored choice for both fresh consumption and extended shelf life."
      },
      {
        heading:"Washington",
        description:"The Washington papaya variety is prized for its table use, featuring round, medium-large fruits with minimal seeds. When ripe, its skin turns a vibrant yellow, enhancing its appeal. Typically weighing between 1.5 to 2 kg, this variety is known for its bright color and juicy texture. Unique to Washington papayas, male and female plants are separate, ensuring consistent fruit quality. Ideal for fresh consumption, this variety combines taste and visual appeal perfectly."
      },
      {
        heading:"Solo",
        description:"The Solo papaya variety is ideal for table use, known for its small, vibrant fruits with deep pink pulp and a delightful sweetness. This variety is perfect for kitchen gardens, offering both charm and taste. Its compact size makes it suitable for home cultivation, and the rich flavor enhances any dish. Whether enjoyed fresh or incorporated into recipes, Solo papayas bring a burst of color and sweetness to your culinary creations."
      },
      {
        heading:"Taiwan-786",
        description:"The Taiwan-786 is a versatile gynodioecious papaya variety, perfect for both table use and processing. It produces oblong fruits with a sweet, flavorful pulp and minimal seeds. The plants begin fruiting from 100 cm above ground, yielding fruits that weigh between 1-3 kg. Known for its excellent keeping quality, Taiwan-786 offers a delightful taste and longevity, making it a valuable addition to any orchard or garden."
      },
      {
        heading:"IIHR39 and IIHR54",
        description:"Developed at IIHR Bangalore, the IIHR39 and IIHR54 papaya varieties are known for their medium-sized, sweet fruits. With a high total soluble solids (TSS) content of 14.5° Brix, these papayas offer exceptional flavor and sweetness. Additionally, they boast an impressive shelf life, making them ideal for both fresh consumption and longer storage. These varieties represent the pinnacle of quality and longevity in papaya cultivation."
      },
    ],
    varietyHeading:"Papaya Varieties We Export",
    verietyDesc:"Papaya exporters in India ship a range of premium varieties globally, including Coorg Honey Dew, Pusa Dwarf, Pusa Giant, and Pusa Majesty, among others. Each variety is known for its unique flavor and quality, ensuring top-notch products reach international markets. The rise in papaya exports from India underscores the fruit's global appeal and the country's commitment to delivering only the best. Discover the rich taste and freshness of Indian papayas through these leading varieties."
  },
  {
    heading: "Indian Pomegranate Exporters",
    picture: pomegranateBanner,
    link: "Pomegranates",
    firstLayout: false,
    description: "Pomegranates are cherished worldwide for their sweet-tart flavor and vibrant seeds. Renowned for their health benefits and unique taste, this fruit is a staple in many cuisines. Indian pomegranate exporters are meeting the growing global demand by shipping tons of these juicy delights annually. The surge in international interest has significantly boosted pomegranate exports from India, showcasing the country’s ability to supply high-quality fruit. This expansion not only benefits local farmers but also opens new markets, making Indian pomegranates a sought-after commodity across the globe.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 83 calories","Fiber - 7 g","Total Carbohydrate 19 g"]
      },
      {
        title:"Protein",
        value:["1.7g"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin C","Vitamin D","Vitamin B-6"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Cobalamin","Magnesium"]
      },
      {
        title:"Fat",
        value:[]
      },
    ],
    benifits: [],
    outro: "Looking to import pomegranates from India? JNTB EXPORT IMPORT is your ideal partner! We streamline the entire export process to ensure a smooth experience for you. With our extensive global network and deep expertise in food exports, we guide you through every step—from meticulous documentation to final delivery. As one of India's top pomegranate exporters, JNTB EXPORT IMPORT is committed to providing premium quality fruit and exceptional service. Our dedicated team is here to assist you and make sure your pomegranate imports are handled with the utmost efficiency. Choose JNTB EXPORT IMPORT for reliable, top-notch service in the world of pomegranate exports!",
    varieties: [
      {
        heading:"Alandi or Vadki",
        description:"The Alandi variety, also known as Vadki, is renowned for its medium-sized, vibrant blood-red or deep pink fruit. This pomegranate variety offers a delightful blend of sweetness with a hint of acidity, complemented by its hard seeds. Its appealing color and rich flavor make it a popular choice for both local consumption and international export. The Alandi pomegranate stands out for its unique taste and striking appearance, making it a favorite among pomegranate enthusiasts."
      },
      {
        heading:"Dholka",
        description:"The Dholka variety of pomegranates, a prized cultivar from Gujarat, is well-regarded for its large, eye-catching fruits. With a rind that ranges from yellowish-red to a vibrant hue and pinkish-white arils, Dholka pomegranates offer a visually appealing and flavorful experience. Their impressive size and distinct coloration make them a sought-after choice for export, reflecting the rich agricultural heritage of Gujarat. This variety is a standout in the global pomegranate market for its quality and taste."
      },
      {
        heading:"Kandhari",
        description:"Kandhari pomegranates are a premium export from India, known for their large size and striking deep red rind. These fruits offer a delightful balance of sweetness with a hint of acidity, complemented by their hard seeds. The vibrant color and robust flavor make Kandhari pomegranates a top choice in international markets, showcasing the exceptional quality and appeal of Indian-grown produce. Perfect for those seeking a rich and flavorful pomegranate experience."
      },
      {
        heading:"Kabul",
        description:"Kabul pomegranates, a sought-after export from India, feature large fruits with a deep red rind and contrasting pale yellow undertones. Inside, the dark red arils offer a unique flavor profile, with the juice having a slightly bitter edge that sets it apart. This variety’s distinctive taste and striking appearance make it a popular choice in global markets, highlighting the diverse offerings of Indian pomegranate cultivars. Ideal for those seeking a unique twist on traditional pomegranate flavors."
      },
      {
        heading:"Muskat",
        description:"Muskat pomegranates, a prized export from India, are known for their vibrant red rind and pink arils. Each fruit typically weighs between 300-350 grams, offering a perfect balance of sweet and tangy flavors. With its striking appearance and juicy, flavorful arils, the Muskat variety stands out in the global market, making it a favorite among international buyers looking for premium pomegranates."
      },
      {
        heading:"Paper Shelled",
        description:"The Paper Shelled pomegranate, a sought-after export from India, is known for its medium size and high-quality pink arils. This variety stands out for its soft seeds, which enhance the fruit's appeal. With its vibrant color and tender texture, the Paper Shelled pomegranate delivers a delightful eating experience, making it a favorite among international markets. This pomegranate variety combines excellent taste with a premium quality that meets global standards."
      },
      {
        heading:"Spanish Ruby",
        description:"The Spanish Ruby pomegranate, a prized export from India, is known for its small to medium size. This variety features small, soft seeds, making it a favored choice for those seeking a tender and enjoyable fruit. With its appealing texture and vibrant color, the Spanish Ruby delivers a burst of flavor in every bite. Its high quality and unique characteristics ensure it stands out in international markets, reflecting the excellence of Indian pomegranate cultivation."
      },
    ],
    varietyHeading:"Pomegranate Varieties We Export",
    verietyDesc:"Indian pomegranate exporters offer a range of premium varieties including Alandi, Dholka, Kandhari, and Kabul. With their rising popularity for both health benefits and medicinal uses, the export of pomegranates from India has become a lucrative business opportunity. These varieties are known for their rich flavor, vibrant color, and exceptional quality. As demand grows, these top pomegranate types continue to make a significant impact in international markets."
  },
  {
    heading: "Indian Green Cardamom Exporters",
    picture: cardamonBanner,
    link: "Cardamom",
    firstLayout: true,
    description: "Green Cardamom, with its intense, slightly sweet flavor reminiscent of mint, is a versatile spice used in both sweet and savory dishes worldwide. Although it originated in India, its global popularity has surged, making it a highly sought-after export. Indian Green Cardamom exporters are capitalizing on this demand, shipping various premium varieties across the globe. The Green Cardamom export market from India has seen consistent growth, presenting a lucrative opportunity for exporters. As global cuisines increasingly incorporate this aromatic spice, the Indian export industry is thriving, making Green Cardamom a key player in international spice markets.",
    marketDemand: "India’s Green Cardamom exporters ship between 500 to 700 metric tons annually to a wide array of countries, including Bangladesh, Pakistan, Syria, Egypt, Jordan, Turkey, Iran, Sudan, and many regions across North Africa and Europe. The demand for Green Cardamom from India has been steadily increasing, driven by its premium quality and diverse varieties. This aromatic spice is cherished worldwide, adding a distinctive flavor to numerous dishes across different cuisines. As the global appetite for Green Cardamom continues to grow, Indian exporters are seizing the opportunity to expand their reach, making India a leading source for this prized spice in international markets.",
    specifications: [
      {
        title:"Product Name",
        value:"Green Cardamom"
      },
      {
        title:"Origin",
        value:"India"
      },
      {
        title:"Family",
        value:"Zingiberaceae"
      },
      {
        title:"Binomial name",
        value:"Elettaria cardamomum"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 311 kcal","Total Carbohydrates 68g","Dietary Fiber 28g"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium"]
      },
      {
        title:"Vitamins",
        value:["Vitamin B-6","Vitamin C"]
      },
      {
        title:"Protein",
        value:["11 g"]
      },
      {
        title:"Fats",
        value:["Fat 7g"]
      },
    ],
    benifits: ["Cardamom may aid in lowering blood pressure, thanks to its powerful antioxidant and diuretic properties. This aromatic spice not only enhances flavor but also offers potential health benefits, making it a valuable addition to any diet.","Cardamom is rich in antioxidants, which may protect cells from damage and help prevent inflammation in the body. These potent compounds contribute to cardamom’s reputation as a flavorful spice with potential health benefits beyond the kitchen.","Cardamom is a natural remedy for bad breath, often found in chewing gums. Its ability to kill common mouth bacteria and prevent cavities makes it more than just a spice—it's a refreshing way to maintain oral health.","Cardamom's essential oils and extracts are believed to combat bacterial strains linked to fungal infections, food poisoning, and stomach issues. While promising, further research is needed to fully understand its potential in treating these health concerns."],
    outro: "JNTB EXPORT IMPORT is the leading Green Cardamom importer from India, committed to making the export process smooth and hassle-free. We understand the complexities involved and have a team of trained executives ready to guide you through every step, from documentation to final delivery. As one of the most reliable exporters in the industry, we prioritize maintaining strict health and safety standards, ensuring that only the best quality products reach international markets. If you’re looking to export Green Cardamom from India, JNTB EXPORT IMPORT is your trusted partner. Contact us today to experience seamless and efficient service!",
    varieties: [],
  },
  {
    heading: "Indian Chilli Pepper Exporters",
    picture: chillieBanner,
    link: "Chillie",
    firstLayout: true,
    description: "Chilli Pepper is a cornerstone spice in Indian cuisine and enjoys widespread popularity globally. Indian Chillies, known for their vibrant flavor and heat, are in high demand across international markets. Each year, Indian Chilli Pepper exporters ship tonnes of this fiery spice abroad, capitalizing on its diverse culinary applications. The growing global appetite for Chilli Pepper has driven a significant rise in exports from India, creating lucrative opportunities for exporters to expand their business and tap into new markets. With a variety of Chilli Pepper types available, Indian exporters are well-positioned to cater to the diverse tastes and preferences of consumers worldwide.",
    marketDemand: "India is a major producer of Chilli Pepper, with red chillies cultivated across 792,000 hectares in states like Andhra Pradesh, Karnataka, Madhya Pradesh, and Maharashtra. Annually, India produces approximately 1.38 million tons of red chillies, with 70% consumed domestically. The remaining 30% is exported by Indian Chilli Pepper exporters, meeting the growing international demand. The premium quality of Indian chillies has led to a steady increase in Chilli Pepper imports from India, as global markets seek out these vibrant and flavorful spices. For Indian exporters, this trend presents a significant opportunity to further establish their presence in the global spice trade, delivering top-quality chillies to kitchens around the world.",
    specifications: [
      {
        title:"Product Name",
        value:"Chilli Pepper"
      },
      {
        title:"Origin",
        value:"Mexico"
      },
      {
        title:"Family",
        value:"Solanaceae"
      },
      {
        title:"Binomial name",
        value:"Capsicum annuum"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 40 kcal","Total Carbohydrates - 9 g","Dietary Fiber - 1.5 g"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin C","Vitamin B-6"]
      },
      {
        title:"Protein",
        value:["2 g"]
      },
      {
        title:"Fat",
        value:["0.2 g"]
      },
    ],
    benifits: ["Chilli Peppers can be enjoyed fresh as a vegetable or dried and ground into chilli powder, a versatile spice that adds heat and flavor to countless dishes. Their adaptability makes them a staple in kitchens worldwide.","Capsaicin, the compound that gives chilli peppers their heat, is also used therapeutically. It features in topical ointments, nasal sprays, and dermal patches to provide effective pain relief, showcasing its dual role in both cuisine and medicine.","Chillies add a kick to cuisines globally, from the spicy dishes of Indian cuisine to the bold flavors of Chinese and Italian fare. Their versatility makes them a beloved ingredient in kitchens around the world."],
    outro: "JNTB EXPORT IMPORT is your premier Chilli Pepper importer from India, renowned for making the export process seamless and stress-free. With extensive experience in the food export industry, we handle every aspect of Chilli Pepper export with precision. From locating buyers to ensuring the smooth delivery of your order, our dedicated team supports you every step of the way. Our commitment to quality and efficiency means you can trust us to manage your Chilli Pepper exports effortlessly. If you’re seeking reliable Chilli Pepper export services from India, JNTB EXPORT IMPORT is the partner you need. Contact us today to experience top-notch service and expertise!",
    varieties: [],
  },
  {
    heading: "Indian Black Pepper Exporter",
    picture: pepperBanner,
    link: "Pepper",
    firstLayout: true,
    description: "Black Pepper, one of the world's most popular spices, originated in South India and is now cultivated in tropical regions globally. Indian Black Pepper exporters play a vital role in this industry, shipping tonnes of pepper abroad each year. Last year alone, Black Pepper exports from India reached approximately 17,600 metric tonnes, highlighting the spice’s significant international demand. This thriving market presents a prime opportunity for Indian exporters to expand their reach and excel in the global arena. With its robust flavor and versatility, Black Pepper remains a key player in international spice trade, offering lucrative prospects for exporters looking to make their mark worldwide.",
    marketDemand: "Black Pepper, renowned for its distinctive flavor and aroma, enhances dishes across global cuisines and offers numerous health benefits. Beyond its culinary appeal, this spice is valued for its medicinal properties. Indian Black Pepper exporters deliver premium quality pepper to countries like the US, Sweden, the UK, Italy, Canada, and beyond. At JNTB EXPORT IMPORT, we specialize in facilitating Black Pepper imports from India, ensuring you receive top-grade products with seamless service. Whether you're seeking to enrich your culinary creations or explore the health benefits of Black Pepper, JNTB EXPORT IMPORT is your trusted partner. Contact us today to experience the finest Black Pepper from India and elevate your offerings.",
    specifications: [
      {
        title:"Product Name",
        value:"Black pepper"
      },
      {
        title:"Origin",
        value:"Kerala in South India"
      },
      {
        title:"Family",
        value:"Piperaceae"
      },
      {
        title:"Binomial name",
        value:"Piper nigrum"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 251","Carbohydrates 64 g","Fiber 25g"]
      },
      {
        title:"Minerals",
        value:["Potassium","Manganese","Iron","Zinc","Magnesium","Calcium"]
      },
      {
        title:"Vitamins",
        value:["Vitamin K"]
      },
      {
        title:"Protein",
        value:["10 g"]
      },
      {
        title:"Fats",
        value:["3.3g"]
      },
    ],
    benifits: ["Black Pepper enhances global cuisines with its delightful aroma and rich flavor. When used expertly, it adds a unique kick and depth to various dishes, making it a cherished ingredient in kitchens around the world.","Black pepper is sometimes applied to the skin to address issues like measles, nerve pain, itchy skin from mites, and insect bites. Its potent properties may provide relief, showcasing its versatility beyond the kitchen.","Inhaling black pepper oil is believed to aid in quitting smoking and curbing cravings, as well as easing trouble swallowing. Its potent properties offer a unique support tool for overcoming these challenges, highlighting its versatile health benefits.","Black pepper boosts digestion, alleviates coughs and colds, and may enhance skin health. Additionally, it’s believed to support weight loss efforts, making it a versatile spice with a range of health benefits beyond its culinary uses."],
    outro: "JNTB EXPORT IMPORT stands out as a top Black Pepper importer from India, with extensive experience in the export industry. Our expertise ensures a seamless process for our clients, from identifying buyers to final product delivery. We handle every detail with care, ensuring no hurdles in the export of Black Pepper. Our dedicated team is committed to providing exceptional support, making your experience smooth and efficient. Whether you're seeking to export Black Pepper from India or need assistance navigating the process, JNTB EXPORT IMPORT is here to help. Drop us a message today, and our knowledgeable executives will assist you every step of the way!",
    varieties: [],
  },
  {
    heading: "Indian Ginger Exporters",
    picture: gingerBanner,
    link: "Ginger",
    firstLayout: false,
    description: "Ginger, one of the earliest known oriental spices, plays a vital role in Indian cuisine and is celebrated globally for its distinctive flavor. This tropical spice thrives in the Indo-Malaysian region, where it is abundantly cultivated. Over recent years, Ginger exports from India have surged, reflecting its growing international demand. Indian exporters must adhere to stringent health and quality standards to ensure premium product delivery. The expanding market for Ginger has turned it into a lucrative revenue stream for exporters. With its versatile use in culinary and medicinal applications, Ginger continues to be a valuable commodity, offering significant opportunities for growth and profit in the global market.",
    marketDemand: "",
    specifications: [],
    ingredients: [],
    benifits: [],
    outro: "India is not only a top global producer of ginger but also a leading exporter, with over 50% of its production coming from the North East, Uttarakhand, and Sikkim regions. If you're looking to export ginger, JNTB EXPORT IMPORT stands out as a leading exporter in India. Our extensive experience and expertise ensure that your ginger is handled with the utmost care and delivered safely to destinations around the world. We adhere to rigorous quality and health standards to guarantee the best product for international markets. Trust JNTB EXPORT IMPORT to manage your ginger export needs seamlessly, leveraging our proven track record to help you succeed in the global marketplace.",
    varieties: [
      {
        heading:"Rio-de-Janeiro",
        description:"Rio-de-Janeiro is a standout variety of ginger, easily recognized by its bold-sized rhizomes and buff-colored skin. Renowned for its strong pungency and rich flavor, this variety is less fibrous compared to others. Its distinct characteristics make Rio-de-Janeiro a popular choice for those seeking high-quality ginger with a robust taste, enhancing dishes with its exceptional flavor profile."
      },
      {
        heading:"Thingpui",
        description:"Thingpui is a notable variety of ginger grown in India, distinguished by its medium-sized rhizomes and buff-colored skin. This variety is known for its pungency and high flavor profile. Thingpui’s less fibrous texture enhances its culinary versatility, making it a preferred choice for those seeking a robust ginger with a rich taste. Its unique characteristics ensure that it stands out in both flavor and quality, adding a distinct kick to any dish."
      },
      {
        heading:"Wynad",
        description:"Wynad is a prominent variety of ginger, celebrated for its bold-sized rhizomes and buff-colored skin. Known for its pungent flavor and less fibrous texture, Wynad stands out among ginger varieties. Its robust aroma and smooth consistency make it a favored choice in both culinary and medicinal applications, enhancing dishes with its rich, spicy kick. This variety continues to be a key player in the global ginger market, prized for its distinctive quality and flavor."
      },
      {
        heading:"Maran",
        description:"Maran is a distinguished variety of ginger known for its pungent kick and intense flavor. Featuring medium-sized rhizomes with a buff-colored skin, Maran stands out for its less fibrous texture. This variety combines rich taste with smooth consistency, making it an excellent choice for enhancing the flavor profile of dishes. Its unique qualities ensure Maran's popularity in both culinary and medicinal uses, offering a perfect blend of spice and subtlety."
      },
      {
        heading:"Nadia",
        description:"Nadia is a unique variety of ginger, distinguished by its yellowish hue and medium to bold-sized rhizomes. It offers a moderately pungent and mild flavor, making it a versatile choice for various dishes. Nadia’s less fibrous texture sets it apart from other ginger varieties, ensuring a smoother, more refined taste. Its balanced flavor profile and quality make it a preferred option for those seeking both subtlety and richness in their culinary creations."
      },
      {
        heading:"IISR-Rejatha",
        description:"Released in 2001, this ginger variety is notable for its lumpy, round shape and bold rhizomes that form three-layered compact clumps. It features low fiber content and is rich in essential oils and oleoresin, enhancing both flavor and aroma. This unique combination makes it a standout choice for those seeking high-quality ginger with concentrated flavor and therapeutic properties. Its distinctive characteristics contribute to its growing popularity in culinary and industrial applications."
      },
      {
        heading:"IISR-Mahima",
        description:"Released in 2001, the IISR-Mahima ginger variety is known for its plump appearance and bold rhizomes. Widely adopted in Kerala, it stands out with its significant fiber content and robust resilience against root-knot nematodes. This combination of traits ensures high productivity and quality, making IISR-Mahima a reliable choice for growers seeking a hardy, high-yielding ginger variety. Its resistance to pests and its robust growth make it a valuable addition to any ginger cultivation."
      },
      {
        heading:"IISR-Varada",
        description:"IISE-Varada is a premium, high-yielding ginger variety known for its plump rhizomes and medium-sized reddish-brown scales. Farmers appreciate its disease tolerance and low fiber content, which contributes to its superior quality. This robust variety not only delivers impressive yields but also offers a smoother texture, making it an excellent choice for both culinary and commercial applications. IISE-Varada combines resilience and quality, ensuring it stands out in the world of ginger cultivation."
      },
    ],
    varietyHeading:"Ginger Varieties We Export",
    verietyDesc:"India, a top global ginger producer, yields approximately 385,330 tons annually. The country's ginger export has surged from 8,333 tons in 2007-08 to 35,616 tons in 2011-12, reflecting significant growth. Indian exporters have enhanced product quality and expanded their market reach, delivering more ginger each year to meet rising global demand. This upward trend highlights the growing reputation of Indian ginger in international markets."
  },
  {
    heading: "Turmeric",
    picture: turmericBanner,
    link: "Turmeric",
    firstLayout: true,
    description: "Turmeric, derived from the dried root of the *Curcuma longa* plant, belongs to the ginger family and is native to the Indian subcontinent and Southeast Asia. This perennial, rhizomatous plant thrives in temperatures between 20-30°C and requires substantial annual rainfall. Each year, the rhizomes are harvested, either used fresh or boiled in water and dried. The dried rhizomes are then ground into a vibrant orange-yellow powder, renowned for its distinct color and flavor. Turmeric's rich history and versatile uses make it a staple in both culinary and medicinal applications, celebrated for its health benefits and culinary charm across the globe.",
    marketDemand: "Turmeric, native to Asia, was originally valued for its use as a dye. Its medicinal properties were discovered later, leading to its incorporation into Ayurvedic practices, traditional Chinese medicine, and various other healing traditions. India, with its diverse climate and soil conditions, boasts the largest number of turmeric species, while Thailand ranks a close second in production and variety. The vibrant yellow spice has evolved from its ancient dyeing roots to become a cornerstone of health and wellness, revered for its anti-inflammatory and antioxidant benefits. Its global prominence continues to grow, driven by its rich history and significant role in traditional medicine.",
    specifications: [
      {
        title:"Product Name",
        value:"Turmeric"
      },
      {
        title:"Origin",
        value:"Indian sub-continent and Southeast Asia"
      },
      {
        title:"Family",
        value:"Zingiberaceae"
      },
      {
        title:"Binomial name",
        value:"Curcuma longa"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["29 Calories","2.1 gm fiber","6.31 gm carbohydrates"]
      },
      {
        title:"Minerals",
        value:["Iron","Potassium","Manganese"]
      },
      {
        title:"Vitamins",
        value:["Vitamin C"]
      },
      {
        title:"Protein",
        value:["0.91 gm"]
      },
      {
        title:"Fats",
        value:["0.31 gm"]
      },
    ],
    benifits: ["Curcuma, renowned for its medicinal properties, plays a key role in home remedies and Ayurvedic treatments. Its powerful anti-inflammatory and antioxidant qualities make it a versatile and essential ingredient for natural health solutions.","Turmeric serves as a natural chemical indicator, effectively testing for acidity and alkalinity. Its vibrant color changes in response to pH levels, making it a handy and visually striking tool for assessing chemical environments.","Turmeric, with its active compound curcumin, is explored for its potential in cancer treatment. Its anti-inflammatory and antioxidant properties may aid in combating cancer cells, offering promising support alongside conventional therapies for enhanced health outcomes."],
    outro: "",
    varieties: [],
  },
  {
    heading: "Indian Coriander Seeds Exporters",
    picture: corianderBanner,
    link: "Coriander",
    firstLayout: false,
    description: "Coriander seeds, plump and brown, are prized for their aromatic essential oils that enhance the flavor of various dishes. Indian Coriander Seeds exporters play a significant role in this global market, shipping large quantities each year. The export of Coriander Seeds from India presents a lucrative opportunity for local vendors and farmers to grow their businesses and reach international markets. Indian exporters offer a diverse range of coriander seed varieties to satisfy global demand. With the world’s increasing appetite for flavorful and aromatic spices, coriander seeds are in high demand, making this a promising sector for expansion and growth in the international spice trade.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 3.68 kcal","Carbohydrate 0.587 g"]
      },
      {
        title:"Protein",
        value:["0.341g"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin C","Vitamin K"]
      },
      {
        title:"Minerals",
        value:["Folate","Potassium","Manganese","Choline","Beta-carotene","Beta-cryptoxanthin","Lutein","zeaxanthin"]
      },
      {
        title:"Fat",
        value:[]
      },
    ],
    benifits: [],
    outro: "JNTB EXPORT IMPORT stands out as a leading importer of Coriander Seeds from India, committed to bringing Indian culture, cuisine, and products to the global stage. Coriander Seeds are in high demand worldwide, creating a valuable opportunity for local vendors and farmers to boost their revenue. At JNTB EXPORT IMPORT, we ensure a seamless export process, guiding you through every step from initial arrangements to final delivery. Our extensive experience and dedication to quality make us the ideal partner for navigating the complexities of international trade. If you’re seeking a reliable exporter of Coriander Seeds from India, reach out to JNTB EXPORT IMPORT today, and let us help you expand your market reach effortlessly.",
    varieties: [
      {
        heading:"Sadhana (CS-4)",
        description:"The Sadhana variety of coriander is a versatile, medium-duration plant ideal for both grain and leaf production, thanks to its bushy growth. It thrives in moisture-retentive black soils and is resistant to aphids, ensuring robust performance. With a yield ranging from 1000 to 1100 kg per hectare, Sadhana offers an impressive return for farmers, combining resilience with high productivity. This variety stands out for its adaptability and quality, making it a valuable choice in coriander cultivation."
      },
      {
        heading:"Sindhu (CS-2)",
        description:"The Sindhu variety of coriander is a medium-duration plant, maturing in about 95-100 days. It produces medium-sized grains and offers a yield of approximately 1050 kg per hectare. Known for its quality and consistency, Sindhu is an excellent choice for export activities, meeting international standards with its reliable performance and robust flavor. Its optimal growing conditions and solid yield make it a standout variety for both domestic use and global markets."
      },
      {
        heading:"Sudha (LCC-128)",
        description:"The Sudha variety of coriander is a medium-duration plant, maturing in 80-98 days. It features oblong-shaped, medium-sized grains and boasts a yield potential of 750-1200 kg per hectare. With essential oil content ranging from 0.36% to 0.40%, Sudha delivers a rich, aromatic flavor that enhances its market appeal. This variety combines high yield and valuable oil content, making it an attractive choice for both culinary and commercial uses."
      },
      {
        heading:"Swathi (CS-6)",
        description:"The Swathi variety of coriander is a short-duration plant, maturing in just 80-85 days. It is notably resistant to powdery mildew, ensuring healthier crops. With a yield of approximately 900 kg per hectare, Swathi stands out for its robust performance. Its rapid growth and disease resistance make it an excellent choice for export activities, combining reliability with high-quality production to meet international standards. This variety offers a dependable option for growers seeking both efficiency and market appeal."
      },
      {
        heading:"APHU Dhania-1 (LCC-170)",
        description:"The APHU Dhania-1 variety of coriander seeds is a medium-duration plant, maturing in 85-90 days. It features bold, oblong-shaped grains with a yield potential of 850-1200 kg per hectare. Known for its high essential oil content of approximately 0.40%, APHU Dhania-1 delivers rich flavor and aromatic quality. This variety combines impressive yields with robust oil concentration, making it an excellent choice for both high-quality production and export opportunities."
      },
      {
        heading:"Suguna (LCC-236)",
        description:"The Sugana variety of coriander seeds is a medium-duration plant, maturing in 90-95 days. It produces slender, oval-shaped grains with an impressive yield potential of 750-1350 kg per hectare. Sugana is distinguished by its high volatile oil content of approximately 0.52%, enhancing its aromatic profile and flavor. This combination of high yield and rich oil content makes Sugana an excellent choice for both premium spice production and export markets, delivering superior quality and value."
      },
      {
        heading:"Suruchi (LCC-234)",
        description:"The Suruchi variety of coriander is renowned for its high yield and suitability for off-season production. Harvesting takes place 35 to 55 days after sowing. In the rabi season, it yields approximately 15-18 t/ha of grain, while in off-season under 50% shade net, it produces 2.5-4.5 t/ha of greens. This variety excels in both grain and leaf production, making it an excellent choice for maximizing output and quality throughout the year."
      },
      {
        heading:"Susthira (LCC-219)",
        description:"The Susthira variety of coriander seeds is a high-yielding, medium-duration plant known for its stability in rainfed areas. It offers a yield potential of 12.0 to 14.3 quintals per hectare under rainfed conditions and 12.0 to 17.5 quintals with irrigation. This versatility and consistent performance make Susthira an ideal choice for diverse growing conditions, ensuring reliable production and quality across different environments."
      },
    ],
    varietyHeading:"Coriander Seeds Varieties We Expor",
    verietyDesc:"Coriander Seeds imported from India feature a range of premium varieties, including Sadhana, Sindhu, and Sudha. Indian exporters are renowned for supplying top-quality seeds to global markets, ensuring exceptional flavor and freshness. The diverse selection of coriander seed varieties highlights India's strength in spice production and its commitment to meeting international demand with excellence. Whether you're sourcing for culinary or commercial use, India's coriander seeds offer unmatched quality and variety."
  },
  {
    heading: "Cumin Seeds",
    picture: cuminBanner,
    link: "Cumin",
    firstLayout: true,
    description: "Cumin seeds, harvested from the fruit of the Cuminum cyminum plant, are a staple in cuisines around the world. Native to South Western Asia and the Middle East, these seeds are dried and used both whole and ground, as well as in essential oil form. India stands as the leading producer and consumer of cumin, reflecting its integral role in Indian culinary traditions. The cumin plant thrives in Mediterranean climates with optimal temperatures ranging from 25-30 degrees Celsius. Its versatility extends beyond the kitchen, with applications in traditional medicine and various other uses, making cumin a valuable spice with widespread global importance.",
    marketDemand: "Originating from the Eastern Mediterranean region, cumin has been a cherished spice for over a millennium. In ancient Egyptian civilization, it was not only used in culinary practices but also played a crucial role in mummification as a preservative. As time progressed, cumin became a staple in Roman cuisine and was introduced to the Americas by Spanish and Portuguese colonists. Today, cumin’s global significance endures with major production centers in India, China, Northern Africa, Chile, and Mexico. This versatile spice continues to enrich dishes worldwide, reflecting its rich historical legacy and enduring popularity.",
    specifications: [
      {
        title:"Product Name",
        value:"Cumin seeds"
      },
      {
        title:"Origin",
        value:"Levant (Eastern Meditteranean)"
      },
      {
        title:"Family",
        value:"Apiaceae"
      },
      {
        title:"Binomial name",
        value:"Cuminum cyminum"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["375 Kcl Calories","10.5 gm fibre","44.24 gm carbohydrates"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium","Manganese","Phosphorus","Potassium","Sodium","Zinc"]
      },
      {
        title:"Vitamins",
        value:["Vitamin C, E, B"]
      },
      {
        title:"Protein",
        value:["17.81 gm"]
      },
      {
        title:"Fats",
        value:["22.27 gm"]
      },
    ],
    benifits: ["Cumin is valued in skincare for its antioxidant and antimicrobial properties. It helps combat acne, reduce inflammation, and promote healthy, glowing skin. Incorporating cumin into your routine can offer natural, effective benefits for radiant skin.","Cumin aids detoxification by boosting digestion and enhancing liver function. Its antioxidants help eliminate toxins from the body, supporting overall wellness. Regularly incorporating cumin into your diet can promote a natural, effective detox for a healthier you.","Cumin supports weight loss by boosting metabolism and improving digestion. Its natural compounds help regulate appetite and reduce bloating. Adding cumin to your diet can enhance fat burning and aid in achieving your weight loss goals.","Cumin promotes healthy digestion by stimulating the production of digestive enzymes. Its carminative properties help alleviate bloating and gas, while enhancing overall gut health. Integrating cumin into your meals can support smooth digestion and comfort."],
    outro: "",
    varieties: [],
  },
  {
    heading: "Dehydrated Fruits Exporters",
    picture: dehydratedFruitsBanner,
    link: "dehydrated fruits",
    firstLayout: false,
    description: "Dehydration involves removing moisture from fruits, vegetables, and other products to preserve them. Dehydrated fruits are increasingly in demand worldwide due to their versatility and numerous benefits. In India, dehydrated fruits are not only highly nutritious but also convenient for storage and have an extended shelf life. By eliminating water content, these fruits retain their essential nutrients and flavors while being lightweight and easy to handle. The growing global interest in healthy, long-lasting snacks has driven a rise in the import of dehydrated fruits from India. As a result, Indian dehydrated fruits are becoming a popular choice for consumers and businesses looking for quality, long-term food solutions.",
    marketDemand: "Global demand for dried fruits from India has surged, driven by their convenience and health benefits. These portable snacks are perfect for on-the-go, offering a nutritious alternative to fried, oil-laden foods that can be harmful to health. Packed with essential nutrients, minerals, and antioxidants, dried fruits provide a quick energy boost during a busy day. Their long shelf life and ease of storage make them a popular choice for health-conscious consumers worldwide. As the global appetite for healthy, convenient snacks grows, the import of dried fruits from India continues to rise, reflecting their increasing popularity and the value they bring to diverse markets.",
    specifications: [],
    ingredients: [],
    benifits: [],
    outro: "JNTB EXPORT IMPORT stands as a leading exporter of dehydrated fruits from India, committed to sharing the richness of Indian culture, food, and more with the world. Our dedicated team at JNTB EXPORT IMPORT is expertly trained to assist clients through every stage of the export process, ensuring a smooth and efficient experience. With our deep expertise and extensive industry experience, we guarantee that your dehydrated fruit exports are handled with utmost care and precision. From navigating paperwork to final delivery, we make the entire process hassle-free. If you're looking to explore the opportunities in dehydrated fruit exports, get in touch with JNTB EXPORT IMPORT today for comprehensive support and exceptional service!",
    varieties: [
      {
        heading:"Dehydrated Apple Flakes",
        description:"Dehydrated apple flakes are in high demand globally due to their rich flavor and enticing aroma. Despite the dehydration process, these flakes retain their nutritional value and offer a long shelf life. Their convenience and health benefits make them a popular choice for consumers looking for tasty, nutritious snacks. As a versatile ingredient, dehydrated apple flakes are sought after for their ability to enhance various dishes, contributing to their growing popularity in the international market."
      },
      {
        heading:"Dehydrated Apple Slices",
        description:"Dehydrated apple slices, ranging from red to green depending on the apple variety, are typically dried using food dehydrators. These slices are prized for their high fiber and antioxidant content, making them a nutritious snack choice. Their long shelf life adds to their appeal, as they offer a convenient and healthful option for consumers. Whether enjoyed on their own or as part of various dishes, dehydrated apple slices are a popular and versatile ingredient in the global market."
      },
      {
        heading:"Dehydrated Chikoo",
        description:"Sapota, or Chikoo, is a tropical delight known for its musky flavor and grainy texture. Dehydrated chikoo has gained popularity due to its impressive health benefits, including high vitamin A & C content, improved digestion, and energy boost. Its long shelf life and nutritional value have driven a surge in its export from India. As more consumers seek nutritious, convenient snacks, the demand for dehydrated chikoo continues to rise, reflecting its growing global appeal."
      },
      {
        heading:"Dehydrated Kiwi",
        description:"Kiwi, often called the Chinese gooseberry, is celebrated for its vibrant flavor and numerous health benefits. Packed with dietary fiber, vitamins, and minerals, it’s a nutritional powerhouse. The export of dehydrated kiwi from India is on the rise due to its high nutritional value and extended shelf life. This includes both natural and processed varieties, catering to a growing global demand for convenient and nutritious snacks. The trend reflects kiwi's increasing popularity in the international market."
      },
      {
        heading:"Dehydrated Mango",
        description:"Mangoes are a global favorite, and their dried counterpart is gaining popularity for its sweetness and nutrition. The export of dehydrated mangoes from India has surged, featuring both natural and processed varieties. Rich in soluble and insoluble fiber, dehydrated mangoes help stabilize blood sugar, enhance nutrient absorption, and may lower LDL cholesterol. Additionally, their insoluble fiber supports digestive health. This growing demand underscores the appeal of dehydrated mangoes as a delicious and healthful snack option worldwide."
      },
      {
        heading:"Dehydrated Orange",
        description:"The export of dehydrated oranges from India has seen significant growth due to their versatility and extended shelf life. With moisture removed, these oranges last longer than fresh ones and are perfect for travel snacks. They are ideal for garnishing and can be powdered to add flavor to soups, stews, and more. This combination of convenience and culinary utility has driven the rise in demand for dehydrated oranges, making them a popular export choice from India."
      },
      {
        heading:"Dehydrated Papaya",
        description:"Papaya, renowned for its health benefits and vibrant color, is increasingly sought after in its dehydrated form. Packed with antioxidants that reduce inflammation and promote youthful skin, dehydrated papaya offers a sweet, exotic flavor and a long shelf life. This combination has fueled a rise in its global demand. India’s export of dehydrated papaya, primarily consisting of papaya flakes, has grown significantly, reflecting its popularity as a nutritious and convenient snack option worldwide."
      },
      {
        heading:"Dehydrated Pineapple",
        description:"Dehydrated pineapple, often hailed as a 'magical fruit,' is packed with natural sugars and vitamins that provide an instant energy boost. Made using advanced vacuum dehydration technology, it offers a delightful candy-like chewiness and vibrant flavor. The export of dehydrated pineapple from India has surged, driven by its popularity in global cuisines and its appeal as a tasty, nutritious snack. Its versatility and long shelf life make it a favorite in both culinary and snack markets worldwide."
      },
      {
        heading:"Dehydrated Strawberry",
        description:"Dehydrated strawberries, with their moisture removed, are nutrient-dense powerhouses rich in fiber, potassium, and essential minerals. These dried fruits make for delicious, healthy snacks and are convenient to carry. The export of dehydrated strawberries from India has been on the rise, reflecting their global appeal. This includes various forms such as powders, slices, and more. Their long shelf life and versatile uses in culinary applications contribute to the increasing demand and export growth of these tasty, nutritious treats."
      },
    ],
    varietyHeading:"Dehydrated Fruits Varieties We Export",
    verietyDesc:"Dehydrated fruit exporters from India offer a diverse range of dried fruits, including apples, pineapples, papayas, bananas, and strawberries. These popular varieties are highly sought after for their convenience and nutritional benefits. The export of dehydrated fruits from India showcases these favorites and more, meeting the global demand for healthy, long-lasting snacks. Here are the major fruits included in the thriving export of dehydrated fruits from India."
  },
  {
    heading: "Global Market Demand of Dehydrated Vegetables",
    picture: dehydratedVegiesBanner,
    link: "dehydrated vegetables",
    firstLayout: false,
    description: "Dehydrated vegetables are created by removing moisture through advanced drying methods, including traditional sun drying. These veggies are used in a variety of products like instant noodles, soups, snacks, and fast foods, offering convenience and extended shelf life. The demand for dehydrated vegetables from India has surged globally, thanks to their versatile applications and retained nutritional value. Despite being dried, these vegetables preserve much of their essential nutrients, making them a healthy choice for consumers. The rise in exports reflects their growing popularity and the increasing global preference for easy-to-use, nutritious ingredients. As the market for dehydrated vegetables expands, Indian exports continue to meet international needs with high-quality, nutrient-rich products.",
    marketDemand: "",
    specifications: [],
    ingredients: [],
    benifits: [],
    outro: "JNTB EXPORT IMPORT stands as the leading exporter of dehydrated vegetables from India, committed to bringing Indian culture and cuisine to the global stage. Our dedicated team at JNTB EXPORT IMPORT ensures a seamless experience for our clients throughout the entire export process. From meticulous documentation to the final delivery, we manage every detail with precision. As one of the top names in dehydrated vegetable exports, we prioritize making the import-export process hassle-free and efficient. Our extensive experience and expertise guarantee that your products are handled with the utmost care and reach their destination in perfect condition. Interested in exporting high-quality dehydrated vegetables? Contact JNTB EXPORT IMPORT today and let us take your business to the global market!",
    varieties: [
      {
        heading:"Dehydrated Onion Flakes",
        description:"Dehydrated Onion Flakes are a versatile ingredient, perfect for adding flavor to canned soups, salads, hamburgers, sauces, and pizzas. These flakes, ranging from 10-25 mm, are also great for enhancing salads and even desserts. Packaged in a 12/14 kg double-layer poly bag within a paper bag or carton box, they retain their potent flavor and convenience. Ideal for fast food preparations and home cooking alike, dehydrated onion flakes offer a deliciously practical solution for flavoring any dish."
      },
      {
        heading:"Dehydrated Onion Chopped",
        description:"Dehydrated Onion Chopped is a versatile ingredient perfect for pizzas, ready-to-eat meals, pickles, soups, and various food preparations. With sizes ranging from 3-5 mm, it adds a burst of flavor to dry casserole mixes, stuffing, and fast foods. Packaged in a 20 kg poly bag within a double-layer carton box, it ensures freshness and convenience. Ideal for both home kitchens and food service operations, Dehydrated Onion Chopped delivers robust taste and ease in every dish."
      },
      {
        heading:"Dehydrated Onion Granules",
        description:"Dehydrated Onion Granules are perfect for enhancing the flavor of sauces, soups, dry and frozen foods, salad dressings, and meat products. These granules, ranging from 0.5 mm to 1 mm, provide a robust onion taste wherever needed. Ideal for repacking and store use, they come packed in a 20 kg poly bag within a double-layer carton box, ensuring freshness and convenience. Elevate your culinary creations with the versatile and flavorful addition of Dehydrated Onion Granules."
      },
      {
        heading:"Dehydrated Onion Minced",
        description:"Dehydrated Onion Minced is a versatile ingredient perfect for adding robust onion flavor to sauces, canned soups, dry and frozen foods, salad dressings, and meat products. With a size range of 1 mm to 3 mm, these minced pieces are ideal for repacking and store use. They come packed in a 20 kg poly bag within a double-layer carton box, ensuring quality and convenience. Enhance your dishes with the rich taste of Dehydrated Onion Minced, a staple for any kitchen."
      },
      {
        heading:"Dehydrated Onion Powder",
        description:"Dehydrated Onion Powder is an essential ingredient for enhancing flavor in sauces, canned soups, dry and frozen foods, salad dressings, and meat products. This fine powder is perfect for adding a concentrated onion taste to a variety of dishes. Conveniently packaged in a 20 kg poly bag inside a double-layer carton box, it’s also ideal for repacking for stores. Elevate your culinary creations with the rich, consistent flavor of Dehydrated Onion Powder."
      },
      {
        heading:"Dehydrated Garlic Flakes",
        description:"Garlic flakes pack a punch with their robust flavor, making them a favorite for seasoning soups, sauces, vegetable mixes, stuffing, and fast foods. Measuring 7mm-15mm, these flakes add a burst of garlic goodness to your dishes. Packaged in a 25 kg double-layer poly bag inside an aluminum foil bag, they ensure freshness and convenience. Elevate your culinary creations with the bold taste of Garlic Flakes, perfect for any kitchen or food service."
      },
      {
        heading:"Dehydrated Garlic Chopped",
        description:"Dehydrated Garlic Chopped is perfect for enhancing pizzas, fast foods, snacks, and a variety of culinary creations. Ideal for food service packs, stuffing mixes, pickles, meat and seafood dishes, gravies, and more, these chopped pieces, measuring 3mm-5mm, deliver a robust garlic flavor. Packaged in a 20 kg double-layer poly bag within a sturdy carton box, this versatile ingredient brings convenience and taste to your kitchen. Elevate your dishes with the bold essence of Dehydrated Garlic Chopped."
      },
      {
        heading:"Dehydrated Garlic Granules",
        description:"Dehydrated Garlic Granules are a must-have for adding robust flavor to a variety of dishes. Perfect for dry soups, sauces, vegetable and stuffing mixes, fast foods, salsas, curry powders, and gourmet sauces, these granules measure 0.5mm-1mm in size. Packaged in a 20 kg double-layer poly bag inside a carton box, they offer convenience and intense garlic taste for all your culinary needs. Elevate your recipes with the rich, aromatic kick of Dehydrated Garlic Granules."
      },
      {
        heading:"Dehydrated Garlic Minced",
        description:"Dehydrated Garlic Minced is a versatile ingredient used to enhance the flavor of pizzas, snacks, sauces, and more. Ideal for food service packs, pickled products, meat, seafood, canned goods, and curry powders, these minced pieces range from 1mm to 3mm in size. Conveniently packed in a 20 kg double-layer poly bag inside a carton box, this garlic offers a robust flavor and aroma for diverse culinary applications. Elevate your dishes with the distinctive taste of Dehydrated Garlic Minced."
      },
      {
        heading:"Dehydrated Garlic Powder",
        description:"Dehydrated Garlic Powder is a versatile ingredient used in sauces, gravies, meats, soups, snacks, and more. Perfect for enhancing flavors in cheese, ethnic dishes, butter spreads, and food service packs, it’s also ideal for stuffing mixes, pickled products, and seafood. This finely powdered garlic, packed in a 20 kg double-layer poly bag inside a carton box, adds a robust and savory kick to a wide range of culinary creations. Elevate your recipes with the convenience of Dehydrated Garlic Powder."
      },
      {
        heading:"Dehydrated Tomato",
        description:"The demand for dehydrated tomatoes has surged due to their versatile culinary uses. From enhancing pizza toppings and sautéed veggies to adding flavor to baked goods and green salads, these tomatoes offer convenience and taste. With a wide variety of dehydrated tomatoes exported from India each year, their global appeal continues to grow. The rising export of these flavorful, long-lasting tomatoes reflects their increasing popularity and the diverse ways they enrich dishes around the world."
      },
      {
        heading:"Dehydrated Carrot",
        description:"Dehydrated carrots have been gaining popularity due to their versatility and convenience. Perfect for casseroles, loaves, pizza toppings, omelettes, and soups, they also add a crisp touch to stir-fried dishes. Their ease of use and long shelf life have fueled a rise in global demand. Consequently, the export of dehydrated carrots from India has been steadily increasing, reflecting their growing role in kitchens worldwide and their value in diverse culinary applications."
      },
      {
        heading:"Dehydrated Cabbage",
        description:"The export of dehydrated cabbage has surged in recent years, and the reason is clear. Its versatility makes it a global favorite. Dehydrated cabbage is a key ingredient in soups, slaws, and casseroles, adding flavor and texture. It's also a convenient addition to salads and stews, making it a staple in diverse culinary preparations. With its extended shelf life and ease of use, the demand for dehydrated cabbage from India continues to grow, catering to kitchens around the world."
      },
      {
        heading:"Dehydrated Beetroot",
        description:"The export of dehydrated beetroot has been booming due to its incredible health benefits and versatility. Rich in natural sugars, potassium, iron, and essential vitamins, dehydrated beetroot is a nutritious addition to salads and a tasty, crunchy snack in chip form. Its uses extend beyond the kitchen, offering various medicinal benefits as well. As a result, the demand for dehydrated beetroot continues to rise, reflecting its growing popularity and widespread applications in global markets."
      },
      {
        heading:"Dehydrated Basil Leaves",
        description:"Dehydrated basil leaves are gaining global popularity for their robust flavor and medicinal benefits. Used to enhance the taste of tea and aid in relief from issues like dysentery, nausea, and gas-related stomach aches, these versatile leaves are increasingly in demand. Their extensive health benefits and diverse applications are driving the rise in their export. With their growing presence in international markets, dehydrated basil leaves are becoming a sought-after ingredient for both culinary and therapeutic uses."
      },
      {
        heading:"Dehydrated Potato Flakes",
        description:"Dehydrated potato flakes are crafted from all-natural potatoes, cooked, mashed, and then dehydrated for ultimate convenience. Simply add hot water or milk to these flakes, and you can enjoy creamy mashed potatoes in minutes. Perfect for quick meals, these flakes offer a tasty, hassle-free solution for enjoying homemade comfort food anytime. With their easy preparation and delightful flavor, dehydrated potato flakes are a staple for fast, delicious dining."
      },
      {
        heading:"Dehydrated Potato Powder",
        description:"Dehydrated potato powder offers an effortless way to enhance your meals. This fine powder is made from air-dried potatoes, which are dehydrated under a steady stream of warm air to remove moisture. Ideal for adding to bread, casseroles, and vegetable side dishes, it brings convenience and a rich, comforting taste to your cooking. With its ease of use and storage, this versatile ingredient is perfect for quick and flavorful recipes, making it a staple in kitchens worldwide."
      },
      {
        heading:"Dehydrated Bitter Gourd",
        description:"Dehydrated bitter gourd is gaining popularity for its diverse uses and benefits. Available in cubes or rings for curries and as dried pieces for bitter gourd tea, it offers extended shelf life and ease of storage. By reducing bulk for transportation and packaging, dehydrated bitter gourd is a convenient choice for many. The export of dehydrated bitter gourd from India has surged in recent years, reflecting its growing demand and the practical advantages it provides."
      },
      {
        heading:"Dehydrated Spinach Powder",
        description:"The export of dehydrated spinach powder from India has seen significant growth, thanks to its nutritional richness and mild flavor. Ideal for enhancing dishes like spinach-flavored bread, pasta, sauces, rotis, and idlis, this versatile powder adds both taste and nutrition. For best results, store it in a cool, dry place and avoid using wet utensils to maintain its quality. The increasing global demand for this convenient and healthful ingredient reflects its rising popularity in various culinary applications."
      },
      {
        heading:"Dehydrated Mushroom",
        description:"Dehydrated mushrooms have become a staple in global cuisines, prized for their long shelf life and intensified flavor. Perfect for enriching broths, stews, soups, and various dishes, these mushrooms offer convenience and robust taste. Morel mushrooms, a sought-after variety, are also included in exports. The rising international demand for dehydrated mushrooms has driven the export from India, reflecting their growing popularity and versatile culinary applications worldwide."
      },
    ],
    varietyHeading:"Dehydrated Vegetable Varieties We Export",
    verietyDesc:"Dehydrated vegetable exporters in India are making waves globally by sending top-quality products to all corners of the world. The range of dehydrated vegetables typically includes onion, garlic, cabbage, beetroot, and more. These versatile ingredients retain their flavor and nutritional value, making them popular in various cuisines. As the demand for convenient and nutritious ingredients rises, the export of these dehydrated vegetables from India continues to grow, ensuring a steady supply of premium products worldwide."
  },
  {
    heading: "Indian Onion Exporters",
    picture: onionBanner,
    link: "Onion",
    firstLayout: false,
    description: "Indian onions are experiencing a surge in global demand, making India the second-largest onion producer worldwide. In the 2018-2019 period alone, India exported an impressive 2,182,826.23 MT of onions, generating a revenue of Rs. 3,467.06 crores (USD 497.94 million). Indian onions are a staple in many international markets, with key destinations including Bangladesh, Nepal, and Sri Lanka. This growing demand underscores the significant role of Indian onions in the global agricultural market, offering substantial opportunities for exporters to expand their reach and cater to a thriving international market.",
    marketDemand: "",
    specifications: [],
    ingredients: [],
    benifits: [],
    outro: "Onions are among the most consumed vegetables globally, integral to countless cuisines both in India and worldwide. As demand continues to rise, finding a reliable exporter is crucial. JNTB EXPORT IMPORT stands out as one of the best onion exporters from India. With a commitment to quality and efficiency, JNTB EXPORT IMPORT ensures that your onion shipments reach any destination, fresh and safe. From documentation to delivery, they handle the entire export process, offering unparalleled support. If you're looking to tap into the growing global market for onions, JNTB EXPORT IMPORT is your trusted partner for seamless and successful exports.",
    varieties: [
      {
        heading:"Agrifound Dark Red",
        description:"The Agrifound Dark Red onion variety is known for its globular, dark red bulbs and moderately pungent flavor. With a tight skin, these onions are resilient and well-suited for various culinary uses. Typically, the crop matures within 90-100 days after transplanting, offering a reliable growth cycle. Farmers can expect an impressive average yield of around 300 quintals per hectare, making Agrifound Dark Red a highly productive and desirable variety for both local and international markets."
      },
      {
        heading:"Agrifound Light Red",
        description:"The Agrifound Light Red onion variety features attractive, light red, globular bulbs with thick reddish inner scales. Known for its appealing appearance, this variety matures in approximately 110-120 days after transplanting. With a strong yield potential, farmers can expect an average harvest of 300-350 quintals per hectare. The Agrifound Light Red onions are not only visually striking but also offer reliable productivity, making them a popular choice for cultivation and export."
      },
      {
        heading:"NHRDF Red",
        description:"The NHRDF onion variety matures within 110-120 days post-transplanting, producing attractive, dark red, globular bulbs. With an average yield of 250-300 quintals per hectare, this variety is both productive and visually appealing. It is well-suited for cultivation in Northern, Central, and Western India during the rabi season. The NHRDF variety’s adaptability and consistent yield make it a favored choice among farmers, contributing to its popularity in both domestic and international markets."
      },
      {
        heading:"Agrifound White",
        description:"The Agrifound White onion variety produces attractive, white, globular bulbs with tight, silvery skin. Maturing in 110-120 days post-transplanting, this variety offers an average yield of 250-300 quintals per hectare. Ideal for late Kharif and rabi seasons, Agrifound White is recommended for cultivation in Maharashtra, Madhya Pradesh, Gujarat, and Rajasthan. Its appealing appearance and reliable yield make it a preferred choice for farmers, contributing to its strong presence in both domestic and export markets."
      },
      {
        heading:"Agrifound Rose",
        description:"The Agrifound Rose onion variety is characterized by deep scarlet red, flattish round bulbs. Maturing in 95-110 days after sowing, this variety offers a dependable yield of 250-300 quintals per hectare. Agrifound Rose is ideal for Kharif cultivation in Cuddapah, Andhra Pradesh, and can be grown in all three seasons in Karnataka. Its vibrant color and strong yield make it a popular choice among farmers, ensuring its demand in both local and broader markets."
      },
      {
        heading:"Agrifound Red",
        description:"The Agrifound Red onion variety is recommended for cultivation during the Kharif and rabi seasons in Karnataka, Tamil Nadu, and Kerala. This variety produces light red bulblets, with each cluster containing about 5-6 bulblets. Known for its adaptability and consistent growth, Agrifound Red is a reliable choice for farmers in these regions. Its attractive color and robust yield make it a favored option for both local markets and export opportunities."
      },
      {
        heading:"Pusa Ratnar",
        description:"The Pusa Ratnar onion variety features red, globular bulbs that are more exposed above ground at maturity. These onions are less pungent and have moderate storage quality, making them suitable for various culinary uses. With an average yield of around 30 tons per hectare, Pusa Ratnar is a productive choice for farmers. This variety is specifically recommended for cultivation during the rabi season, offering reliable growth and appeal for both local consumption and broader markets."
      },
      {
        heading:"Pusa Red",
        description:"The Pusa Red onion variety features medium-sized bulbs with a striking bronze-red color, and shapes that range from flat to globular. Maturing in approximately 140-145 days after transplanting, Pusa Red is highly suitable for export due to its robust quality and appealing appearance. Ideal for cultivation in Maharashtra during the late Kharif and rabi seasons, this variety promises a productive harvest and is well-regarded for its export potential and adaptability."
      },
      {
        heading:"Pusa White Round",
        description:"The Pusa White Round onion variety boasts white, roundish or flat bulbs that mature in 130-135 days after transplanting. Known for its excellent storage qualities, this variety is ideal for long-term use. Its attractive appearance and dependable performance make it a favorite among farmers. The Pusa White Round onion’s robust storage capability ensures that it remains fresh and viable for extended periods, making it a valuable choice for both local markets and export opportunities."
      },
    ],
    varietyHeading:"Onion Varieties We Export",
    verietyDesc:"India is a leading exporter of onions, offering popular varieties such as Agrifound Dark Red, Agrifound Light Red, and NHRDF Red. These onions are cultivated in key states like Maharashtra, Karnataka, Madhya Pradesh, Gujarat, Bihar, Andhra Pradesh, Haryana, and Telangana. Known for their distinct pungency, Indian onions are in high demand globally. Countries around the world rely on Indian exporters to supply these flavorful onions, making them a staple in international kitchens."
  },
  {
    heading: "Indian Mushroom Exporters",
    picture: mushroomBanner,
    link: "Mushroom",
    firstLayout: false,
    description: "Mushrooms have emerged as a thriving non-traditional cash crop in India, benefiting from both seasonal cultivation and controlled indoor environments. With rising global demand, India's mushroom exports are expanding, reaching key markets such as the US, Israel, and Mexico. Exporters are capitalizing on the growing interest in both fresh and processed mushrooms, showcasing India's ability to deliver high-quality produce. As a versatile ingredient in various cuisines, mushrooms from India are increasingly sought after worldwide. This upward trend highlights India's growing role in the global mushroom market, offering a promising opportunity for exporters to meet the diverse needs of international buyers.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 22","Total Carbohydrate 3.3 g","Dietary Fibre 1 g"]
      },
      {
        title:"Protein",
        value:["3.1 gm"]
      },
      {
        title:"Fat",
        value:["0.6 gm"]
      },
      {
        title:"Vitamin",
        value:["B vitamins:- riboflavin, niacin, and pantothenic acid"]
      },
      {
        title:"Minerals",
        value:["Iron","Phosphor","Copper","Potassium","Selenium"]
      },
    ],
    benifits: [],
    outro: "JNTB EXPORT IMPORT stands out as one of India’s premier mushroom importers, offering exceptional service in the global market. We help our customers connect with international buyers, conduct thorough market analysis, and ensure the supply of premium quality mushrooms. Leveraging our extensive expertise in the food export industry and our robust global network, we are equipped to export mushrooms to any destination worldwide. Whether you’re looking to enter new markets or enhance your existing export operations, JNTB EXPORT IMPORT provides the reliability and knowledge needed to succeed. For top-notch mushroom export from India, JNTB EXPORT IMPORT is your ideal partner, committed to delivering excellence and expanding your reach in the international marketplace.",
    varieties: [
      {
        heading:"White Button Mushroom",
        description:"White button mushrooms are petite with a white, edible stem that is dense, thick, and smooth. In their raw state, they offer a mild, crisp texture. When cooked, these mushrooms transform, developing a rich, earthy flavor and a tender, chewy texture. Their versatility makes them ideal for a variety of dishes, adding both taste and texture. Whether used in salads or sautéed, white button mushrooms are a staple for enhancing culinary creations."
      },
      {
        heading:"Portobello Mushrooms",
        description:"Portobello mushrooms are distinguished by their dark brown, fleshy gills and a small ring beneath the cap, remnants of the cotton-like veil. Their stems are fibrous, white, and dense. When cooked, Portobellos offer a robust, meaty texture with a smoky, earthy flavor that enhances a variety of dishes. Their hearty quality makes them a fantastic alternative to meat, adding depth and richness to everything from burgers to stir-fries."
      },
      {
        heading:"Shiitake Mushrooms",
        description:"Shiitake mushrooms are small to medium-sized, featuring wide, umbrella-shaped caps that range from light to dark brown. Known for their distinctive curled rim, these mushrooms have thin stems and firm, chewy, spongy cream-colored flesh. Their unique texture and rich, savory flavor make them a popular choice in various dishes, from stir-fries to soups. Shiitake mushrooms add a delightful depth to meals, offering both a satisfying bite and an earthy taste that enhances any culinary creation."
      },
      {
        heading:"Oyster Mushrooms",
        description:"Oyster mushrooms boast caps that range from white to light or dark brown, shaped like a funnel and adorned with whitish-yellow gills. Their off-center stems support a delicate, white flesh. Growing in a distinctive shelf-like formation, these mushrooms appear in overlapping clusters that create a visually appealing display. Their mild flavor and tender texture make them a versatile ingredient, adding a subtle depth to a variety of dishes while enhancing both presentation and taste."
      },
      {
        heading:"Enoki Mushrooms",
        description:"Enoki mushrooms grow in delicate clusters with long, string-like stems and small white caps. They offer a mild flavor with a satisfying crunch, making them a versatile addition to salads, soups, meat dishes, and as a garnish. For optimal texture and taste, it's best to remove the spongy base before use. Their unique appearance and crisp bite elevate any dish, adding a touch of elegance and subtle flavor that enhances both visual appeal and culinary experience."
      },
      {
        heading:"Shimeji Mushrooms",
        description:"Shimeji mushrooms should always be cooked to enjoy their full flavor and texture. When raw, they can be bitter, but cooking transforms them into a delightful ingredient. After cooking, Shimeji mushrooms offer a pleasant, firm, slightly crunchy texture and a subtly nutty flavor. Their transformation from bitter to delicious makes them a versatile choice for enhancing a variety of dishes, from stir-fries to soups, adding both a satisfying bite and a rich, savory taste."
      },
      {
        heading:"Porcini Mushrooms",
        description:"Porcini mushrooms are renowned for their nutty and earthy flavor, offering a rich, meaty texture that sets them apart from other mushrooms. Their deeper, nuttier taste enhances a variety of dishes. When dried, porcini mushrooms infuse broths and sauces with a profound mushroom essence. Once rehydrated, they regain a slightly chewy texture, making them a versatile ingredient for adding depth and complexity to both savory and hearty culinary creations."
      },
      {
        heading:"Paddy Straw Mushrooms",
        description:"Volvariella volvacea, commonly known as the paddy straw mushroom, is widely cultivated in East and Southeast Asia. This versatile mushroom is a staple in Asian cuisines and ranks third in global consumption. While fresh paddy straw mushrooms are readily available in Asia, they are typically found in canned or dried forms elsewhere. Their unique texture and flavor make them a popular choice for a variety of dishes, adding a distinct touch to international culinary creations."
      },
    ],
    varietyHeading:"Mushroom Varieties We Export",
    verietyDesc:"India’s mushroom exporters offer a diverse range of varieties, including White Button, Portobello, and Oyster mushrooms. With the growing global demand, mushroom export from India presents a lucrative opportunity for boosting revenue. Exporters can capitalize on this expanding market by providing high-quality, versatile mushrooms to international buyers. As a valuable cash crop, mushrooms not only enhance revenue but also showcase India's agricultural expertise on the global stage."
  },
  {
    heading: "Indian Broccoli Exporters",
    picture: broccoliBanner,
    link: "Broccolie",
    firstLayout: false,
    description: "Broccoli, a nutritious green vegetable from the cabbage family, is prized for its large, flowery head and stalk. As one of the world’s leading producers, India plays a significant role in the global broccoli market. Indian broccoli exporters ship tonnes of this health-boosting vegetable each year, capitalizing on its rising popularity due to its numerous health and medicinal benefits. With a growing demand internationally, many countries import broccoli from India to enjoy its exceptional nutritional value and versatility. The increase in broccoli export reflects its global appeal, driven by health-conscious consumers and the expanding recognition of its dietary advantages.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 31","Carbs - 6 grams","Fibre - 2.4 grams"]
      },
      {
        title:"Protein",
        value:["2.6 gm"]
      },
      {
        title:"Fat",
        value:["0.4 gm"]
      },
      {
        title:"Vitamin",
        value:["Vitamin C and K"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Phosphorous","Potassium","Zinc","Thiamin","Riboflavin","Niacin"]
      },
    ],
    benifits: [],
    outro: "JNTB EXPORT IMPORT is a top choice for broccoli importers in India, renowned for its exceptional quality and reliable service. We export premium broccoli to numerous countries worldwide, ensuring timely delivery and top-notch products. As the broccoli export industry continues to grow rapidly in India, JNTB EXPORT IMPORT stands out for its commitment to excellence. Our extensive expertise and dedication to quality make us a trusted partner for exporting broccoli from India. If you're looking to enter the booming broccoli export market or enhance your existing operations, JNTB EXPORT IMPORT is here to provide seamless support and help you succeed in the global marketplace.",
    varieties: [
      {
        heading:"Belstar",
        description:"The Belstar broccoli variety is an organic hybrid ideal for spring or summer planting. Renowned for its adaptability, Belstar produces stress-tolerant plants that thrive under various conditions. This variety features a large central head and an abundance of smaller side shoots, ensuring a generous harvest. Its robust growth and high yield make Belstar a popular choice for growers seeking a reliable and productive broccoli variety."
      },
      {
        heading:"Calabrese",
        description:"Calabrese, often referred to as American, Italian, or green sprouting broccoli, is a fast-growing and easy-to-cultivate crop. Reaching up to 2 feet in height, it yields vibrant bluish-green heads that are harvested in summer or autumn, depending on the sowing time. Its robust growth and appealing color make Calabrese a favorite among gardeners and farmers alike, offering a reliable and bountiful harvest with minimal effort."
      },
      {
        heading:"Destiny",
        description:"Destiny is a heat-tolerant broccoli variety celebrated for its high vitamin A and C content. This mid-early, compact variety features medium-small green beads, a smooth dome, and a round shape. Ideal for fall harvests when planted in summer, Destiny offers a reliable yield and robust performance in warmer climates. Its nutritious profile and adaptability make it a standout choice for growers seeking a versatile and healthful broccoli variety."
      },
      {
        heading:"DiCicco",
        description:"Broccoli di Cicco is a classic variety known for its sweet flavor, similar to common broccoli. Renowned as the precursor to giant broccoli heads, this old-fashioned variety offers versatility in the kitchen. All parts of the Broccoli di Cicco—stems, leaves, and florets—are edible, allowing you to chop and cook the entire bunch. Its rich taste and full usability make it a great choice for creating flavorful, wholesome dishes."
      },
      {
        heading:"Eastern Magic",
        description:"The Eastern Magic variety of broccoli is renowned as a super-producer, particularly suited for the northeast US and Canada. These plants boast a substantial blue-green crown and are celebrated for their rich, tangy, nutty, and buttery flavor. Eastern Magic’s impressive yield and exceptional taste make it a standout choice for both local markets and culinary enthusiasts, offering a flavorful twist to your broccoli dishes with its robust and unique flavor profile."
      },
      {
        heading:"Green Magic",
        description:"The Green Magic variety is a hybrid heading broccoli known for its impressive performance. Maturing in approximately 60 days from transplant, Green Magic yields large, densely-packed heads. It excels in producing abundant harvests, particularly during warm spring temperatures. This variety’s robust growth and high yield make it a favorite among growers seeking reliable and bountiful broccoli crops, ensuring a vibrant and plentiful addition to any spring garden."
      },
      {
        heading:"Purple Sprouting",
        description:"Purple-sprouting broccoli is a remarkably hardy variety, thriving in temperatures as low as -12°C. It’s typically sown in March for an early winter harvest or from April to mid-June for a January to May harvest. This robust variety excels in heavy, alkaline soils, producing vibrant, nutritious sprouts. Its resilience and extended harvesting window make it a valuable addition to any garden, offering both flavor and durability throughout the colder months."
      },
      {
        heading:"Romanesco",
        description:"Romanesco broccoli is a striking variety of edible flower bud from the Brassica oleracea species, known for its chartreuse color and unique fractal-like appearance. Its intricate, geometric patterns make it not only a visually stunning vegetable but also a fascinating natural example of fractal geometry. Romanesco’s distinctive look and mild, nutty flavor add an exceptional touch to any dish, making it a standout choice for both its aesthetic appeal and culinary versatility."
      },
      {
        heading:"Sun King",
        description:"The Sun King variety of broccoli is celebrated for its exceptional heat tolerance and delicious flavor. This robust variety produces vibrant blue-green heads, measuring 6-8 inches in diameter, along with several productive side shoots. Reaching maturity in about 70 days, Sun King offers a reliable harvest even in warmer climates. Its impressive flavor and adaptability make it a top choice for gardeners and chefs seeking a versatile and flavorful addition to their broccoli repertoire."
      },
      {
        heading:"Waltham 29",
        description:"Waltham 29 is a broccoli variety engineered to thrive in the cooler temperatures of the Pacific Northwest and East Coast. These plants reach a height of around 20 inches, producing medium to large blue-green heads on long, sturdy stalks. Ideal for colder climates, Waltham 29 combines resilience with impressive yield, offering a reliable harvest even in challenging weather conditions. Its robust growth and high-quality heads make it a standout choice for gardeners in cooler regions."
      },
    ],
    varietyHeading:"Broccoli Varieties We Export",
    verietyDesc:"Indian broccoli exporters offer a diverse range of varieties to the global market, including popular types such as Belstar, Calabrese, and Destiny. Each variety boasts unique characteristics, from flavor profiles to textures, catering to different culinary needs and preferences. Over the past few years, the broccoli export industry in India has experienced exponential growth, reflecting the increasing global demand for this nutritious vegetable. With a commitment to quality and innovation, Indian exporters are well-positioned to supply premium broccoli to international markets. This surge in export activity highlights India’s expanding role in the global vegetable industry and the growing appreciation for the health benefits and versatility of broccoli."
  },
  {
    heading: "Indian Potato Exporters",
    picture: potatoBanner,
    link: "Potato",
    firstLayout: false,
    description: "Potatoes are a staple food in India, featured in a variety of dishes such as vegetables, salads, and curries. As the world’s second-largest potato exporter, India supplies these versatile tubers to over 30 countries globally. Key potato-producing states include Uttar Pradesh, Bihar, and Gujarat, known for their high-quality yields. Among the countries importing Indian potatoes, Nepal stands out as the largest buyer. With its extensive production capabilities and robust export network, India continues to play a significant role in the global potato market, providing a crucial food source to many regions around the world.",
    marketDemand: "",
    specifications: [],
    ingredients: [],
    benifits: [],
    outro: "Potatoes are one of the most widely consumed vegetables globally, and India's potato exports are on the rise year after year. From 2013 to 2017, the value of Indian potato exports surged by 11%. If you're looking to export potatoes, JNTB EXPORT IMPORT is your go-to partner. Renowned for its reliability, JNTB EXPORT IMPORT excels in every step of the export process—from identifying potential buyers to conducting thorough market analysis. With JNTB EXPORT IMPORT, you can navigate the complexities of international trade with ease and confidence. For seamless and successful food product exports, trust JNTB EXPORT IMPORT to deliver excellence and support at every turn.",
    varieties: [
      {
        heading:"Kufri Pukhraj",
        description:"The Kufri Pukhraj variety of potatoes is medium to large, featuring a slightly lumpy shape and a light brown to golden color. These potatoes boast a smooth, wax-like skin with a few shallow eyes and small brown spots. Once cooked, Kufri Pukhraj potatoes reveal a tender texture and a mild, earthy flavor. Their versatile nature makes them ideal for a range of culinary uses, from creamy mashed potatoes to flavorful roasts."
      },
      {
        heading:"Kufri Jyoti",
        description:"The Kufri Jyoti is a popular potato variety known for its ease of cooking and waxy texture. With a mild flavor, it’s ideal for making instant flakes and crispy chips. The flesh of Kufri Jyoti potatoes can be either white or match the skin's color, adding versatility to its culinary applications. Its consistent quality and desirable properties make it a favorite choice for a variety of processed potato products."
      },
      {
        heading:"Kufri Bahar",
        description:"Kufri Bahar is a distinctive potato variety, featuring large, round-oval white potatoes with smooth skin. It has medium-deep eyes and white flesh, making it versatile for various culinary uses. This variety thrives in regions like Haryana, Himachal Pradesh, Jammu and Kashmir, and Uttar Pradesh. Kufri Bahar’s adaptability and quality make it a reliable choice for growers and consumers alike, offering both visual appeal and a consistent performance in the kitchen."
      },
      {
        heading:"Bhura Aloo",
        description:"Bhura Aloo is a notable potato variety predominantly found in Bihar. Known for its red skin, it is a native variety with generally low productivity but high demand. The preference for red-skinned potatoes among farmers and consumers keeps Bhura Aloo in high demand. Its distinct color and traditional value make it a sought-after choice, reflecting its importance in regional agriculture and culinary traditions."
      },
      {
        heading:"Kufri Chipsona",
        description:"Kufri Chipsona is a versatile potato variety known for its ease of cooking and mild flavor. Featuring a waxy texture, it maintains its appealing appearance without discoloration after cooking. With low reducing sugars and phenols, Kufri Chipsona is ideal for making crispy chips and French fries. Its consistent quality and suitability for deep-frying make it a popular choice among producers and consumers seeking top-notch, golden, and delicious potato products."
      },
      {
        heading:"Kufri Sindhuri",
        description:"Kufri Sindhuri is a medium-late potato variety, maturing in about 110-120 days. This variety features medium-sized, smooth, round tubers with light red skin and dull white flesh. Kufri Sindhuri’s appealing color and texture make it a versatile choice for various culinary applications. Its reliable growth and quality ensure it remains a favorite among both growers and consumers, offering a consistent and attractive option in the potato market."
      },
      {
        heading:"Kufri Chandramukhi",
        description:"Kufri Chandramukhi is an early-maturing potato variety, ready in just 80-90 days. It produces large, smooth, oval-shaped tubers with white skin, flat eyes, and white flesh. Known for its impressive yield, Kufri Chandramukhi can produce around 100 quintals per acre. Its consistent performance and attractive appearance make it a favored choice for growers seeking both quality and quantity in their potato harvests."
      },
      {
        heading:"Kufri Khyati",
        description:"Kufri Khyati is a high-yielding potato variety with notable resistance to late blight. Cultivated primarily in the plains of India, it stands out for its impressive productivity compared to other early-maturing cultivars. Its resilience and robust performance make Kufri Khyati a preferred choice for farmers seeking a reliable and bountiful potato harvest. With its superior yield and resistance, this variety ensures a successful crop and contributes significantly to India’s potato production."
      },
    ],
    varietyHeading:"Potato Varieties We Export",
    verietyDesc:"Indian potato exporters offer a range of varieties, including Kufri Pukhraj, Kufri Jyoti, Kufri Bahar, and Bhura Aloo. Despite being a leading exporter, India also ranks as one of the largest global consumers of potatoes. These popular varieties are enjoyed worldwide, each bringing unique flavors and textures to diverse dishes. From crispy fries to hearty curries, these versatile potatoes cater to a wide array of culinary preferences and highlight India’s significant role in the global potato market."
  },
  {
    heading: "Indian Spinach Exporters",
    picture: spinachBanner,
    link: "Spinach",
    firstLayout: false,
    description: "Spinach, renowned for its rich nutritional profile, is a highly sought-after green leafy vegetable. Indian spinach exporters are making significant strides, supplying spinach to approximately 40 countries worldwide. In the 2019-2020 period, the export value of spinach from India reached $0.37 million, reflecting its growing international demand. During the same period, India exported around 338,210 metric tons of spinach, showcasing its strong production capabilities and global reach. With its impressive nutritional benefits and versatile culinary uses, Indian spinach continues to capture attention and thrive in the international market, highlighting India's role as a major player in global spinach trade.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 23","Carbohydrates - 7g","Fibre - 4g"]
      },
      {
        title:"Protein",
        value:["1g"]
      },
      {
        title:"Fat",
        value:["0g"]
      },
      {
        title:"Vitamin",
        value:["Vitamin K, Vitamin A, Vitamin B2, Vitamin B6, Vitamin E, and Vitamin C"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Folic Acid","Potassium","Magnesium"]
      },
    ],
    benifits: [],
    outro: "JNTB EXPORT IMPORT stands out as one of the top spinach importers from India, dedicated to delivering premium-quality spinach to a wide range of international markets. We assist clients by identifying potential buyers, conducting thorough market analysis, and handling all necessary documentation and permissions. With the export of spinach from India on the rise and expected to grow even further, JNTB EXPORT IMPORT is your ideal partner for navigating the global market. Our expertise and comprehensive support ensure a seamless export experience, making us the best choice for exporting spinach from India. Trust JNTB EXPORT IMPORT to elevate your spinach export business to new heights!",
    varieties: [
      {
        heading:"Bloomsdale",
        description:"Bloomsdale spinach is a robust variety known for its large, spreading growth and meaty, dark-green leaves with a rich, nutty flavor. This variety is slow to bolt, providing a steady harvest throughout the growing season. It is best sown in spring for an initial crop and can be replanted in late summer for continuous production. In regions with mild winters, fall plantings of Bloomsdale can yield a fresh crop by early spring, ensuring a bountiful harvest."
      },
      {
        heading:"Regiment",
        description:"Regiment spinach is a fast-growing, vigorous variety known for its bolt resistance and long-lasting performance. Ideal for gardens from early spring through fall, it features thick, slightly triangular, and glossy dark green leaves that grow upright. This robust spinach not only adds beauty to your garden but also provides a steady, reliable harvest throughout the growing season. With its impressive resilience and attractive foliage, Regiment spinach is a top choice for both novice and experienced gardeners."
      },
      {
        heading:"Indian Summer",
        description:"Indian Summer spinach is a versatile semi-savoy variety perfect for all three seasons. It features flat, slightly curled leaves with a deep green color. Known for its high bolting resistance, this spinach is a reliable choice for consistent harvests. With a harvest period of around 7 weeks, Indian Summer offers both durability and productivity. Its robust nature and attractive foliage make it a valuable addition to any garden, ensuring fresh, flavorful spinach throughout the year."
      },
      {
        heading:"Tyee",
        description:"Tyee spinach is a robust, disease-resistant variety renowned for its resilience to both heat and cold. It features wrinkly, dark-green leaves that add a striking visual appeal to any garden. Once harvested, Tyee leaves are flavorful and packed with nutrients, perfect for cooking or adding to salads. This hardy spinach ensures a bountiful and beautiful harvest, making it a top choice for gardeners seeking both beauty and taste in their leafy greens."
      },
      {
        heading:"Catalina",
        description:"Catalina spinach is known for its well-shaped, crunchy texture, making it ideal for baby leaf harvests perfect for fresh, flavorful salads. This variety boasts smooth leaves with a mild, nutty flavor and no metallic aftertaste. Catalina spinach plants are vigorous, productive, and long-lasting, ensuring a plentiful and delicious harvest. Its crisp texture and delightful taste make it a standout choice for gardeners and salad enthusiasts alike."
      },
      {
        heading:"Space Spinach",
        description:"Space spinach features dark green, round, spoon-shaped leaves that are almost smooth and meaty, delivering rich flavor. The leaves are elevated off the ground, ensuring they remain clean. Versatile and robust, Space spinach can be enjoyed both raw and cooked. Ideal for early spring and late fall cultivation, this variety thrives in larger containers, making it a practical choice for gardeners looking to extend their harvest season while enjoying flavorful, nutritious greens."
      },
      {
        heading:"Red Carnival",
        description:"Red Carnival spinach stands out with its oval to slightly oblong leaves, featuring a rounded arrowhead tip. This variety showcases a deep green color with striking maroon veins and beet-red stems. Its flavor is akin to traditional green spinach but with a subtle sweetness and earthy finish. The vibrant color and rich taste make Red Carnival a visually appealing and delicious addition to any dish, adding both beauty and flavor to your meals."
      },
      {
        heading:"Malabar Spinach",
        description:"Malabar spinach, popular in India and the tropics, boasts dark green leaves similar to common spinach. Thriving in hot temperatures, often exceeding 32°C, it’s well-suited for warm climates. Unlike traditional spinach, Malabar spinach prefers the heat and will creep when exposed to cooler temperatures. This robust variety adds a unique twist to gardens, offering both resilience and lush, flavorful greens even in challenging conditions."
      },
    ],
    varietyHeading:"Spinach Varieties We Export",
    verietyDesc:"India's top spinach trading partners are Canada, Australia, the United Arab Emirates, the USA, and Qatar, with a total export value of approximately $0.31 million. These countries appreciate the premium quality and diverse varieties of Indian spinach. Among the varieties exported are Savoy Spinach, Smooth-Leaf Spinach, and Baby Spinach, each known for their unique characteristics and culinary versatility. India's commitment to quality ensures that these varieties continue to be in high demand globally."
  },
  {
    heading: "Indian Cabbage Exporters",
    picture: cabbageBanner,
    link: "Cabbage",
    firstLayout: false,
    description: "Cabbage, a highly nutritious leafy vegetable, is gaining global popularity. As its use spans various cuisines and benefits, the Indian cabbage export market is thriving. With exports increasing year after year, cabbage has become a significant revenue source for many Indian exporters. The growing demand and diverse applications ensure that cabbage import from India will continue to rise. Embrace the trend and explore the expanding opportunities in the global cabbage market!",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 25","Carbohydrates - 6g","Dietary Fibre - 2.5 g"]
      },
      {
        title:"Protein",
        value:["1.3g"]
      },
      {
        title:"Fat",
        value:["0.1g"]
      },
      {
        title:"Vitamin",
        value:["Vitamin C, Vitamin K, Vitamin B6"]
      },
      {
        title:"Minerals",
        value:["Thiamin","Calcium","Iron","Magnesium","Phosphorous","Potassium"]
      },
    ],
    benifits: [],
    outro: "If you're seeking a reliable cabbage importer from India, JNTB EXPORT IMPORT is your ideal partner! With extensive experience in the food industry, JNTB EXPORT IMPORT excels in exporting a wide range of products, including spices, pulses, fruits, vegetables, and more. Our team is dedicated to guiding you through every step of the cabbage export process, from handling documentation and securing buyers to conducting detailed market analyses and managing permissions and licenses. Whether you're looking to export cabbage or other food products, JNTB EXPORT IMPORT ensures a smooth and successful experience. Choose JNTB EXPORT IMPORT for your export needs and unlock new opportunities in the global market!",
    varieties: [],
    varietyHeading:"Cabbage Varieties We Export",
    verietyDesc:"The cabbage exporter in India offers a diverse range of varieties, catering to global markets. Among the top varieties are the crunchy and versatile Green Cabbage, the nutrient-rich Red Cabbage, and the tender and delicate Savoy Cabbage. These varieties are prized for their quality and taste, making them popular choices for international buyers. As India's cabbage export continues to grow, these key varieties are at the forefront of satisfying global demand."
  },
  {
    heading: "Indian Brinjal Exporters",
    picture: brinjalBanner,
    link: "Brinjal",
    firstLayout: false,
    description: "India's fertile soil and favorable climate have made it a global leader in vegetable production, including Brinjal. As the second-largest producer of Brinjal, India has seen a steady growth in its export business. The Brinjal export process from India is meticulous, involving thorough documentation, stringent health and safety measures, and the careful selection of various Brinjal varieties. Indian Brinjals are in demand worldwide, known for their quality and diversity. With a focus on maintaining the highest standards, Indian Brinjal exporters ensure that only the best produce reaches international markets, contributing to India's growing reputation as a reliable supplier of fresh vegetables.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 25 calories","Carbs - 5 grams","Fibre - 3 grams"]
      },
      {
        title:"Protein",
        value:["1g"]
      },
      {
        title:"Vitamin",
        value:["Vitamin C, Vitamin K, Vitamin B6"]
      },
      {
        title:"Minerals",
        value:["Dietary Fiber","Calcium","Antioxidants"]
      },
    ],
    benifits: [],
    outro: "For those seeking a reliable Brinjal importer from India, JNTB EXPORT IMPORT is your top choice! As one of India's most trusted exporters, we specialize in a wide range of food products, including pulses, spices, grains, fruits, and vegetables. Our commitment to quality ensures that you receive fresh, healthy produce, no matter where you are in the world. When it comes to Brinjal export, JNTB EXPORT IMPORT stands out for its dedication to excellence and customer satisfaction. We handle every aspect of the export process with precision, ensuring that only the finest Brinjals reach your market. Choose JNTB EXPORT IMPORT for all your Brinjal importing needs and experience the best in Indian agriculture.",
    varieties: [
      {
        heading:"Pusa Purple Long",
        description:"The Pusa Purple Long Brinjal is an early-maturing variety, known for its long, glossy, light purple fruits. Each Brinjal typically measures 25-30 cm, offering a smooth and tender texture that’s highly valued in culinary uses. Ready for harvest within 100 to 110 days, this variety is favored for its quick growth and high yield. Pusa Purple Long is an excellent choice for both local markets and export, meeting the demand for premium-quality Brinjals."
      },
      {
        heading:"Pusa Purple Cluster",
        description:"The Pusa Purple Cluster Brinjal, developed by the Indian Agricultural Research Institute, New Delhi, is a high-yielding variety known for its resistance to bacterial wilt. This cluster-bearing variety produces medium-long, dark purple, oblong fruits that grow in clusters. Its early maturity allows for the first picking just 60-65 days after transplanting. Pusa Purple Cluster is ideal for farmers and exporters seeking a robust, disease-resistant Brinjal variety that delivers consistent, high-quality yields."
      },
      {
        heading:"Pusa Kranti",
        description:"The Pusa Kranti Brinjal, developed by IARI, New Delhi, features a dwarf and spreading growth habit, making it ideal for compact planting. The fruits are oblong and stocky, boasting an attractive dark purple color. This versatile variety is well-suited for both spring and autumn plantings, particularly in northern India. Pusa Kranti is a preferred choice for farmers seeking a resilient and visually appealing Brinjal variety that thrives in different seasons, ensuring consistent and high-quality produce."
      },
      {
        heading:"Pusa Barsati",
        description:"The Pusa Barsati Brinjal, introduced by PAU, Ludhiana, is a dwarf and erect variety, notable for its thornless growth. Its medium-long fruits have a striking purple color, making them visually appealing in the market. With an impressive average yield of 35.5 t/ha, Pusa Barsati is a highly productive option for farmers. This variety’s robust growth and high yield potential make it an excellent choice for those looking to maximize their Brinjal harvests."
      },
      {
        heading:"Manjri Gota",
        description:"The Manjiri Gota Brinjal is characterized by its dwarf, spreading growth habit. Its medium-large, round fruits display a unique purple color with white stripes, transitioning to a golden yellow upon maturity. This visually distinctive variety is not only attractive but also productive, with an average yield of 15-20 t/ha. Manjiri Gota Brinjal offers farmers a reliable crop with appealing aesthetics, making it a popular choice for both local markets and export opportunities."
      },
      {
        heading:"Arka Navneet",
        description:"The Arka Navneet is a high-yielding hybrid Brinjal variety, known for its large, oval to oblong fruits with a deep purple, glossy skin. Each fruit weighs around 450 grams, making it an impressive option for markets. This variety is ready for harvest in approximately 150-160 days, offering an exceptional average yield of 65-70 t/ha. Arka Navneet is a top choice for farmers seeking a robust, high-producing Brinjal variety with significant market appeal."
      },
      {
        heading:"Arka Sheel",
        description:"Arka Sheel Brinjal is a medium-long variety featuring deep, shining purple skin and a fleshy green calyx. The plant is distinguished by its purple flowers and solitary bearing habit. With a harvest time of approximately 150-160 days, Arka Sheel offers a reliable and consistent yield, averaging around 38 t/ha. This variety's appealing appearance and productive nature make it a strong choice for farmers looking to grow high-quality Brinjals with excellent market potential."
      },
      {
        heading:"Arka Kusmukar",
        description:"Released by IIHR, Bangalore, the Arka Kusumukar Brinjal is noted for its spreading growth habit and distinctive green stem and leaves. This variety produces small, green fruits that grow in clusters. Renowned for their soft texture and excellent cooking quality, Arka Kusumukar Brinjals offer a delightful culinary experience. With an average yield of 40 t/ha, this variety provides both high productivity and superior fruit quality, making it a favorite among farmers and chefs alike."
      },
      {
        heading:"Pusa Ankur",
        description:"Pusa Ankur Brinjals are small, oval-round fruits known for their glossy, eye-catching appearance. Weighing approximately 60-80 grams each, these early-bearing Brinjals are ready for the first harvest just 45 days after transplanting. Notably, they retain their vibrant color and tender texture even with delayed picking, making them a reliable choice for consistent quality. Pusa Ankur combines quick maturation with enduring freshness, ensuring a delightful and attractive product for both farmers and markets."
      },
      {
        heading:"Arka Nidhi",
        description:"Arka Nidhi is a high-yielding Brinjal variety renowned for its resistance to bacterial wilt. This variety features fruits borne in clusters, with a distinctive purplish-green calyx. Ready for harvest about 150 days after transplanting, Arka Nidhi delivers an impressive average yield of 48 t/ha. Its robust disease resistance and productive nature make it an excellent choice for farmers seeking both quality and quantity in their Brinjal crops, ensuring a reliable and rewarding harvest."
      },
      {
        heading:"Arka Keshav",
        description:"Released by IIHR, Bangalore, Arka Keshav is a high-yielding Brinjal variety with excellent bacterial wilt resistance. Known for its tender fruits, Arka Keshav is free from bitter principles and features well-matured seeds. The crop reaches maturity and is ready for picking in approximately 150 days, delivering an impressive average yield of 45 t/ha. Arka Keshav combines disease resistance with high productivity, making it a top choice for farmers seeking both quality and quantity in their Brinjal harvest."
      },
    ],
    varietyHeading:"Brinjal Varieties We Export",
    verietyDesc:"Indian Brinjal exporters offer a diverse range of Brinjal varieties, catering to global demand. Among the top varieties exported are Pusa Purple Long, known for its elongated shape and rich color; Pusa Kranti, favored for its resilience and flavor; and Pusa Barsati, appreciated for its adaptability and taste. These high-quality varieties make Indian Brinjal a sought-after product in international markets, ensuring that consumers worldwide enjoy the best of India’s agricultural produce."
  },
  {
    heading: "Indian Cauliflower Exporters",
    picture: cauliflowerBanner,
    link: "Cauliflower",
    firstLayout: false,
    description: "Cauliflower, a versatile vegetable from the Brassica oleracea species, is a staple in global cuisines. Indian cauliflower exporters have been making waves, sending tons of this nutritious vegetable to markets worldwide. The export of cauliflower from India has seen a significant rise in recent years, driven by its increasing popularity and demand. This surge has turned cauliflower import from India into a lucrative trade, reflecting the high value and appeal of Indian-grown produce. With its crisp texture and culinary flexibility, cauliflower continues to be a sought-after item, enhancing India's reputation as a leading exporter of fresh, high-quality vegetables.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 25 calories","Dietary Fiber - 2 g","Carbohydrate 5 g"]
      },
      {
        title:"Protein",
        value:["22g"]
      },
      {
        title:"Vitamins",
        value:["Vitamin C","Vitamin K"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Potassium"]
      },
      {
        title:"Fat",
        value:[]
      },
    ],
    benifits: [],
    outro: "JNTB EXPORT IMPORT stands out as one of India's premier cauliflower importers, specializing in exporting top-quality food products to a global clientele. Our comprehensive services go beyond just exporting; we assist our clients in finding buyers, conducting thorough product analyses, and providing end-to-end support to ensure safe and timely delivery. With our expertise and dedication, we make the cauliflower export process seamless and efficient. If you're seeking a reliable partner for cauliflower export, JNTB EXPORT IMPORT is here to provide exceptional service and ensure your products reach international markets with ease. Trust us to handle every detail and deliver excellence from start to finish!",
    varieties: [
      {
        heading:"Pusa Deepali",
        description:"Pusa Deepali, recommended for Northern India, especially Delhi and Punjab, is an early-maturing cauliflower variety. Known for its compact, self-blanching white curds, this medium-sized cauliflower is prized for being nearly free from riceyness. With an impressive average yield of around 12 t/ha, Pusa Deepali combines quality and productivity, making it a popular choice among farmers. Its reliable performance and superior characteristics ensure it stands out in the cauliflower market."
      },
      {
        heading:"Early Kunwari",
        description:"Early Kunwari is an ideal cauliflower variety for Haryana, Punjab, and Delhi, renowned for its early maturity. The curds are hemispherical with a smooth, even surface, making them visually appealing. This variety is typically ready for harvest from mid-September to mid-October, offering a timely crop for early-season markets. With an average yield of around 8 t/ha, Early Kunwari delivers both quality and efficiency, making it a top choice for growers seeking an early, reliable cauliflower option."
      },
      {
        heading:"Pant Shubhra",
        description:"Pant Shubhra is a top choice for cauliflower cultivation in Northern India, valued for its early growth. This variety features compact, slightly conical curds with a creamish white color. Ready for harvest typically in November, Pant Shubhra offers a high average yield of around 20 t/ha. Its robust performance and attractive curds make it a favorite among farmers, ensuring a bountiful and visually appealing cauliflower crop that meets market demands with ease."
      },
      {
        heading:"Sonwball-16",
        description:"Snowball-16 thrives in the cooler climates of North Indian states, making it ideal for late-season cultivation. This variety is known for its medium-sized, solid white curds, which are highly valued for their quality. Ready for harvest from January to March, Snowball-16 offers an impressive average yield of 25-30 t/ha. Its robustness in cooler weather and high yield make it a standout choice for farmers looking to maximize their cauliflower production during the winter months."
      },
      {
        heading:"Pant Gobhi-2",
        description:"Pant Gobhi-2 is an early-maturing cauliflower variety known for its compact, composite curds with a creamy white color. Ideal for quick harvests, this variety is ready from November to December, making it a perfect choice for early-season markets. With an average yield of around 12 t/ha, Pant Gobhi-2 combines speedy growth with reliable productivity, ensuring farmers get a high-quality crop in a shorter time frame. Its appealing curds and efficient harvesting make it a popular selection."
      },
      {
        heading:"Dania Kalimpong",
        description:"Dania Kalimpong is a favored cauliflower variety in eastern India, known for its late-season growth. This variety produces medium-large, compact, and attractive white curds that are less sensitive to environmental fluctuations. Ready for harvest from January to April, Dania Kalimpong offers consistent quality and reliable performance throughout its growing season. Its resilience and appealing curds make it a top choice for farmers seeking a robust cauliflower variety that thrives in diverse conditions."
      },
      {
        heading:"Pusa Snowball-1",
        description:"Pusa Snowball-1 is a late-maturing cauliflower variety renowned for its compact, medium-sized, snow-white curds. Ideal for harvesting from January to April, it delivers an impressive average yield of 25-30 t/ha. While it boasts excellent quality and productivity, growers should be mindful of its susceptibility to black rot. Despite this, Pusa Snowball-1 remains a popular choice for its attractive, high-yielding curds, making it a valuable addition to any cauliflower cultivation."
      },
      {
        heading:"Punjab Giant-35",
        description:"Punjab Giant-35 is a key variety for the main cauliflower season, known for its white, compact, and medium-sized curds. Ready for harvest from mid-November to December, this variety provides a reliable crop with an average yield of around 17 t/ha. Its robust performance and attractive curds make Punjab Giant-35 a preferred choice for farmers aiming for quality and consistency during the peak season. Enjoy the benefits of high-yielding, visually appealing cauliflower with this dependable variety."
      },
    ],
    varietyHeading:"Cauliflower Varieties We Export",
    verietyDesc:"Indian cauliflower exporters offer a range of premium varieties, including Pusa Deepali, Early Kunwari, and Pant Shubra, among others. These high-quality varieties are carefully selected and exported while adhering to stringent food safety regulations. With a focus on maintaining top standards, Indian exporters ensure that each cauliflower shipment meets global quality expectations. The export of these premium varieties highlights India’s commitment to delivering fresh, top-notch produce to international markets."
  },
  {
    heading: "Indian Drumstick Exporters",
    picture: drumstickBanner,
    link: "Drum Stick",
    firstLayout: false,
    description: "Drumstick, or Moringa, is celebrated for its long, smooth-skinned, dark green pods with a distinctive three-sided shape. Indian Drumstick exporters are meeting the rising global demand by shipping tonnes of this nutritious vegetable each year. Renowned for its health benefits and versatility in various recipes, Drumstick's popularity has significantly boosted its export from India. This surge in demand highlights the growing business opportunities in Drumstick import, as more countries recognize its value. With its impressive nutritional profile and culinary flexibility, Drumstick remains a highly sought-after product in international markets, driving the expansion of its export and import opportunities.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 64 calories","Dietary Fiber - 2 g","Carbohydrate 8.3 g"]
      },
      {
        title:"Protein",
        value:["9.4g"]
      },
      {
        title:"Vitamins",
        value:["Vitamin C","Vitamin K","Vitamin B12","Vitamin A","Vitamin B"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium","Niacin","Riboflavin"]
      },
      {
        title:"Fat",
        value:[]
      },
    ],
    benifits: [],
    outro: "If you're searching for a reliable Drumstick importer in India, JNTB EXPORT IMPORT is your top choice! At JNTB EXPORT IMPORT, we offer comprehensive support throughout the entire export process, ensuring a seamless experience from start to finish. Our dedicated team assists in finding buyers for your products, managing all logistics, and overseeing the final delivery to ensure your Drumsticks reach their destination safely and on time. With our expertise and commitment to excellence, JNTB EXPORT IMPORT makes exporting Drumsticks hassle-free and efficient. Simply drop us a message, and we'll handle the rest, providing you with exceptional service every step of the way!",
    varieties: [
      {
        heading:"KM-1 (Kudumianmalai 1)",
        description:"The KM-1 Drumstick variety is a bushy plant propagated from seeds, thriving with a productive lifespan of 2-3 years. Developed at Anna Pannai, Kudumianmalai in Pudukkottai, KM-1 begins bearing fruit approximately 6 months after planting. With an impressive productivity of around 400-500 fruits per year, this variety is valued for its consistent yield and quality. KM-1 stands out as a reliable choice for both local cultivation and international export, offering high returns and robust performance."
      },
      {
        heading:"PKM – 1",
        description:"The PKM-1 Drumstick variety, developed at the Horticultural Research Station, TNAU, Periyakulam, is propagated through seeds. This variety produces plants that grow to a height of 4-6 meters and begin flowering around 90-100 days after planting. The first harvest is typically ready 160-170 days post-planting, offering a timely and productive yield. PKM-1 stands out for its robust growth and efficient harvest cycle, making it a popular choice for both cultivation and export."
      },
      {
        heading:"PKM 2",
        description:"Developed at the Horticultural Research Station, TNAU, Periyakulam, the PKM-2 Drumstick variety is renowned for its extra-long, pulpy pods, measuring 125-130 cm. This variety is particularly suited for homesteads, offering a robust and high-yielding option for local cultivation. PKM-2's impressive pod length and pulp quality make it a standout choice for both home gardens and larger-scale production, providing both exceptional taste and productivity."
      },
      {
        heading:"Dhanraj",
        description:"Dhanraj is an annual Drumstick variety, propagated from seeds and developed at KRC College of Agriculture, UAS, Arabhavi, Karnataka. Known for its high quality and productivity, Dhanraj is a popular choice for export to numerous countries worldwide. Its robust growth and superior pod characteristics make it a favored option in international markets, showcasing India's commitment to providing top-notch Drumstick varieties."
      },
      {
        heading:"Jaffna moringa",
        description:"Jaffna Moringa is a perennial Drumstick variety renowned for its impressive 60-90 cm long pods. This variety is highly sought after for export due to its tender flesh and excellent flavor. Jaffna Moringa’s robust growth and superior taste make it a standout choice in international markets, offering a high-quality option for Drumstick enthusiasts and consumers around the world. Its consistent performance and delightful taste ensure it remains a favorite among global buyers."
      },
      {
        heading:"Chavakacheri muringa",
        description:"Chavakacheri Muringa is a premium Drumstick variety exported globally for its exceptional quality. This perennial plant produces impressive pods ranging from 90-120 cm in length. Valued for its robust growth and high yield, Chavakacheri Muringa stands out in international markets. Its long, tender pods make it a popular choice among exporters and consumers, showcasing the finest characteristics of Drumstick cultivation. With its impressive size and flavor, Chavakacheri Muringa is a top contender in the global Drumstick trade."
      },
      {
        heading:"Chemmuringa",
        description:"Chemmuringa is a perennial Drumstick variety known for its year-round flowering and distinctive red-tipped fruits. This vibrant variety is highly valued in the global market and exported from India by numerous vendors. With its continuous bloom and striking fruit color, Chemmuringa stands out as a premium choice for international buyers. Its exceptional quality and unique appearance make it a sought-after option, showcasing India’s commitment to delivering top-tier Drumstick varieties worldwide."
      },
      {
        heading:"Pal muringai",
        description:"Pal Muringai is a standout Drumstick variety from India, perfect for export. Its pods feature thickened pulp, delivering a superior taste compared to other Drumstick varieties. This enhanced flavor profile makes Pal Muringai a preferred choice for international markets. With its rich taste and robust quality, it highlights India's dedication to providing premium Drumsticks worldwide. Pal Muringai’s exceptional characteristics ensure it remains a top pick among global buyers seeking high-quality Drumstick options."
      },
      {
        heading:"Puna muringa",
        description:"Puna Muringa is a sought-after Drumstick variety exported from India to numerous countries worldwide. Known for its thinner pods compared to other Drumstick varieties, Puna Muringa offers a unique option for international markets. Its distinctive shape and quality make it a favorite among global buyers, showcasing India's diverse Drumstick offerings. With its slim, high-quality pods, Puna Muringa stands out in the global trade, ensuring a premium choice for those seeking a refined Drumstick variety."
      },
      {
        heading:"Kodikal muringa",
        description:"Kodikal Muringa is a Drumstick variety known for its short pods, typically measuring 15-20 cm in length. This versatile variety is not only prized for its quality but also serves as support for betel vine plants, making it a dual-purpose crop. Propagated by seeds, Kodikal Muringa stands out for its compact size and functional benefits, appealing to both agricultural and horticultural applications. Its unique characteristics make it a valuable choice for diverse cultivation needs."
      },
    ],
    varietyHeading:"Drumstick Varieties We Export",
    verietyDesc:"Indian Drumstick exporters offer a range of premium varieties, including KM-1, PKM-1, PKM-2, and Dhanraj. Each variety is carefully selected for its quality and nutritional value, ensuring that only the best Drumsticks are exported to global markets. These top-notch varieties highlight India's commitment to delivering fresh, high-quality Drumsticks worldwide. With their diverse options, Indian exporters meet international demand and provide customers with the best of what Drumstick cultivation has to offer."
  },
  {
    heading: "Indian Lady Finger Exporter",
    picture: ladyfingerBanner,
    link: "Lady Finger",
    firstLayout: true,
    description: "Ladyfinger, also known as okra, is a vibrant green vegetable with a distinctive finger-like shape and a small tip at the tapering end. Renowned for its numerous health benefits and nutritional value, Ladyfinger is becoming increasingly popular worldwide. This surge in global demand has led to a significant rise in Ladyfinger exports from India. The vegetable is sourced from key producing states such as Gujarat, Madhya Pradesh, West Bengal, Bihar, and Orissa. These regions are known for their high-quality Ladyfinger, contributing to its growing presence in international markets. The increase in Ladyfinger imports from India reflects its rising popularity and the commitment of Indian exporters to meet global needs with fresh, top-notch produce.",
    marketDemand: "The global demand for ladyfingers, or okra, is soaring, creating a prime opportunity for Indian exporters to enter international markets. With this growing interest, Indian vendors and farmers are positioned to capitalize on the surge in ladyfinger imports, potentially earning substantial revenues. The rise in global demand underscores the importance of exporting premium quality ladyfingers from India, ensuring that the best produce reaches international buyers. As the market for ladyfingers expands, Indian exporters can make a significant impact on the global stage, showcasing their top-tier products and contributing to the country’s growing presence in the international agricultural trade.",
    specifications: [
      {
        title:"Product Name",
        value:"Lady Finger"
      },
      {
        title:"Origin",
        value:"West African, Ethiopian, and South Asian origins"
      },
      {
        title:"Family",
        value:"Malvaceae"
      },
      {
        title:"Binomial name",
        value:"Abelmoschus Esculentus"
      },
    ],
    ingredients: [],
    benifits: ["Lady Finger, rich in carotenoids, is excellent for boosting eye health. These essential nutrients support strong vision and help maintain optimal eye function, making Lady Finger a valuable addition to a vision-enhancing diet.","Carotenoids are fantastic for skin health, helping to protect against UV damage. These powerful antioxidants reduce skin damage caused by sunlight, promoting a healthier, more resilient complexion and keeping your skin looking vibrant and youthful.","Lady Finger boosts immunity with its rich nutrient profile and supports weight loss thanks to its high fiber content. This versatile vegetable aids in maintaining overall health while helping you stay fit and resilient against illnesses.","Lady Finger helps reduce the risk of colorectal cancer by keeping the intestinal tract healthy. Its bulk laxative properties aid in lubricating the large intestine, promoting smooth digestion and supporting overall colorectal health."],
    outro: "JNTB EXPORT IMPORT is a leading importer of Lady Finger from India, dedicated to ensuring timely deliveries and seamless service. Navigating the complexities of Lady Finger export can be challenging, but with JNTB EXPORT IMPORT, you’re in good hands. We provide comprehensive support at every stage, from meticulous documentation to the final delivery of your order. Our expert team ensures that your Lady Finger export process is smooth and efficient, offering peace of mind and top-notch service. If you’re seeking a reliable partner for food product exports, JNTB EXPORT IMPORT is here to help. Reach out to us today and experience hassle-free export solutions!",
    varieties: [],
  },
  {
    heading: "Indian Green Chilli Exporter",
    picture: greenChilleBanner,
    link: "Green Chilli",
    firstLayout: true,
    description: "Green chillies are essential to Indian cuisine, renowned for their distinctive flavor and aroma. Indian exporters play a crucial role, shipping tonnes of green chillies to global markets each year. This rising global popularity of green chillies offers significant opportunities for local farmers and vendors to tap into international trade. With a diverse range of types and qualities available, green chilli export from India is a thriving business, enabling local producers to showcase their premium products worldwide. As demand continues to grow, the green chilli import sector eagerly anticipates high-quality offerings from India, making it an excellent avenue for expanding into the global market.",
    marketDemand: "The global demand for green chillies and their products is on the rise, driven by their unique flavor and notable health benefits. This growing popularity has positioned Indian exporters to supply premium quality green chillies to markets around the world. As a result, the trend of green chilli imports from India is expected to increase in the coming years. Indian green chillies are celebrated for their freshness and quality, making them a sought-after ingredient in international cuisines. With this expanding global interest, both exporters and importers are poised to benefit from a robust and thriving trade in green chillies.",
    specifications: [
      {
        title:"Product Name",
        value:"Green Chillies"
      },
      {
        title:"Origin",
        value:"Mexico"
      },
      {
        title:"Family",
        value:"Solanaceae"
      },
      {
        title:"Binomial name",
        value:"Capsicum annuum"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 40","Total Carbohydrate 9 g","Dietary Fiber 1.5 g"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin C","Vitamin D","Vitamin B6"]
      },
      {
        title:"Protein",
        value:["2 g"]
      },
      {
        title:"Fats",
        value:["0g"]
      },
    ],
    benifits: ["Chillies, rich in vitamin C and beta-carotene, are excellent for skin health. Their potent nutrients help keep your skin vibrant and glowing, promoting overall wellness and a healthy complexion. Spice up your diet for radiant skin!","If you're dealing with iron deficiency, adding chillies to your diet is a flavorful way to boost your iron levels. These vibrant peppers are a natural source of iron, enhancing both the taste of your meals and your overall health.","Green chillies can help balance blood sugar levels, making them a smart choice for diabetics. Incorporating chillies into your diet not only adds flavor but also supports a healthier, more enjoyable lifestyle. Spice up your meals and manage diabetes effectively!","Chillies boost your immune system, helping to fend off common colds and sinus infections. By adding chillies to your diet, you can enhance your body's natural defenses and enjoy better health with every spicy bite."],
    outro: "If you’re searching for a top-notch Green Chilli importer in India, look no further than JNTB EXPORT IMPORT! Navigating the complexities of green chilli export can be challenging, but with JNTB EXPORT IMPORT, you’re in expert hands. We guide you through every step of the export process, from initial documentation to the final delivery of your order. Our dedicated team is well-trained and prepared to handle any issues that may arise, ensuring a smooth and efficient experience. Ready to export green chillies with ease? Contact JNTB EXPORT IMPORT today and let us simplify the process for you!",
    varieties: [],
  },
  {
    heading: "Indian Bottle Gourd Exporters",
    picture: laukiBanner,
    link: "Doodhi",
    firstLayout: false,
    description: "Bottle gourd, also known as Calabash, is a versatile vegetable with white flowers that can be harvested young for consumption. Its increasing popularity is driven by its impressive health and nutritional benefits. Each year, Indian exporters ship tonnes of bottle gourd to international markets, reflecting the growing global demand. This rise in export is a testament to the vegetable’s appeal and nutritional value. The export of bottle gourd from India encompasses various varieties, catering to diverse tastes and preferences worldwide. As the demand for this beneficial vegetable continues to grow, bottle gourd imports from India are set to rise, offering fresh and high-quality produce to global consumers.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 15 calories","Dietary Fiber - 1.2 g","Carbohydrate 5 g"]
      },
      {
        title:"Protein",
        value:["0.6g"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin C","Vitamin D","Vitamin B6"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium"]
      },
      {
        title:"Fat",
        value:[]
      },
    ],
    benifits: [],
    outro: "If you're seeking a reliable Bottle Gourd importer in India, JNTB EXPORT IMPORT is your top choice! With our extensive experience in the food industry, we’re here to simplify the export process for you. From meticulous documentation to the final delivery, our dedicated team handles every step with precision. Equipped with the latest tools and trained to address any issues that may arise, we ensure a smooth and efficient export experience. Whether you're looking to export bottle gourd from India or need expert guidance, JNTB EXPORT IMPORT is ready to assist you. Reach out to us today and let’s make your bottle gourd export seamless and successful!",
    varieties: [
      {
        heading:"PMK1",
        description:"The PMK variety of Bottle Gourd is an advanced induced mutant of H375, boasting a remarkable yield potential of 25t/ha in just 135-140 days. This variety is known for its extra-long fruits, reaching lengths of 180-200 cm. Ideal for both commercial and home cultivation, PMK delivers impressive size and quality, making it a standout choice for bottle gourd exports."
      },
      {
        heading:"CO.1",
        description:"The CO.1 variety of Bottle Gourd, selected from germplasm types, features distinctive fruits with a prominent bottleneck at the top. These fruits are pale green and offer a robust yield of approximately 25-30t/ha. Known for its unique shape and high productivity, CO.1 is an excellent choice for quality bottle gourd exports, ensuring both visual appeal and substantial returns."
      },
      {
        heading:"Pusa Naven",
        description:"This variety of Bottle Gourd features long, straight, and bottle-shaped fruits with a light green hue. It reaches maturity for the first harvest in just 60-65 days. Ideal for export, the seeds are well-suited for long-distance transport, ensuring they retain their quality. This variety's excellent shelf life and distinctive shape make it a top choice for both domestic and international markets, meeting high standards of freshness and durability."
      },
      {
        heading:"Arka Bahar",
        description:"Arka Bahar, a premier selection from Karnataka, boasts vigorous vines and lush dark green foliage with a smooth leaf texture. This variety produces medium-long, straight fruits without any crook neck. The bottle gourds have a light green, shiny skin, highlighting their premium quality. Ideal for various climates and export conditions, Arka Bahar offers both robust growth and appealing aesthetics, making it a standout choice for fresh and durable produce."
      },
      {
        heading:"Summer Prolific Round",
        description:"The Summer Prolific Round Bottle Gourd variety, popular in Himachal Pradesh, is known for its round, robust fruits. With a diameter of 15-18 cm, these gourds are perfect for farming in the region's summer climate. Their uniform shape and substantial size make them a favorite for both local markets and export. Ideal for versatile use, this variety ensures high-quality produce with consistent results, catering to diverse culinary and market needs."
      },
      {
        heading:"Summer Prolific Long",
        description:"The Summer Prolific Long Bottle Gourd, exported from India to various countries, is prized for its impressive length. Typically reaching 40-50 cm, these elongated fruits are sought after for their quality and versatility. Ideal for international markets, they stand out with their consistent size and shape, making them a top choice for global buyers. This variety ensures high yields and exceptional quality, reinforcing India's reputation as a leading exporter of premium bottle gourd varieties."
      },
      {
        heading:"Pusa Manjiri",
        description:"Pusa Manjiri is a standout F1 hybrid Bottle Gourd, resulting from the cross between PSPR and Sel.11. Known for its round, light green fruits, this variety offers both aesthetic appeal and quality. Ideal for diverse culinary uses, Pusa Manjiri ensures a consistent, attractive product with excellent taste and texture. Its hybrid vigor makes it a preferred choice among growers and exporters, highlighting India’s commitment to providing top-tier bottle gourd varieties to the global market."
      },
      {
        heading:"Pusa Meghdoot",
        description:"Pusa Meghdoot is a premier Bottle Gourd variety exported by Indian vendors worldwide. This F1 hybrid, developed from the cross between PSPL and Sel.2, boasts long, light green fruits. Renowned for its quality and consistency, Pusa Meghdoot is a top choice for global markets, ensuring premium produce with a vibrant appearance. Its impressive length and appealing color make it a favorite among consumers, reflecting India's excellence in bottle gourd cultivation and export."
      },
      {
        heading:"Samrat",
        description:"The Samrat variety of Bottle Gourd features cylindrical, crook-neck-free fruits that are greenish-white. Typically 30-40 cm long and weighing around 700-800 g, these bottle gourds stand out for their consistent quality and size. With an impressive yield of about 43 t/ha, the Samrat variety is a top choice for farmers and exporters. Its vibrant color and substantial size make it a valuable addition to the global market, showcasing the best of Indian bottle gourd cultivation."
      },
    ],
    varietyHeading:"BOTTLE GOURD VARIETIES WE EXPORT",
    verietyDesc:"The Bottle Gourd exporter in India ships a range of premium varieties, including PMK1, CO.1, Pusa Naveen, and Arka Bahar. These top-quality bottle gourds are carefully selected to meet international standards. The export of bottle gourd from India features these key varieties, showcasing the country's dedication to providing fresh and nutritious produce globally. Explore the best in bottle gourd export from India with these renowned varieties!"
  },
  {
    heading: "Indian Bitter Gourd Exporters",
    picture: bitterGourdBanner,
    link: "Bitter Gourd",
    firstLayout: false,
    description: "Bitter Gourd, or Bitter Melon, is a globally cultivated fruit, renowned for its role in Asian cuisine. Indian Bitter Gourd exporters play a vital role in supplying this unique vegetable to international markets. The rising global demand for Bitter Gourd, driven by its numerous culinary uses and health benefits, has led to a significant increase in export volumes from India. This trend offers Indian vendors and farmers a lucrative opportunity to expand their business and tap into global markets. With its distinctive flavor and versatile applications, Bitter Gourd export from India continues to thrive, presenting a promising avenue for growth and international success.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 34 calories","Dietary Fiber - 1.9 g","Total Carbohydrate - 7 g"]
      },
      {
        title:"Protein",
        value:["3.6g"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin C"]
      },
      {
        title:"Minerals",
        value:["Folate","Potassium","Zinc","Iron"]
      },
      {
        title:"Fat",
        value:[]
      },
    ],
    benifits: [],
    outro: "JNTB EXPORT IMPORT stands out as a premier Bitter Gourd exporter from India, committed to delivering excellence. Navigating the complexities of the export process can be daunting, but with JNTB EXPORT IMPORT by your side, you can rest easy. Our extensive experience in the food export industry ensures that your Bitter Gourd arrives fresh, safe, and on time. We handle every detail of the export process with care, from documentation to final delivery. If you’re seeking reliable Bitter Gourd imports from India, JNTB EXPORT IMPORT is your trusted partner. Contact us today and let our expertise work for you!",
    varieties: [
      {
        heading:"Pusa Do Mausmi",
        description:"The Pusa Do Mausmi Bitter Gourd thrives in spring-summer and the rainy season. It produces dark green, club-shaped fruits with 7-8 continuous ridges. These medium-long, thick gourds are ready for harvest approximately 55 days after sowing. Ideal for diverse culinary uses, this variety offers a vibrant color and robust flavor, making it a popular choice in both domestic and international markets."
      },
      {
        heading:"Coimbatore Long",
        description:"The Coimbatore Long Bitter Gourd variety features long, tender fruits with a distinctive white color. Ideal for the rainy season, this variety offers a robust yield of approximately 25-30 t/ha. Its tender texture and unique hue make it a sought-after choice for both local and international markets. Perfectly suited for diverse culinary applications, Coimbatore Long stands out for its quality and resilience, ensuring a premium product for consumers worldwide."
      },
      {
        heading:"Akra Harit",
        description:"The Akra Harit Bitter Gourd variety features attractive, spindle-shaped fruits with a glossy green hue. These compact fruits are distinguished by their smooth, regular ribs. With a crop duration of about 100-110 days, Akra Harit delivers a solid average yield of 12 t/ha. Its appealing appearance and reliable performance make it a popular choice for both domestic and international markets, ensuring quality and consistency for consumers seeking premium Bitter Gourds."
      },
      {
        heading:"VK-1-Priya",
        description:"The VK-1-Priya Bitter Gourd variety boasts white, 35-40 cm long fruits and is known for its heavy bearing capacity. With the first picking occurring in about 60 days, VK-1-Priya stands out for its impressive yield of 50 fruits per plant. This variety’s robust production and striking appearance make it a top choice for growers and exporters, ensuring a steady supply of high-quality Bitter Gourds to meet global demand."
      },
      {
        heading:"Phule Priyanka",
        description:"The Phule Priyanka is a premium hybrid Bitter Gourd variety known for its dark green, tubercle-covered fruits. Measuring about 20-25 cm long, this variety offers a robust yield of 35-40 t/ha. Its impressive productivity and distinctive appearance make Phule Priyanka a standout choice for growers and exporters, ensuring a high-quality supply that meets global standards and demand."
      },
      {
        heading:"Phule Green Gold",
        description:"The Phule Green Gold Bitter Gourd boasts dark green, tubercle-covered fruits, each measuring 25-30 cm long. Renowned for its striking appearance and quality, this variety is ideal for export. Its vibrant color and consistent size make it a top choice for international markets, ensuring a premium product that meets global standards. Perfect for export, Phule Green Gold offers both visual appeal and excellent value for growers and distributors alike."
      },
      {
        heading:"Phule Ujwala",
        description:"The Phule Ujwala Bitter Gourd features dark green fruits, 18-20 cm long, adorned with tubercles. Known for its high yield of 30-35 t/ha, this variety excels in both appearance and productivity. Its consistent quality and vibrant color make Phule Ujwala an excellent choice for export markets. Ideal for international distribution, it offers a premium option for those seeking top-notch Bitter Gourds. This variety ensures a reliable supply and impressive export potential."
      },
      {
        heading:"Pride of Gujarat",
        description:"The Pride of Gujarat Bitter Gourd variety features small, round fruits with a distinctive whitish-green hue and a few white dabs on the skin. Weighing about 8-10 g each, these compact and visually appealing gourds offer both unique color and texture. Their distinctive look and quality make them a standout choice for export, catering to markets seeking premium, eye-catching produce. Ideal for those aiming to impress with their Bitter Gourd selection, this variety ensures a notable presence in international markets."
      },
    ],
    varietyHeading:"Bitter Gourd Varieties We Export",
    verietyDesc:"The Bitter Gourd exporter in India offers a range of varieties, including Pusa Do Mausmi, Coimbatore Long, and Akra Harit, among others. Each variety is selected for its superior quality and compliance with international health and safety standards. These premium Bitter Gourds are meticulously cultivated and exported to meet global demand. Explore the top varieties that define the Bitter Gourd export from India and ensure your access to the finest produce available."
  },
  {
    heading: "Indian Cucumber Exporters",
    picture: cucumberBanner,
    link: "Cucumber",
    firstLayout: false,
    description: "Cucumber, a versatile creeping vine vegetable, is extensively cultivated and exported from India. With its numerous culinary and health benefits, the demand for Indian cucumbers has surged globally. As a result, Indian cucumber exports have grown significantly, presenting an excellent business opportunity for local vendors and farmers. The rise in global demand underscores the value of India's high-quality cucumbers, making it a prime export product for expanding your international reach.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 45 calories","Dietary Fiber - 2 g","Total Carbohydrate - 11 g"]
      },
      {
        title:"Protein",
        value:["2g"]
      },
      {
        title:"Vitamins",
        value:["Vitamin C","Vitamin K"]
      },
      {
        title:"Minerals",
        value:["Magnesium","Potassium","Manganese"]
      },
      {
        title:"Fat",
        value:[]
      },
    ],
    benifits: [],
    outro: "JNTB EXPORT IMPORT is a top choice for cucumber exports from India. We guide you through every step of the export process, ensuring a seamless experience with minimal hassle. Our dedicated team adheres to stringent health and safety regulations, guaranteeing that your cucumbers are delivered fresh and safely. Trust JNTB EXPORT IMPORT for reliable and efficient cucumber import services. Ready to expand your market? Contact JNTB EXPORT IMPORT today for expert assistance!",
    varieties: [
      {
        heading:"Ashley",
        description:"Ashley is a prized heirloom cucumber variety known for its prolific production of dark green, six to eight-inch fruits. With a slight taper at the stem end, these cucumbers are ready for harvest in about 65 days. Resistant to downy mildew, Ashley thrives in hot and humid conditions, making it a top choice for growers seeking resilience and quality."
      },
      {
        heading:"Japanese Long Green",
        description:"Japanese Long Green cucumbers are renowned for their sweet, crisp taste and easy digestibility. Growing up to a foot long with minimal seeds, they’re a favorite in home gardens for their mild flavor. Ideal for a fall harvest, a second planting in early summer ensures a bountiful yield. This variety stands out as one of the finest Japanese cucumbers, offering both quality and versatility for gardeners."
      },
      {
        heading:"Pusa Sanyog",
        description:"Pusa Sanyog is an F1 hybrid cucumber, blending Japanese Gynoecious and Green Long of Naples lines. Known for its early maturity and high yield, this variety produces cylindrical, dark green fruits with striking yellow stripes. With crisp flesh and a rapid maturation period of around 50 days, Pusa Sanyog is a standout choice for growers seeking both quality and efficiency."
      },
      {
        heading:"Pant Khira 1 (PCUC 28)",
        description:"Pant Khira 1, released in 2001, is a superior cucumber variety developed from indigenous germplasm at Pantnagar. This variety features cylindrical fruits, about 20 cm long, with light white stripes. Known for its robust yield of approximately 150 q/ha, Pant Khira 1 combines high productivity with attractive fruit appearance, making it a top choice for cucumber cultivation."
      },
      {
        heading:"Pant Sankar Khira 1",
        description:"Pant Sankar Khira 1, a hybrid developed at Pantnagar and released in 1999, combines PCUC 28 and PCUC 8. This high-yielding variety produces cucumbers approximately 20 cm long, with an impressive yield potential of about 200 q/ha. Its robust performance makes it a standout choice for growers seeking top quality and productivity."
      },
      {
        heading:"Bush Champion",
        description:"The Bush Champion cucumber is a compact, bush-style variety, ideal for container or raised bed gardening. It grows to just 20 cm in height with a spread of about 60 cm. Perfect for small spaces, this variety offers a productive harvest without requiring extensive garden area. Its space-saving nature and ease of cultivation make it a top choice for urban gardeners and those seeking a versatile cucumber option."
      },
      {
        heading:"Dasher II",
        description:"The Dasher II cucumber, scientifically known as *Cucumis sativus*, is renowned for its early production and high yield. This variety consistently produces dark green cucumbers, 8 to 10 inches long, making it one of the top performers in the market. Praised for its impressive yield and robust disease resistance, Dasher II is a standout choice for growers seeking reliable and high-quality cucumbers. Its resilience and productivity make it a preferred option for both commercial and home gardening."
      },
      {
        heading:"Diva",
        description:"The Diva cucumber variety is renowned for its sweet, crisp flavor, perfect for slicing or pickling. With its burpless, seedless fruits and thin, tender skin, it offers a delightful eating experience. Not only is Diva resistant to pests, but it also boasts impressive yields. This variety’s bitterness-free, easy-to-handle cucumbers make it a top choice for both fresh consumption and preservation, ensuring a consistently enjoyable addition to any garden or kitchen."
      },
      {
        heading:"Early Pride",
        description:"Early Pride cucumbers are known for their early harvest and extended production period. These dark green cucumbers, measuring 8.5' by 2', boast crisp, juicy flesh. For optimal growth, sow seeds 6' apart in rows or plant 5-6 seeds in groups spaced 4-5' apart. This method ensures robust development and abundant yields, making Early Pride a dependable choice for gardeners seeking a rewarding and consistent cucumber crop."
      },
      {
        heading:"Fanfare",
        description:"The Fanfare cucumber variety delivers high yields of crisp, 9-inch slicing cucumbers with a mild, less bitter flavor. Its compact 2-3-foot vines make it ideal for containers or small garden spaces. Additionally, Fanfare cucumbers are known for their robust disease resistance, ensuring a healthy and productive crop. Perfect for urban gardens and limited spaces, this variety combines convenience with quality for a delicious harvest."
      },
      {
        heading:"Poinsette",
        description:"The Poinsett cucumber variety is perfect for a plentiful harvest of dark green, straight cucumbers. These plants are renowned for their resilience, effectively resisting common diseases like mildews, anthracnose, scab, and angular leaf spot. With their robust health and high yield, Poinsett cucumbers offer both quality and durability, making them a top choice for a thriving garden. Enjoy a bumper crop with cucumbers that stand up to challenges and deliver excellent results."
      },
    ],
    varietyHeading:"Cucumber Varieties We Export",
    verietyDesc:"The cucumber exporter in India handles a range of premium varieties, including Ashley, Japanese Long Green, and Pusa Sanyog. Each variety is chosen for its quality and adherence to stringent health and safety standards. Exporting from India, these cucumbers are carefully selected to meet global demands and regulations. Here are some of the key varieties featured in the cucumber export from India."
  },
  {
    heading: "Indian Bell Pepper Exporters",
    picture: bellPaperBanner,
    link: "Bell Pepper",
    firstLayout: false,
    description: "Bell peppers, a versatile staple from the nightshade family, enjoy global popularity for their vibrant colors and crisp texture. Each year, the Indian Bell Pepper exporter ships tonnes of these peppers to international markets, reflecting their diverse culinary uses and nutritional benefits. As demand for bell peppers continues to rise, the export of Capsicum from India has seen significant growth. This surge presents an excellent business opportunity for local farmers and vendors to broaden their reach and tap into the global market. With a variety of bell pepper types included in the export, India’s bell pepper trade offers both quality and diversity to meet worldwide tastes and preferences.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 39 calories","Dietary Fiber - 3.1 g","Total Carbohydrate - 9 g"]
      },
      {
        title:"Protein",
        value:["3g"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin C","Vitamin E","Vitamin K1","Vitamin B6"]
      },
      {
        title:"Minerals",
        value:["Folate","Potassium"]
      },
      {
        title:"Fat",
        value:[]
      },
    ],
    benifits: [
      {
        heading:"California Wonder",
        description:"The California Wonder is a standout Bell Pepper variety exported from India, introduced by IARI. Renowned for its vibrant scarlet skin, this variety offers a crisp texture and rich flavor. Ideal for both fresh consumption and culinary use, the California Wonder ensures premium quality and eye-catching color. As a favorite among international markets, it reflects India’s commitment to excellence in bell pepper cultivation."
      },
      {
        heading:"Yolo Wonder",
        description:"The Yolo Wonder is a Bell Pepper variety introduced by IARI New Delhi. Known for its resilience, this variety is tolerant of Tobacco Mosaic Virus (TMV), making it a reliable choice for growers. However, it is sensitive to drought conditions, requiring careful water management. The Yolo Wonder produces high-quality, flavorful peppers, making it a popular choice for export from India. Its disease resistance and productivity make it valuable in the global market."
      },
      {
        heading:"Chinese Giant",
        description:"The Chinese Giant is a Bell Pepper variety exported from India to various countries. Introduced by Indo American Hybrid Seeds (IAHS) in Bangalore, this variety is known for its large size and vibrant dark green skin. The Chinese Giant is favored for its robust flavor and crisp texture, making it a popular choice in international markets. Its strong appeal and quality make it a significant contributor to India's bell pepper export industry."
      },
      {
        heading:"World Beater",
        description:"The World Beater variety of Bell Peppers is a unique hybrid developed by combining the size and productivity of the Chinese Giant with the shape and color of the Ruby King. This variety is known for its thick flesh and sweet flavor, with vibrant red peppers that stand out in any dish. The World Beater’s large, juicy bells make it a popular choice among consumers, adding to its demand in both domestic and international markets."
      },
      {
        heading:"Arka Mohini",
        description:"The Arka Mohini variety of Bell Peppers is known for its thick flesh and vibrant red color upon ripening. Each fruit typically weighs between 180-200 grams, making it a substantial addition to any dish. With a high yield potential of around 20 tons per hectare, this variety is a popular choice for both farmers and exporters. Its appealing color, size, and quality make Arka Mohini a sought-after variety in the international market."
      },
      {
        heading:"Arka Gurav",
        description:"The Arka Gurav variety of Bell Peppers is an indeterminate type known for its tolerance to bacterial wilt. This variety produces thick-fleshed fruits, each weighing between 130-150 grams. With a yield potential of approximately 16 tons per hectare, Arka Gurav is a reliable choice for growers looking for both quality and resilience. Its ability to withstand bacterial wilt makes it particularly valuable in regions where this disease is a concern, ensuring a steady and productive harvest."
      },
      {
        heading:"Arka Basant",
        description:"The Arka Basant variety of Bell Peppers is renowned for its excellent keeping and cooking qualities. The fruits of this variety are initially cream-colored and stand erect on the plant. As they ripen, they transition to a vibrant orange-red hue. This variety not only offers visual appeal but also retains its quality during storage and cooking, making it a popular choice for both fresh markets and culinary use. Arka Basant is a standout variety for those seeking durability and flavor."
      },
    ],
    outro: "JNTB EXPORT IMPORT stands out as a top Bell Pepper exporter from India, bringing extensive expertise in the food export industry. Our experienced team is dedicated to guiding you through every step of the bell pepper import process, from sourcing buyers to ensuring the safe and timely delivery of your products. We understand the complexities of international trade and provide comprehensive support to make your bell pepper export experience seamless and efficient. Whether you're seeking a reliable Bell Pepper importer from India or need assistance navigating the export process, JNTB EXPORT IMPORT is your trusted partner. Contact us today to explore how we can help grow your business in the global market!",
    varieties: [],
    varietyHeading:"Bell Pepper Varieties We Export",
    verietyDesc:"The Bell Pepper exporter in India offers a range of high-quality varieties including California Wonder, Yolo Wonder, and Chinese Giant. Each variety is carefully selected to meet stringent health and safety standards, ensuring premium quality for international markets. The Bell Pepper export from India features these key varieties, known for their vibrant colors and rich flavors. Trust in India's expertise for your bell pepper needs and enjoy the best of what the country has to offer."
  },
  {
    heading: "Indian Ridge Gourd Exporter",
    picture: ridgeGourdBanner,
    link: "Ridge Gourd",
    firstLayout: true,
    description: "Ridge Gourd, a ridged dark green vegetable with white pulp inside, has a taste similar to zucchinis. Rich in nutrients and minerals, Ridge Gourd offers numerous health and medicinal benefits. Due to its growing popularity, the Ridge Gourd exporter in India has been supplying increasing quantities to various countries worldwide. The rising demand has led to a significant boost in Ridge Gourd export from India, making it a promising opportunity for Indian farmers and vendors to expand their business in international markets.",
    marketDemand: "The Ridge Gourd exporter in India supplies this nutritious vegetable to various countries around the globe. It is crucial that the exported Ridge Gourd meets high-quality standards and adheres to the health and safety regulations of the importing country. Any products that fail to meet these standards risk being returned. The growing demand for Ridge Gourd offers a lucrative business opportunity for local farmers and vendors in India, allowing them to expand their operations and enter the international market successfully.",
    specifications: [
      {
        title:"Product Name",
        value:"Ridge Gourd"
      },
      {
        title:"Family",
        value:"Cucurbits"
      },
      {
        title:"Binomial name",
        value:"Luffa"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 20 kcal","Total Carbohydrate 4.35 g","Dietary Fiber 0.5 g"]
      },
      {
        title:"Minerals",
        value:["Zinc","Iron","Riboflavin","Magnesium","Thiamine"]
      },
      {
        title:"Vitamins",
        value:["Vitamin C"]
      },
      {
        title:"Protein",
        value:["1.20 g"]
      },
      {
        title:"Fats",
        value:["0.2g"]
      },
    ],
    benifits: ["Ridge gourd is known for its blood-purifying properties and its ability to nourish liver health. It also offers protection against alcohol-induced liver damage, making it a valuable addition to a healthy diet.","Ridge gourd juice can aid in healing jaundice and bolster your immune system, providing a natural defense against infections. Incorporate this nourishing juice into your routine for enhanced health benefits.","Ridge gourd's blood-purifying properties promote clear, healthy skin, helping to prevent pimples and acne. Embrace this natural remedy for a radiant complexion and healthier skin.","Ridge gourd's cooling properties help manage acidity and ulcers, providing relief from burning sensations during urination. Embrace this natural remedy for soothing digestive health and comfort."],
    outro: "JNTB EXPORT IMPORT stands out as a premier Ridge Gourd importer from India. Our expertise in the food export industry ensures a seamless process for our clients, from finding buyers to final product delivery. With extensive experience and a robust network of global connections, we handle every step of the Ridge Gourd export process with precision and care. Our dedicated team is committed to delivering top-quality service, adhering to all health and safety regulations to ensure your products reach their destination in perfect condition. Ready to export Ridge Gourd from India? Contact JNTB EXPORT IMPORT today for reliable, efficient, and expert assistance!",
    varieties: [],
  },
  {
    heading: "Indian Pumpkin Exporters",
    picture: pumpkinBanner,
    link: "Pumpkin",
    firstLayout: false,
    description: "Indian Pumpkin exports are thriving, with tons of pumpkins shipped abroad annually. Known for their round shape, smooth, ribbed skin, and vibrant colors ranging from orange to yellow, pumpkins are in high demand globally. This versatile vegetable offers numerous health benefits, driving an increase in Pumpkin exports from India over the years. For local vendors and farmers, Pumpkin export presents an excellent opportunity to grow their businesses and tap into international markets. India exports various Pumpkin varieties, catering to diverse global tastes and preferences, making it a lucrative venture in the agricultural export sector.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 49 calories","Dietary Fiber - 3 g","Total Carbohydrate - 12 g"]
      },
      {
        title:"Protein",
        value:["2g"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin C","Vitamin B-6"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium"]
      },
      {
        title:"Fat",
        value:[]
      },
    ],
    benifits: [],
    outro: "JNTB EXPORT IMPORT stands as a premier Pumpkin exporter from India, dedicated to simplifying the Pumpkin import process. We know that importing can be complex, but our trained executives are here to guide you every step of the way, from documentation to the final delivery. With our extensive global network and deep market insights, we have consistently delivered exceptional results for our clients. If you’re seeking a reliable Pumpkin importer from India, JNTB EXPORT IMPORT is your trusted partner. Contact us now to experience seamless and efficient service that sets us apart in the industry.",
    varieties: [
      {
        heading:"Arka Suryamukhi",
        description:"The Arka Suryamukhi Pumpkin variety is distinguished by its round shape with slightly flattened ends. These pumpkins are typically orange, developing striking white stripes as they mature. Each fruit of this variety weighs approximately 1 kg, making it an ideal choice for both culinary and decorative uses. Known for its vibrant color and unique appearance, Arka Suryamukhi Pumpkins are a popular choice in the market, offering both quality and visual appeal."
      },
      {
        heading:"Arka Chandan",
        description:"The Arka Chandan Pumpkin variety is known for its medium-sized fruits with a distinctive light brown color. Each pumpkin typically weighs between 2 to 3 kilograms, making it a substantial choice for various culinary applications. This variety matures in about 120 days, ensuring a consistent and reliable harvest. Arka Chandan Pumpkins are valued for their size, quality, and appealing appearance, making them a preferred option in both domestic and international markets."
      },
      {
        heading:"PAU Magaz Kaddu-1",
        description:"The PAU Magaz Kaddu-1 variety is ideal for export, featuring medium-sized, round pumpkins that are perfect for global markets. As these pumpkins mature, they transform into a vibrant yellow, enhancing their visual appeal. Their consistent size and quality make them a reliable choice for exporters looking to meet international demand. With their attractive appearance and suitability for long-distance shipping, PAU Magaz Kaddu-1 Pumpkins are a valuable addition to the global pumpkin trade."
      },
      {
        heading:"Punjab Samrat",
        description:"The Punjab Samrat Pumpkin variety is an excellent choice for export, featuring small, green fruits that gradually turn brown as they mature. This transformation adds to their unique appeal in international markets. Despite their compact size, Punjab Samrat Pumpkins are prized for their quality and adaptability, making them a reliable option for exporters. Their distinct color change and suitability for shipping make Punjab Samrat Pumpkins a standout variety in the global pumpkin trade."
      },
      {
        heading:"PPH-1",
        description:"The PPH-1 Pumpkin variety is an early-maturing option featuring dwarf vines, making it a practical choice for efficient cultivation. The small, round fruits of this variety undergo a striking color transition from blotchy green to brown as they mature, adding visual interest. PPH-1 Pumpkins are ideal for markets that value early harvests and unique aesthetics. Their compact size and vibrant color change make them a desirable choice for both domestic and export markets."
      },
      {
        heading:"PPH-2",
        description:"The PPH-2 Pumpkin variety is known for its fast-growing crop, producing round fruits that transition from green to brown as they mature. While similar to the PPH-1 variety, PPH-2 stands out with its smoother skin, offering a distinct texture that appeals to both consumers and exporters. This smooth-skinned variety is ideal for markets seeking visually appealing and high-quality pumpkins, making PPH-2 a valuable addition to the range of export-ready pumpkin options."
      },
      {
        heading:"PAU Magaz Kaddoo-1",
        description:"Released in 2018, the PAU Magaz Kaddoo-1 Pumpkin variety is known for its unique hull-less seeds and dwarf vines. The plants feature dark green leaves, while the medium-sized, round fruits turn a striking golden yellow as they mature. This variety's combination of practical features and vibrant appearance makes it a standout option for both growers and exporters, offering quality produce that meets market demands. PAU Magaz Kaddoo-1 is a top choice for those seeking innovation in pumpkin cultivation."
      },
    ],
    varietyHeading:"Pumpkin Varieties We Export",
    verietyDesc:"Indian Pumpkin exporters offer a wide range of Pumpkin varieties, including Arka Suryamukhi, Arka Chandan, and Punjab Samrat. These pumpkins are carefully selected to ensure they meet the highest quality standards and adhere to the health and safety regulations of importing countries. Exporting these top-tier varieties, Indian Pumpkin exporters provide a reliable supply of premium produce, catering to the global demand for diverse and high-quality pumpkins."
  },
  {
    heading: "Indian Lemon Exporter",
    picture: lemonBanner,
    link: "Lemon",
    firstLayout: true,
    description: "Lemon, a highly favored citrus fruit, is renowned for its diverse medicinal benefits, with its fruit, juice, and peel widely used. Indian Lemon exporters are capitalizing on the rising global demand by shipping large quantities of lemons each year. The surge in Lemon export from India presents a prime opportunity for exporters to broaden their reach and tap into international markets. Offering various varieties and qualities, Indian Lemon exporters cater to diverse global preferences, making it a lucrative venture. With lemons gaining popularity worldwide, now is the perfect time for Indian exporters to expand their business and make a significant impact on the global stage.",
    marketDemand: "Lemons are celebrated for their wide-ranging health benefits and versatile culinary applications. Recognizing their value, Indian Lemon exporters focus on delivering top-quality fruits to global markets. Over the past few years, Lemon imports from India have steadily increased, reflecting the high standards and excellent reputation of Indian lemons. This growth highlights the increasing global demand for premium lemons and presents a fantastic opportunity for exporters to showcase their high-quality produce. By maintaining rigorous quality control and leveraging India’s renowned reputation for exceptional lemons, exporters are successfully meeting international needs and expanding their market presence.",
    specifications: [
      {
        title:"Product Name",
        value:"Lemon"
      },
      {
        title:"Origin",
        value:"Assam, northern Burma or China"
      },
      {
        title:"Family",
        value:"Rutaceae"
      },
      {
        title:"Binomial name",
        value:"Citrus × limon"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 29 kcal","Total Carbohydrates 9g","Dietary Fiber 2.8g"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium"]
      },
      {
        title:"Vitamins",
        value:["Vitamin B-6","Vitamin C"]
      },
      {
        title:"Protein",
        value:["1.1 g"]
      },
      {
        title:"Fats",
        value:["Fat 0.3g"]
      },
    ],
    benifits: ["Citrus fruits, like lemons, are packed with vitamin C, a powerful antioxidant that shields cells from harmful free radicals, promoting overall health and well-being.","Vitamin C in lemons supports skin health by minimizing wrinkles, combating dryness from aging, and repairing sun damage. This essential nutrient helps maintain a youthful, radiant complexion and enhances overall skin vitality.","Lemons can boost digestion, with lemon water serving as a natural laxative that helps prevent constipation. Regular consumption may enhance digestive health and promote a smoother, more comfortable digestive process.","Lemons can help prevent kidney stones thanks to their citric acid content. This natural compound aids in reducing the formation of stones, promoting healthier kidneys and supporting overall urinary tract health."],
    outro: "JNTB EXPORT IMPORT is a top Lemon importer from India, dedicated to ensuring a seamless export experience for our clients. Our expert team brings extensive experience to the table, guiding you through every step of the export process. We prioritize smooth operations, minimizing confusion and hassle, while strictly adhering to all government health and safety regulations. At JNTB EXPORT IMPORT, we guarantee the safe and timely delivery of your products, leveraging our industry knowledge and commitment to quality. If you're seeking reliable Lemon export services from India, look no further—contact JNTB EXPORT IMPORT today for exceptional support and efficient service.",
    varieties: [],
  },
  {
    heading: "Indian Sweet Potato Exporter",
    picture: sweetPotatoBanner,
    link: "Sweet Potato",
    firstLayout: true,
    description: "Sweet potatoes are versatile, sweet, and starchy root vegetables found globally, available in orange, white, and purple hues. They are rich in vitamins, minerals, antioxidants, and fiber, making them a nutritional powerhouse. Indian Sweet Potato exporters play a crucial role, shipping large quantities of these nutritious vegetables to various international markets each year. As the global demand for sweet potatoes rises, the export volume from India has been rapidly increasing. Indian exporters are well-equipped to meet this growing demand by offering a diverse range of sweet potato varieties. With their commitment to quality and efficiency, Indian Sweet Potato exporters are perfectly positioned to satisfy the expanding global appetite for this valuable vegetable.",
    marketDemand: "Sweet potatoes are celebrated for their numerous health and medicinal benefits, driving a surge in global demand. Indian Sweet Potato exporters are responding to this growing interest by shipping substantial quantities of premium-quality sweet potatoes to international markets. The reputation of Indian sweet potatoes for exceptional quality and the diverse range of varieties available have contributed to a steady increase in imports from India. As the global market continues to expand, Indian exporters are well-positioned to meet the rising demand with top-notch produce, ensuring that customers worldwide receive the best sweet potatoes. With a focus on quality and variety, Indian Sweet Potato exporters are making a significant impact on the global stage.",
    specifications: [
      {
        title:"Product Name",
        value:"Sweet Potato"
      },
      {
        title:"Origin",
        value:"Central and South America"
      },
      {
        title:"Family",
        value:"Convolvulaceae"
      },
      {
        title:"Binomial name",
        value:"Ipomoea batatas"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 180 kcal","Total Carbohydrates 41.4g","Dietary Fiber 6.6g"]
      },
      {
        title:"Minerals",
        value:["Manganese","Potassium","Copper","Niacin"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin B-6","Vitamin C"]
      },
      {
        title:"Protein",
        value:["4 g"]
      },
      {
        title:"Fats",
        value:["Fat 0.3g"]
      },
    ],
    benifits: ["Sweet potatoes boost gut health with their high fiber and antioxidant content. They encourage the growth of beneficial gut bacteria, supporting a healthy digestive system and overall well-being.","Sweet potatoes are packed with beta-carotene and anthocyanins, powerful antioxidants that support eye health. They may help prevent vision loss and enhance overall eye function, keeping your vision sharp and vibrant.","Sweet potatoes are a powerhouse of beta-carotene, which converts to vitamin A, bolstering your immune system and promoting gut health. This vital nutrient keeps you healthy and supports overall digestive wellness.","Animal studies suggest sweet potatoes might boost brain health by reducing inflammation and slowing mental decline. However, further research is needed to confirm these benefits and fully understand their impact on cognitive function."],
    outro: "JNTB EXPORT IMPORT is a top Sweet Potato importer from India, dedicated to simplifying the export process for our clients. We understand that exporting can be complex, but our team is here to make it effortless! Our experienced executives will guide you through every step of the Sweet Potato export process, ensuring a smooth journey from start to finish. With our extensive expertise in the export industry, we prioritize a hassle-free experience for our clients, adhering to all regulations and delivering quality products. If you're seeking reliable Sweet Potato export services from India, look no further—contact JNTB EXPORT IMPORT today for seamless support and exceptional service!",
    varieties: [],
  },
  {
    heading: "Indian Jerusalem Exporter",
    picture: jerusalemBanner,
    link: "Jerusalem",
    firstLayout: true,
    description: "Despite its name, the Jerusalem artichoke has no connection to Jerusalem; it’s named after the Italian word for sunflower. This root vegetable features a tough dark skin and a flavor similar to potatoes. Indian Jerusalem artichoke exporters are seeing a significant increase in demand, thanks to the vegetable’s growing popularity. Each year, large quantities are shipped globally, reflecting the rising interest in this unique root veggie. This trend presents a prime opportunity for Indian exporters to expand their reach and enter new international markets. With a range of varieties and qualities available, the Jerusalem export from India is set to meet the diverse needs of global consumers.",
    marketDemand: "Indian Jerusalem artichoke exporters are known for delivering top-quality produce to international markets. As awareness of the uses and benefits of Jerusalem artichokes grows, global demand for this versatile root vegetable is on the rise. Over the past few years, imports of Jerusalem artichokes from India have steadily increased, reflecting the growing appreciation for this nutritious and flavorful vegetable. With its expanding market reach and rising popularity, Indian exporters are well-positioned to meet the increasing global demand. By offering exceptional quality and a range of varieties, Indian Jerusalem artichoke exporters are making a significant impact on the international stage.",
    specifications: [
      {
        title:"Product Name",
        value:"Jerusalem"
      },
      {
        title:"Origin",
        value:"North America"
      },
      {
        title:"Family",
        value:"Asteraceae"
      },
      {
        title:"Binomial name",
        value:"Helianthus tuberosus"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 73 kcal","Total Carbohydrates 17g","Dietary Fiber 1.6g"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium"]
      },
      {
        title:"Vitamins",
        value:["Vitamin B-6","Vitamin C"]
      },
      {
        title:"Protein",
        value:["4 g"]
      },
      {
        title:"Fats",
        value:["Fat 0g"]
      },
    ],
    benifits: ["Jerusalem artichokes are packed with potassium, offering about 643 mg per cup. This essential mineral supports overall health and may help reduce the risk of heart disease, making Jerusalem artichokes a heart-healthy addition to your diet.","Eating Jerusalem artichokes may help lower blood cholesterol levels. Their nutritious profile supports heart health by potentially reducing cholesterol, making them a beneficial addition to a balanced diet.","Jerusalem artichokes are rich in iron, a crucial mineral that aids in delivering oxygen to every cell in your body. This essential nutrient supports overall vitality and ensures your cells receive the oxygen they need to function optimally.","Jerusalem artichokes boast higher protein levels than many root vegetables and are rich in sulfur-containing amino acids like taurine, methionine, homocysteine, and cysteine. These amino acids support flexible connective tissue and aid liver detoxification."],
    outro: "JNTB EXPORT IMPORT stands out as a leading Jerusalem artichoke importer from India, dedicated to providing seamless export solutions. Our experienced team expertly guides you through every stage of the export process, ensuring minimal confusion and hassle. With a commitment to reliability, we adhere strictly to all government health and safety regulations, guaranteeing the safe delivery of your products. Our extensive industry expertise and attention to detail ensure a smooth and efficient export experience. If you're seeking top-notch Jerusalem artichoke export services from India, JNTB EXPORT IMPORT is your trusted partner. Contact us today to benefit from our exceptional support and industry-leading service!",
    varieties: [],
  },
  {
    heading: "Indian Radish Exporters",
    picture: radishBanner,
    link: "Radish",
    firstLayout: false,
    description: "Radish, scientifically known as Raphanus sativus, is a herbaceous plant from the Brassicaceae family, primarily cultivated for its edible taproot. Indian Radish exporters are at the forefront, shipping large quantities of radishes to international markets each year. As global popularity for radishes rises, so does the demand for Indian exports. This growing interest has led to a rapid increase in Radish exports from India. Our exports encompass a diverse range of radish varieties, catering to various global preferences and requirements. With the surge in international demand, Indian Radish exporters are well-positioned to provide high-quality radishes that meet global standards.",
    marketDemand: "",
    specifications: [],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories - 16 calories","Dietary Fiber - 1.6 g","Carbohydrate 3.4 g"]
      },
      {
        title:"Protein",
        value:["0.7g"]
      },
      {
        title:"Vitamins",
        value:["Vitamin C","Vitamin B-6"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium"]
      },
      {
        title:"Fat",
        value:[]
      },
    ],
    benifits: [],
    outro: "JNTB EXPORT IMPORT is a leading Radish exporter from India, bringing extensive expertise in the food export industry to the table. Our knowledgeable team is well-versed in every aspect of the export-import process, ensuring a smooth journey from finding buyers to final product delivery. We pride ourselves on delivering exceptional service and high-quality radishes that meet global standards. Whether you're seeking to import radishes from India or need guidance through the export process, JNTB EXPORT IMPORT is your trusted partner. Contact us today to benefit from our industry expertise and efficient, hassle-free service. Let JNTB EXPORT IMPORT help you make the most of the growing global demand for radishes!",
    varieties: [
      {
        heading:"Pusa Desi",
        description:"Pusa Desi is a subtropical radish cultivar perfect for sowing between mid-August and October in northern India. Characterized by its pure white, tapering roots and green stem end, this variety matures in just 50-55 days. Ideal for the northern plains, Pusa Desi offers a reliable harvest and is well-suited to the region’s growing conditions."
      },
      {
        heading:"Pusa Chetki",
        description:"Pusa Chetki is a versatile radish variety known for its heat tolerance and adaptability. Ideal for cultivation from mid-March to mid-August in northern and central India, it produces medium-long, stumpy, pure white roots with a mild pungency. This variety matures in approximately 40-45 days, making it a reliable choice for growers seeking a hardy radish that thrives in warmer conditions."
      },
      {
        heading:"Pusa Reshmi",
        description:"Pusa Reshmi radishes feature roots that are 30-45 cm long, white with a green tinge at the top. Ideal for early sowing from mid-September to mid-November, this variety also tolerates slightly higher temperatures. With a maturity period of 55-60 days, Pusa Reshmi offers a reliable harvest for growers looking for a radish that adapts well to varying conditions while delivering robust, high-quality produce."
      },
      {
        heading:"Japanese White",
        description:"The Japanese White radish boasts cylindrical roots that are 25-30 cm long, with pure white skin and crisp, solid flesh. Mildly flavored, this variety thrives in plains from October to December and in hills from July to September. With a maturation period of approximately 60-65 days, Japanese White offers a reliable and flavorful harvest, making it a versatile choice for diverse growing conditions."
      },
      {
        heading:"Punjab Safed",
        description:"Punjab Safed radishes feature smooth, white, tapering roots that are 30-40 cm long and 3-5 cm thick, with a mild taste and no forking. This quick-growing variety matures in 50-60 days and is ideal for sowing in September-October. Punjab Safed roots stay edible for up to 10 days after reaching full size, making them a versatile and convenient choice for growers seeking reliable, flavorful radishes."
      },
      {
        heading:"Arka Nishant",
        description:"Arka Nishant radishes feature long, marble-white roots with a shiny red, smooth skin. Known for their resistance to pithiness and premature bolting, these radishes offer crisp, solid, and mildly pungent white flesh. With a maturation period of 50-55 days, Arka Nishant combines durability with exceptional flavor, making it a standout choice for reliable and high-quality radish cultivation."
      },
      {
        heading:"Pusa Himani",
        description:"Pusa Himani radishes are distinguished by their large roots, measuring 30-35 cm in length and 10-12 cm in diameter. With pure white skin and crisp, sweet-flavored flesh that has a mild pungency, this variety matures in about 55 days. Notably, Pusa Himani is unique for its ability to be grown year-round in the hills, except during the winter months, making it a versatile choice for consistent radish production."
      },
      {
        heading:"White Icicle",
        description:"White Icicle is a medium-short European radish variety that matures in just 25-30 days. The roots, measuring 12-15 cm long and 2-3 cm in diameter, feature pure white skin and icy white, juicy, sweet-flavored flesh. Ideal for sowing from mid-October to February, White Icicle offers a crisp and refreshing taste, making it a perfect choice for those seeking a quick-growing radish with a distinctive flavor and attractive appearance."
      },
      {
        heading:"Rapid Red White Tipped",
        description:"Rapid Red White Tipped is an extra-early European radish that matures in just 25-30 days. Featuring smooth, small, round roots with a striking bright red color and a white tip, this variety offers pure white, crisp, and snappy flesh. Ideal for sowing from mid-October to February, Rapid Red White Tipped delivers a vibrant appearance and refreshing taste, making it a standout choice for quick-growing, flavorful radishes."
      },
    ],
    varietyHeading:"Radish Varieties We Export",
    verietyDesc:"Indian radish exporters offer a diverse selection of radish varieties, including Pusa Desi, Pusa Chetki, and Pusa Reshmi, among others. Each variety brings unique qualities to the table, catering to different global preferences. The export of radishes from India features these key varieties, ensuring a broad appeal and meeting various international market demands. With such variety, Indian radish exporters are well-equipped to provide high-quality produce tailored to global tastes."
  },
  {
    heading: "Indian Beetroot Exporter",
    picture: beetrootBanner,
    link: "Beetroot",
    firstLayout: true,
    description: "Beetroots, a versatile root vegetable cherished globally, are packed with essential vitamins, minerals, and beneficial plant compounds. Indian beetroot exporters are at the forefront, shipping substantial quantities of this nutritious vegetable worldwide. The increasing awareness of beetroot’s numerous health benefits and its diverse culinary uses has driven a surge in demand. This growing trend presents a significant opportunity for Indian beetroot exporters to expand their reach and tap into the international market. Our exports include a variety of beetroot types, ensuring high-quality produce that meets global standards. With beetroot’s rising popularity, now is the perfect time for Indian exporters to grow their business and cater to a wider audience.",
    marketDemand: "India's beetroot exports reach over 56 countries, with a notable value of approximately $3.6 million in 2019-20. Major trading partners include Iran, the United Arab Emirates, the Netherlands, Kenya, and Uganda. The growing global demand for beetroot reflects its increasing recognition for health and medicinal benefits. As more countries seek to tap into these benefits, the import of Indian beetroot continues to rise, highlighting India’s pivotal role in the international market. With its rich nutritional profile and diverse applications, Indian beetroot remains a sought-after commodity, making it an exciting opportunity for exporters looking to expand their global presence.",
    specifications: [
      {
        title:"Product Name",
        value:"Beetroot"
      },
      {
        title:"Origin",
        value:"Mediterranean"
      },
      {
        title:"Family",
        value:"Amaranthaceae"
      },
      {
        title:"Binomial name",
        value:"Beta vulgaris subsp. vulgaris"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 43 kcal","Total Carbohydrates 10g","Dietary Fiber 2.8g"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium"]
      },
      {
        title:"Vitamins",
        value:["Vitamin B-6","Vitamin C"]
      },
      {
        title:"Protein",
        value:["1.6 g"]
      },
      {
        title:"Fats",
        value:["Fat 0.2g"]
      },
    ],
    benifits: ["Beetroots are rich in nitrates, which may help lower blood pressure and reduce the risk of heart attacks, heart failure, and stroke. Their natural compounds contribute to cardiovascular health, making beetroots a heart-friendly addition to your diet.","Beets might offer anti-inflammatory benefits, potentially easing inflammation in the body. However, more research is needed to confirm these effects and fully understand how beets could contribute to reducing inflammation and supporting overall health.","Beetroots are rich in fiber, which promotes digestive health and helps lower the risk of chronic conditions. Their fiber content supports regular bowel movements and overall well-being, making beets a valuable addition to a balanced diet."],
    outro: "JNTB EXPORT IMPORT stands out as a premier beetroot importer from India, dedicated to simplifying the often complex export process. We understand the intricacies involved and are here to guide you every step of the way. Our experienced team will assist you from finding the right buyers to ensuring the seamless final delivery of your beetroot products. With our extensive network and in-depth knowledge of the export industry, we ensure a hassle-free experience and top-notch service. If you're looking to export beetroot from India, let JNTB EXPORT IMPORT be your trusted partner. Contact us today to streamline your export process and access global markets with ease!",
    varieties: [],
  },
  {
    heading: "Indian Carrots Exporters",
    picture: carrotBanner,
    link: "Carrots",
    firstLayout: true,
    description: "Carrots, or Daucus carota, are a crunchy, flavorful root vegetable renowned for their exceptional health benefits and nutritional value. The Indian carrot exporter plays a significant role in the global market, shipping vast quantities each year. With their versatile health benefits and medicinal properties, the demand for Indian carrots has surged over the decades. This growth reflects the increasing appreciation for carrots’ role in a healthy diet. Indian exporters are now handling a diverse range of carrot varieties and qualities to meet global needs. As the popularity of carrots continues to rise, the Indian carrot export industry is expanding, offering premium produce to international markets.",
    marketDemand: "Global demand for carrots has surged in recent years, with Indian exporters meeting this need by shipping carrots to over 105 countries. In the 2019-2020 period, India exported carrots valued at approximately $11.5 million globally. This growth is attributed to the superior quality and diverse varieties of carrots that India offers. As the world recognizes the nutritional benefits and versatility of carrots, the import of Indian carrots continues to rise. With a commitment to excellence and a broad range of options, Indian carrot exporters are well-positioned to cater to the growing international market and expand their global footprint.",
    specifications: [
      {
        title:"Product Name",
        value:"Carrot"
      },
      {
        title:"Origin",
        value:"Europe and Southwestern Asia"
      },
      {
        title:"Family",
        value:"Apiaceae"
      },
      {
        title:"Binomial name",
        value:"Daucus carota subsp. sativus"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 41 kcal","Total Carbohydrates 9.6g","Dietary Fiber 2.8g"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium"]
      },
      {
        title:"Vitamins",
        value:["Vitamin B-6","Vitamin C"]
      },
      {
        title:"Protein",
        value:["0.9 g"]
      },
      {
        title:"Fats",
        value:["Fat 0.2g"]
      },
    ],
    benifits: ["Eating carrots can lower cancer risk, thanks to their high carotenoid content. These powerful antioxidants may help shield against various cancer types, making carrots a smart choice for a health-boosting, cancer-fighting diet.","Carrots may help lower blood cholesterol levels, promoting heart health and overall well-being. Including this crunchy, nutrient-rich vegetable in your diet supports cardiovascular health and keeps your body in top shape.","Carrots are low in calories and high in fiber, helping you feel full longer. This natural fullness can reduce overall calorie intake, making carrots a smart choice for weight management and a balanced diet.","Low vitamin A levels can lead to night blindness, but eating carrots can help. Their rich vitamin A and carotenoid content support eye health, potentially reducing symptoms of night blindness and improving overall vision."],
    outro: "JNTB EXPORT IMPORT is a top Carrots importer from India, dedicated to sharing India's rich agricultural heritage with the world. Our skilled team ensures a seamless export process, from initial coordination to final delivery. With our deep industry knowledge and commitment to excellence, we make exporting carrots from India a smooth, hassle-free experience for our clients. If you're looking to import high-quality carrots from India, JNTB EXPORT IMPORT is your go-to partner. Reach out to us today and let us handle the complexities while you enjoy the benefits of premium Indian carrots!",
    varieties: [],
  },
  {
    heading: "Indian Taro Exporters",
    picture: taroBanner,
    link: "Taro",
    firstLayout: true,
    description: "Taro root, a starchy and versatile vegetable with a brown outer skin and white flesh flecked with purple, is a staple in cuisines worldwide. India, a major player in the global Taro market, exports substantial quantities of this nutritious root vegetable each year. As Taro’s popularity continues to soar, the demand for Indian Taro has surged, leading to a significant increase in exports. Indian Taro exporters are rising to the challenge, supplying a diverse range of Taro varieties to meet global needs. Whether you're seeking Taro for its culinary versatility or its health benefits, the growing export of Taro from India ensures a steady supply of this prized vegetable.",
    marketDemand: "The global demand for Taro roots has surged in recent years, driven by the vegetable’s impressive health and medicinal benefits. Taro, known for its versatility and nutritional value, is increasingly sought after worldwide. Indian Taro exporters play a crucial role in this expanding market, delivering premium-quality Taro to diverse international destinations. As the appreciation for Taro grows, so does the import of this root from India, reflecting the country’s commitment to providing top-tier Taro varieties. The rise in Taro exports from India highlights the vegetable's global appeal and the efficiency of Indian exporters in meeting international demands. Whether for culinary use or health benefits, Indian Taro stands out for its superior quality and consistency.",
    specifications: [
      {
        title:"Product Name",
        value:"Taro"
      },
      {
        title:"Origin",
        value:"South East Asia"
      },
      {
        title:"Family",
        value:"Araceae"
      },
      {
        title:"Binomial name",
        value:"Colocasia esculenta"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 35 kcal","Total Carbohydrates 6g","Dietary Fiber 3g"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium","Potassium","Folate","Phosphorous"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin C"]
      },
      {
        title:"Protein",
        value:["4 g"]
      },
      {
        title:"Fats",
        value:["Less than 1 gm"]
      },
    ],
    benifits: ["Taro root boasts a smooth, starchy texture and a mildly sweet flavor, making it a versatile ingredient. It can be cooked into a variety of sweet or savory dishes, adding a unique touch to your meals.","The fiber and resistant starch in taro ferment into short-chain fatty acids in the gut, potentially offering protection against colon cancer and inflammatory bowel disease. This process supports a healthier digestive system and reduces inflammation.","Taro root can enhance satiety, leading to reduced calorie intake and increased fat burning. This helps with weight loss and decreases body fat, making it a valuable addition to a healthy diet.","Taro root is rich in polyphenols and antioxidants, which may help fight cancer growth and shield your body from oxidative stress. However, further research is needed to fully understand these potential benefits."],
    outro: "JNTB EXPORT IMPORT is a leading Taro importer from India, dedicated to sharing Indian culture and exceptional products with the world. Our experienced team at JNTB EXPORT IMPORT provides comprehensive support throughout the export process, ensuring a seamless experience from start to finish. We handle every detail, from finding buyers to final delivery, with precision and expertise. With our deep industry knowledge and commitment to quality, we guarantee a hassle-free Taro export process for our clients. If you’re seeking reliable Taro export from India, look no further—contact JNTB EXPORT IMPORT today!",
    varieties: [],
  },
  {
    heading: "",
    picture: woodappleBanner,
    link: "",
    firstLayout: "",
    description: "",
    marketDemand: "",
    specifications: [],
    ingredients: [],
    benifits: [],
    outro: "",
    varieties: [],
  },
  {
    heading: "Indian Coriander Exporters",
    picture: greencorianderBanner,
    link: "Coriander",
    firstLayout: true,
    description: "Coriander, derived from the Coriandrum sativum plant, is a versatile herb widely used in global cuisines. Closely related to parsley, carrots, and celery, coriander is not only valued for its flavor but also for its numerous health and medicinal benefits. The demand for Indian coriander has been steadily rising, leading to a significant increase in its export. Indian coriander exporters are tapping into this growing market, exporting various coriander varieties to meet international demand. This surge in exports is proving profitable for Indian exporters, as coriander's global appeal continues to expand, making it a sought-after commodity in the international marketplace.",
    marketDemand: "In the financial year 2018-19 (Apr-Jan), India exported approximately 41,235 tonnes of coriander, reflecting a growing global demand for this versatile herb. The increasing popularity of coriander is driven by its numerous health benefits and culinary uses, making it a staple in kitchens worldwide. Indian coriander, known for its premium quality, has established a strong reputation in the international market, leading to a steady rise in exports. Indian exporters are capitalizing on this demand, expanding their reach to various global regions. The consistent increase in coriander imports from India underscores the trust and preference for Indian coriander, positioning the country as a key player in the global spice trade.",
    specifications: [
      {
        title:"Product Name",
        value:"Coriander"
      },
      {
        title:"Origin",
        value:"Western Asia and Southern Europe"
      },
      {
        title:"Family",
        value:"Apiaceae"
      },
      {
        title:"Binomial name",
        value:"Coriandrum sativum"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 3.68 kcal","Total Carbohydrates 0.587g"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin B-6","Vitamin C"]
      },
      {
        title:"Protein",
        value:["0.341 g"]
      },
      {
        title:"Fats",
        value:["Fat 0.2g"]
      },
    ],
    benifits: ["Coriander may help reduce blood sugar levels by activating specific enzymes, making it a potent natural remedy. However, its effects are strong, so those with low blood sugar should use coriander with caution to avoid potential complications.","Coriander is packed with antioxidants that offer a range of health benefits, including immune support, anticancer properties, anti-inflammatory effects, and neuroprotection. This powerful herb not only enhances flavor but also contributes significantly to overall well-being.","Coriander is heart-friendly, helping to lower blood pressure and reduce LDL (bad) cholesterol levels, while boosting HDL (good) cholesterol. Incorporating coriander into your diet supports cardiovascular health and promotes overall well-being naturally.","Coriander’s antioxidants may help reduce brain inflammation, enhance memory, and alleviate anxiety symptoms. While these benefits are promising, further research is needed to fully understand coriander’s potential impact on brain health."],
    outro: "JNTB EXPORT IMPORT stands as one of the leading coriander importers from India, offering expert guidance throughout the entire export process. We understand that coriander export can be complex, but our dedicated team is here to simplify it for you. From meticulous documentation to the secure final delivery of your products, JNTB EXPORT IMPORT ensures a smooth experience at every stage. Our extensive global network and deep market insights have consistently benefited our clients, making us a trusted partner in the industry. If you're seeking reliable support for coriander export from India, JNTB EXPORT IMPORT is your go-to solution. Contact us today to streamline your export journey!",
    varieties: [],
  },
  {
    heading: "Indian Spring Onion Exporters",
    picture: springOnionBanner,
    link: "Spring Onion",
    firstLayout: true,
    description: "Spring onions, featuring dark green hollow-tubed leaves and crisp white bulbs, are a versatile favorite in Asian cuisines. Packed with vitamin C and dietary fiber, they not only enhance flavors but also offer significant health benefits. Indian exporters are successfully shipping tonnes of spring onions worldwide each year, driven by their rising popularity and diverse culinary uses. The export of spring onions from India has seen consistent growth, opening up lucrative opportunities for expansion into international markets. Offering a variety of spring onion types, Indian exporters are well-equipped to meet global demand with quality and reliability. Partner with India’s leading spring onion exporters to bring fresh, flavorful produce to your market today!",
    marketDemand: "The global demand for spring onions has been steadily rising, driven by their unique taste and versatility in various cuisines. Indian spring onion exporters are meeting this demand by supplying premium quality varieties to markets across the globe. The consistent growth in spring onion imports from India reflects the trust in the country’s produce and the high standards maintained by its exporters. With a reputation for delivering fresh, flavorful spring onions, Indian exporters are well-positioned to cater to the increasing global appetite for this versatile vegetable. Explore the potential of partnering with India’s leading spring onion exporters and bring the best of this vibrant vegetable to your market.",
    specifications: [
      {
        title:"Product Name",
        value:"Spring Onion"
      },
      {
        title:"Origin",
        value:"Far East"
      },
      {
        title:"Family",
        value:"Amaryllidaceae"
      },
      {
        title:"Binomial name",
        value:"Allium fistulosum"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 14 kcal","Total Carbohydrates 3g","Dietary Fiber 1.3g"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium","Phosphorus","Potassium"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin B1","Vitamin B2"]
      },
      {
        title:"Protein",
        value:["0.9 g"]
      },
      {
        title:"Fats",
        value:["Fat 0.1g"]
      },
    ],
    benifits: ["Spring onions are nutrient-rich, offering a variety of essential minerals. They are particularly high in vitamin C and calcium, making them a valuable addition to any diet for boosting immunity and supporting strong bones.","Spring onions are packed with dietary fiber and essential vitamins A, B6, thiamine, and folate. They also provide important minerals such as potassium, copper, chromium, manganese, and iron, making them a powerhouse of nutrition.","Studies suggest that spring onions may help lower cholesterol, reduce the risk of heart attacks and strokes, and offer antibacterial and antifungal benefits. Their diverse health advantages make them a valuable addition to a heart-healthy diet."],
    outro: "JNTB EXPORT IMPORT stands out as the premier spring onion importer from India, offering unparalleled expertise in the food export industry. We ensure a seamless and hassle-free export process for our clients, supporting them from locating buyers to the final delivery of their orders. Our extensive experience and commitment to quality guarantee that every step of the spring onion export journey is handled with care and efficiency. Whether you’re seeking to export spring onions from India or need a reliable partner to manage your export needs, JNTB EXPORT IMPORT is here to assist. Contact us today to benefit from our industry-leading support and global network!",
    varieties: [],
  },
  {
    heading: "Indian Mint Exporters",
    picture: mintBanner,
    link: "Mint",
    firstLayout: true,
    description: "Mint, encompassing varieties like peppermint and spearmint from the Mentha genus, is celebrated for its refreshing, cooling sensation and wide culinary use. Indian mint exporters are thriving, shipping tonnes of this versatile herb worldwide each year. The rising global demand for mint is driven by its numerous health and medicinal benefits, including aiding digestion and providing soothing relief. This growing interest presents a significant opportunity for Indian mint exporters to expand their reach and tap into international markets. With a range of mint varieties and qualities available, Indian exporters are well-positioned to meet diverse global needs and elevate their business on the international stage. Explore the potential of partnering with India’s top mint exporters today!",
    marketDemand: "India dominates the global mint market, producing approximately 80% of the world’s mint supply and exporting 75% of its output. Indian mint exporters are increasingly shipping this valued herb and its products to countries around the globe. The rising global demand for mint is fueled by its exceptional nutritional benefits and versatile applications. As mint's popularity continues to grow, so does the volume of mint imported from India, reflecting the excellent reputation of Indian mint in international markets. With its superior quality and diverse uses, Indian mint is establishing a strong presence worldwide. Explore the benefits of partnering with India’s leading mint exporters and take advantage of this thriving market opportunity today!",
    specifications: [
      {
        title:"Product Name",
        value:"Mint"
      },
      {
        title:"Origin",
        value:"Eurasia, North America, southern Africa, and Australia"
      },
      {
        title:"Family",
        value:"Lamiaceae"
      },
      {
        title:"Binomial name",
        value:"Mentha"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 6 kcal","Dietary Fiber 1g"]
      },
      {
        title:"Minerals",
        value:["Iron","Manganese","Folate"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A"]
      },
    ],
    benifits: ["Mint is renowned for its calming properties, aiding in stress and anxiety relief while promoting restful sleep. This refreshing herb is a natural way to soothe your mind and enhance relaxation for a better night’s rest.","Peppermint tea is an excellent remedy for an upset stomach, soothing the digestive tract and relieving indigestion, gas, and cramps. This herbal brew offers gentle, natural relief and promotes a comfortable, relaxed digestive experience.","Mint has a rich history in traditional medicine, serving roles in both human and veterinary care. It’s also valued as an insecticide, repellent, and natural antifungal or antibacterial agent for protecting crops, showcasing its versatile benefits."],
    outro: "Looking for a top-notch mint importer from India? JNTB EXPORT IMPORT is your ideal partner! With extensive experience and deep expertise in the food export industry, we ensure a smooth and efficient mint export process. Our dedicated team supports you at every stage, from meticulous documentation to the secure final delivery of your mint products. We handle all the details, so you can focus on growing your business. Whether you’re interested in importing mint or seeking a reliable export partner, JNTB EXPORT IMPORT is here to deliver excellence. Contact us today to explore the benefits of working with India’s leading mint exporter and take your business to the next level!",
    varieties: [],
  },
  {
    heading: "Indian Hyacinth Beans Exporters",
    picture: papdiBanner,
    link: "Papdi",
    firstLayout: true,
    description: "Hyacinth bean, a versatile climbing plant, is valued for its edible seeds, pods, leaves, flowers, and roots. The seeds, often used in medicine, contribute to its growing popularity. Indian exporters are shipping tonnes of hyacinth beans worldwide, with exports steadily increasing each year. This rise is driven by the numerous benefits of hyacinth beans, including their nutritional value and medicinal properties. As demand grows, Indian hyacinth bean exporters are capitalizing on this trend, boosting their revenues and expanding their global reach. The export includes a range of hyacinth bean varieties, catering to diverse international needs. Discover the advantages of partnering with India’s leading hyacinth bean exporters for premium, high-quality products.",
    marketDemand: "The global demand for hyacinth beans has been on the rise, prompting Indian exporters to increase their shipments to countries around the world. Indian hyacinth beans are gaining popularity due to their exceptional quality and diverse applications, from culinary uses to medicinal benefits. This growing interest has led to a notable increase in hyacinth bean imports from India, driven by the excellent reputation of Indian beans and the premium standards maintained by exporters. With a commitment to quality and reliability, Indian hyacinth bean exporters are well-positioned to meet the expanding global market needs. If you’re seeking high-quality hyacinth beans, look no further than India’s trusted exporters for top-notch products and exceptional service.",
    specifications: [
      {
        title:"Product Name",
        value:"Hyacinth Beans"
      },
      {
        title:"Origin",
        value:"Africa"
      },
      {
        title:"Family",
        value:"Fabaceae"
      },
      {
        title:"Binomial name",
        value:"Lablab purpureus"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 117 kcal","Dietary Fiber 21g"]
      },
      {
        title:"Minerals",
        value:["Iron","Manganese","Folate"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin C","Vitamin D","Vitamin B-6"]
      },
      {
        title:"Protein",
        value:["8 g"]
      },
      {
        title:"Fat",
        value:["0.6 g"]
      },
    ],
    benifits: ["Hyacinth beans offer diverse health and medicinal benefits, including traditional uses for birth control. Their versatile properties make them a valuable addition to various health practices, showcasing their significant role in natural medicine.","Hyacinth beans are packed with essential nutrients, vitamins, and minerals. They are also known for their potential to aid in treating diarrhea, making them a nutritious and beneficial choice for supporting digestive health.","Hyacinth beans can be beneficial for managing stomach disorders, offering natural relief for digestive issues. Their nutrient-rich profile supports overall gut health, making them a valuable addition to remedies for stomach discomfort and related conditions."],
    outro: "Looking for a top-tier hyacinth bean importer from India? JNTB EXPORT IMPORT is your premier choice! With our extensive experience and deep knowledge of the food export industry, we ensure a smooth and efficient export process for hyacinth beans. Our dedicated team will assist you at every stage, from meticulous documentation to secure final delivery. We handle all the details, so you can focus on your business. As a trusted partner, JNTB EXPORT IMPORT guarantees top-quality service and reliable support. If you’re interested in importing hyacinth beans or seeking an expert export partner, contact us today. Let JNTB EXPORT IMPORT streamline your hyacinth bean export needs and elevate your business globally!",
    varieties: [],
  },
  {
    heading: "Indian Fenugreek Exporter",
    picture: methiBanner,
    link: "Methi",
    firstLayout: true,
    description: "Fenugreek is a versatile herb used in cooking, medicine, and as a flavor mask for other medicines. Its seeds have a distinctive maple syrup aroma, while the leaves are enjoyed as a vegetable in India. Indian fenugreek exporters are shipping to around 131 countries worldwide, reflecting the herb’s growing global appeal. The increasing export of fenugreek from India is driven by its wide range of uses and health benefits, presenting a significant business opportunity for expansion into international markets. With various fenugreek varieties and qualities available, Indian exporters are well-positioned to meet global demand. Explore the potential of partnering with India’s leading fenugreek exporters and enhance your business with this exceptional herb.",
    marketDemand: "In the 2019-2020 period, Indian fenugreek exporters shipped approximately $18.63 million worth of fenugreek to global markets. The total volume of fenugreek exported from India reached around 24,385,899 metric tonnes. India’s top trading partners for fenugreek include Yemen, the USA, Bangladesh, Nepal, and Saudi Arabia, which together account for over 38.27% of the country's fenugreek seed exports. This robust international demand highlights India’s significant role in the global fenugreek market. With a strong foothold in these key regions, Indian fenugreek exporters are well-positioned to expand their reach and tap into new opportunities. Discover the advantages of partnering with India’s leading fenugreek exporters and leverage this thriving market potential.",
    specifications: [
      {
        title:"Product Name",
        value:"Fenugreek"
      },
      {
        title:"Origin",
        value:"Southern Europe and the Mediterranean region"
      },
      {
        title:"Family",
        value:"Fabaceae"
      },
      {
        title:"Binomial name",
        value:"Trigonella foenum-graecum"
      },
    ],
    ingredients: [
      {
        title:"Nutrients",
        value:["Calories 323 kcal","Total Carbohydrate 58 g","Dietary Fiber 25 g"]
      },
      {
        title:"Minerals",
        value:["Calcium","Iron","Magnesium"]
      },
      {
        title:"Vitamins",
        value:["Vitamin A","Vitamin C","Vitamin B-6"]
      },
      {
        title:"Protein",
        value:["23 g"]
      },
      {
        title:"Fats",
        value:["6g"]
      },
    ],
    benifits: ["Fenugreek is a valuable ally for diabetes management. Consuming at least 5 grams of fenugreek seeds daily may help lower blood sugar levels, offering a natural way to support glucose control and enhance overall health for those with diabetes.","Fenugreek can alleviate menstrual cramps effectively. Taking 1800-2700 mg of fenugreek seed powder three times daily for the first three days of your period, followed by 900 mg three times daily for the next two cycles, may help ease pain.","Fenugreek is great for digestive issues, addressing problems like loss of appetite, upset stomach, constipation, and stomach inflammation. Its natural properties support digestive health, providing relief and promoting overall well-being."],
    outro: "JNTB EXPORT IMPORT is a top leader in fenugreek importation from India. We are dedicated to sharing the richness of Indian culture and food products with the world. Our experienced team at JNTB EXPORT IMPORT is committed to providing exceptional support throughout every stage of the fenugreek export process. From meticulous documentation to the final delivery, we ensure a seamless and hassle-free experience for our clients. With our deep expertise and extensive background in the food export industry, we handle all the details with precision and care. If you’re seeking reliable fenugreek export from India, JNTB EXPORT IMPORT is your go-to partner. Contact us today and let us help you navigate the global market with ease!",
    varieties: [],
  },
  {
    heading: "",
    picture: woodappleBanner,
    link: "",
    firstLayout: "",
    description: "",
    marketDemand: "",
    specifications: [],
    ingredients: [],
    benifits: [],
    outro: "",
    varieties: [],
  },
  {
    heading: "",
    picture: suranBanner,
    link: "",
    firstLayout: "",
    description: "",
    marketDemand: "",
    specifications: [],
    ingredients: [],
    benifits: [],
    outro: "",
    varieties: [],
  },
];
