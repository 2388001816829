import React from "react";
import "./style.css";

const Blog = () => {
  return (
    <div>
      <iframe
        src="https://jntbexim.com/blog/blog"
        className="page-padding"
        width="100%"
        style={{minHeight:'100dvh',outline:'none',border:'none'}}
      ></iframe>
    </div>
  );
};

export default Blog;
