import React from "react";
import { useParams } from "react-router-dom";
import { Primary, Secondary } from "../../components";
import { porductLayout } from "../../constants";

const Product = () => {
  const { product } = useParams();
  return (
    <div>
      {porductLayout.map(({ heading, picture, contentArray, link }, index) => (
        <>
          {link === product && (
            link==='natural-honey'?<>
              <Secondary  
                heading={heading}
                picture={picture}
                contentArray={contentArray}
              />
            </>:
            <>
              <Primary
                heading={heading}
                picture={picture}
                contentArray={contentArray}
                product={product}
              />
            </>
          )}
        </>
      ))}
    </div>
  );
};

export default Product;
