import React from 'react'
import './style.css'

const Banner = () => {
  return (
    <div class='example'>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
    <div class='block'></div>
</div>
  )
}

export default Banner
